// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { MessagingsState } from '../_reducers/messaging.reducers';
import { each } from 'lodash-es';
import { Message } from '../_models/message.model';

export const selectMessagingsState = createFeatureSelector<MessagingsState>('messagings');

export const selectMessagingById = (_id: string) => createSelector(
    selectMessagingsState,
    messagingsState => messagingsState.entities[_id]
);

export const selectMessagingsPageLoading = createSelector(
    selectMessagingsState,
    messagingsState => {
        return messagingsState.listLoading;
    }
);

export const selectMessagingsActionLoading = createSelector(
    selectMessagingsState,
    messagingsState => messagingsState.actionsloading
);

export const selectLastCreatedMessagingId = createSelector(
    selectMessagingsState,
    messagingsState => messagingsState.lastCreatedMessagingId
);

export const selectMessagingsPageLastQuery = createSelector(
    selectMessagingsState,
    messagingsState => messagingsState.lastQuery
);

export const selectMessagingsInStore = createSelector(
    selectMessagingsState,
    messagingsState => {
        const items: Message[] = [];
        each(messagingsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: Message[] = httpExtension.sortArray(items, messagingsState.lastQuery.sortField, messagingsState.lastQuery.sortOrder);
        return new QueryResultsModel(result, messagingsState.totalCount, '');

    }
);

export const selectMessagingsShowInitWaitingMessage = createSelector(
    selectMessagingsState,
    messagingsState => messagingsState.showInitWaitingMessage
);

export const selectHasMessagingsInStore = createSelector(
    selectMessagingsState,
    queryResult => {
        if (!queryResult.totalCount) {
            return false;
        }

        return true;
    }
);

