import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef, OnDestroy, Input, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router, NavigationCancel, NavigationEnd, NavigationStart, RouteConfigLoadEnd, RouteConfigLoadStart } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { fromEvent, Subscription, Observable } from 'rxjs';
// Material
import { MatDialog } from '@angular/material/dialog';
// rxjs
import { distinctUntilChanged, skip, debounceTime, tap } from 'rxjs/operators';
import { AppState } from '../../../../core/reducers';

// Services
import { LayoutUtilsService, MessageType, QueryParamsModel } from '../../../../core/_base/crud/';
import { Vehicle, City, selectCityById } from "../../../../core/master-data";
import {
  Driver,
  DriversService,
  selectDriverById
} from "../../../../core/driver";

import {
  RideReport,
  RideReportService,
  RideDetailsMonitorService
} from "../../../../core/ride-report";

//Moment
import * as moment from 'moment';

declare var $: any;

@Component({
  selector: 'app-driver-details-sidebar',
  templateUrl: './driver-details-sidebar.component.html',
  styleUrls: ['./driver-details-sidebar.component.scss']
})
export class DriverDetailsSidebarComponent implements OnInit, OnDestroy {

  @Input() driverDetails: any;
  @Input() rideDetails: RideReport;

  driverId: string;
  driverDetail: Driver = new Driver();
  vehicleDetails: Vehicle = new Vehicle();
  profileImgUrl: string = "/assets/images/icons/profile.png";

  loading: boolean = false;
  assignLoding: boolean = false;

  // map
  locationTrackData: any[] = [];

  latitude = 12.971393140735792;
  longitude = 77.59248438935239;
  markers: any;
  zoom: number = 7;

  drawingManager: any;
  boundaryPath: any[] = [];
  mapStore: any;
  mapType: any = "roadmap";
  styles = [
    {
      "featureType": "landscape.man_made",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "color": "#eaeaea"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#fed89e"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "color": "#eebc79"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#fde7c4"
        }
      ]
    }
  ];
  isMapLoading: boolean = false;
  isMapDataLoading: boolean = true;

  // Subscriptions
  private subscriptions: Subscription[] = [];

  constructor(private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private store: Store<AppState>,
    private service: DriversService,
    private rideDetailsMonitor: RideDetailsMonitorService,
    private rideService: RideReportService,
    private layoutService: LayoutUtilsService) { }

  ngOnInit(): void {
    //
  }

  ngOnChanges(): void {
    if (this.driverDetails && this.driverDetails?._id && this.driverDetails?._id?.length > 0) {
      this.loading = true;
      this.driverId = this.driverDetails?._id;
      // this.subscriptions.push(this.store.pipe(select(selectDriverById(this.driverId))).subscribe(res => {
      //   if (res) {
      //     console.log(res);
      //     this.driverDetail = res;
      //     this.loading = false;
      //     this.cdr.detectChanges();
      //   } else {
      //     this.getDriver(this.driverId);
      //   }
      // }));
      this.getDriver(this.driverId);
      //this.getDriverLocationData(this.driverId)
    }
  }

  getDriver(id) {
    this.subscriptions.push(this.service.getSingleDriver(id).subscribe(
      (res: any) => {
        console.log(res);
        if (res.response.code === 200) {
          this.loading = false;
          this.driverDetail = res.data;
          this.vehicleDetails = res.data.vehicleDetails[0];
          this.cdr.markForCheck();
        } else {
          this.loading = false;
          this.hide();
          this.layoutService.showActionNotification(MessageType.danger, 'Driver Unavaiable', 'Driver details not available in the server.');
        }
      },
      error => {
        this.loading = false;
        this.hide();
      }
    ));
  }

  ngOnDestroy() {
    this.hide();
    this.subscriptions.forEach(el => el.unsubscribe());
  }

  public hide() {
    document.body.classList.remove('right-bar-enabled');
  }

  getPrimaryNumber(numbers) {
    let selected = numbers.filter(item => item.primary === true);
    if (selected.length > 0) {
      return this.formatMobileNumber(selected[0]);
    }
    return '-';
  }

  formatMobileNumber(mobile: any) {
    if (!mobile) {
      return '-';
    }
    if (mobile.countryCode && mobile.number) {
      return '+' + mobile.countryCode + ' ' + mobile.number;
    } else if (mobile.countryCode && mobile.contact) {
      return '+' + mobile.countryCode + ' ' + mobile.contact;
    } else {
      return '-';
    }
  }

  formatTime(time, noTime: boolean = false): string {
    if (!time) {
      return '-'
    }
    if (noTime) {
      return moment(time).format('DD MMM YYYY');
    }
    return moment(time).format('DD MMM YYYY, hh:mm A');
  }

  // get vehicle image from type
  getVehicleTypeImage(vehicleType) {
    console.log(vehicleType);
    switch (vehicleType) {
      case 'sedan':
        return "/assets/images/vehicle-type/sedan.svg";
      case 'suv':
        return "/assets/images/vehicle-type/suv.svg";
      case 'mini':
        return "/assets/images/vehicle-type/mini.svg";
      case 'premium':
        return "/assets/images/vehicle-type/premium.svg";
      default:
        return "/assets/images/vehicle-type/sedan.svg";
    }
  }

  viewDriver() {
    this.router.navigate(['/driver-management/details/view', this.driverId], { relativeTo: this.activatedRoute });
  }

  getDriverLocationData(driverId) {
    this.locationTrackData = [];
    this.isMapDataLoading = true;
    this.cdr.detectChanges();
    let query: string = 'driverId=' + driverId + '&startTime=' + moment().format('YYYY-MM-DDT') + '00:00:00' + '&endTime=' + moment().format('YYYY-MM-DDT') + '23:59:59';
    this.subscriptions.push(this.service.getSingleDriverLocation(query).subscribe((res: any) => {
      console.log(res);
      if (res.response.code == 200) {
        this.locationTrackData = res.data;
        this.boundaryPath = this.getLatLong((this.locationTrackData || []));
        if (this.mapStore) {
          this.initDrawingManager(this.mapStore);
        }
        this.cdr.detectChanges();
      } else {
        this.boundaryPath = [];
        this.locationTrackData = [];
      }
      this.isMapDataLoading = false;
      this.cdr.detectChanges();
    }, error => {
      this.boundaryPath = [];
      this.locationTrackData = [];
      this.isMapDataLoading = false;
      this.cdr.detectChanges();
    }));
  }

  zoomChange(value) {
    this.zoom = value;
    this.cdr.detectChanges();
  }

  onMapReady(map) {
    this.mapStore = map;
    this.initDrawingManager(this.mapStore);
  }

  getLatLong(list = []) {
    let latlongArray = [];
    for (let i = 0; i < list.length; i++) {
      if (list[i].lat && list[i].long) {
        latlongArray.push(
          { lat: parseFloat(list[i].lat), lng: parseFloat(list[i].long), heading: parseFloat(list[i].heading) }
        );
      }
    }
    console.log(latlongArray);
    return latlongArray;
  }

  initDrawingManager(map: any) {
    if (this.boundaryPath.length > 0) {
      var bounds = new google.maps.LatLngBounds();
      this.boundaryPath.forEach(function (path, index) {
        bounds.extend(path);
      });
      map.fitBounds(bounds);
    }
  }

  /* Assign driver logic */

  assignDriver(){
    this.assignLoding = true;
    const dialogRef = this.layoutService.driverStatusVerifyPopup(this.driverDetail, this.rideDetails);
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        this.assignLoding = false;
        this.cdr.detectChanges();
        return;
      }
      const data = {
        rideId: this.rideDetails?._id,
        driverId: this.driverDetail?._id
      };
      this.subscriptions.push(this.rideService.assignDriverManual(data).subscribe(
        res => {
          console.log(res);
          this.assignLoding = false;
          this.cdr.detectChanges();
          if (res.response.code === 202) {
            this.layoutService.showActionNotification(MessageType.success, 'Driver Assigned!', 'Driver assigned to ride. Notification shared with driver.');
            this.rideDetailsMonitor.reloadRide$.next(this.rideDetails._id);
            this.hide();
          }
        },
        error => {
          this.assignLoding = false;
          this.cdr.detectChanges();
          console.log(error);
          this.layoutService.showActionNotification(MessageType.danger, 'Driver Not Assigned!', error.error.response.message);
        }
      ));
    });
  }
}
