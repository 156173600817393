// Angular
import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

import { QueryParamsModel } from "src/app/core/_base/crud";
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class EncryptionService {

  private key = CryptoJS.enc.Utf8.parse(environment.encryptKey);
  private iv = CryptoJS.enc.Utf8.parse(environment.encryptIV);
  constructor() { }
  // Methods for the encrypt Using AES
  encryptQuery(query: QueryParamsModel): any {
    var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(JSON.stringify(query)), this.key, {
      keySize: 128 / 8,
      iv: this.iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
  }
  // Methods for the decrypt Using AES
  decryptQuery(decString) {
    var decrypted = CryptoJS.AES.decrypt(decString, this.key, {
      keySize: 128 / 8,
      iv: this.iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
  }
}
