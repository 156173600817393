// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
// Actions
import { MessagingActions, MessagingActionTypes } from '../_actions/messaging.actions';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { Message } from '../_models/message.model';
export interface MessagingsState extends EntityState<Message> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedMessagingId: string;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}
// export const adapter: EntityAdapter<Messaging> = createEntityAdapter<Messaging>();

export const adapter: EntityAdapter<Message> = createEntityAdapter({
    selectId: (messaging: Message) => messaging._id,
  });
export const initialMessagingsState: MessagingsState = adapter.getInitialState({
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastQuery:  new QueryParamsModel(),
    lastCreatedMessagingId: undefined,
    showInitWaitingMessage: true
});
export function MessagingsReducer(state = initialMessagingsState, action: MessagingActions): MessagingsState {
    switch  (action.type) {
        case MessagingActionTypes.MessagingsPageToggleLoading: return {
            ...state, listLoading: action.payload.isLoading, lastCreatedMessagingId: undefined
        };
        case MessagingActionTypes.MessagingsActionToggleLoading: return {
            ...state, actionsloading: action.payload.isLoading
        };
        case MessagingActionTypes.MessagingsPageCancelled: return {
            ...state, listLoading: false, lastQuery: new QueryParamsModel()
        };
        case MessagingActionTypes.MessagingsPageLoaded: {
            return adapter.addMany(action.payload.messagings, {
                ...initialMessagingsState,
                totalCount: action.payload.totalCount,
                lastQuery: action.payload.page,
                listLoading: false,
                showInitWaitingMessage: false
            });
        }
        default: return state;
    }
}


export const getMessagingstate = createFeatureSelector<MessagingsState>('messagings');
export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
