import { Component, OnInit, Inject, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// RxJS
import { Observable, of, Subscription, Subject} from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
// Layout
import { LayoutUtilsService, MessageType } from '../../../../core/_base/crud/utils/layout-utils.service';
// Service
import { UploadService } from '../../../../core/upload/_services/upload.service';

@Component({
  selector: 'app-dropzone-upload',
  templateUrl: './dropzone-upload.component.html',
  styleUrls: ['./dropzone-upload.component.scss']
})
export class DropzoneUploadComponent implements OnInit, OnDestroy {

	loading: boolean = false;
	fileChanged: boolean = false;
	private formData: FormData;
	tempUrl: any;

	private subscriptions: Subscription[] = [];

	constructor(private uploadService: UploadService,
				public dialogRef: MatDialogRef<DropzoneUploadComponent>,
	           	@Inject(MAT_DIALOG_DATA) public data: any,
	           	private cdr: ChangeDetectorRef,
	           	private layoutService: LayoutUtilsService) { }

	ngOnInit(): void {
		if (this.data.imageUrl) {
			console.log(this.data.imageUrl);

			this.tempUrl = this.data.imageUrl;
		}
	}

	ngOnDestroy() {
		this.subscriptions.forEach(el => el.unsubscribe());
	}

	handleUploadEventDrop(newEvent) {
    console.log("calling in upload popup");
		this.formData = new FormData();
		if (newEvent.target.files && newEvent.target.files[0]) {
			console.log(newEvent.target.files[0]);
			//if (newEvent.target.files[0].size > 20971520) {
			if (newEvent.target.files[0].size > 10485760) {
				this.layoutService.showActionNotification(MessageType.warning, 'File Too Large!', 'Maximum uploadable file size is 10MB. Please upload a smaller image and try again.');
				return;
			}
			if (newEvent.target.files[0].type === 'image/jpeg' || newEvent.target.files[0].type === 'image/jpg' || newEvent.target.files[0].type === 'image/png') {
				this.fileChanged = true;
				const file = newEvent.target.files[0];
        var reader = new FileReader();
        const self = this;
        reader.onload = function (e) {
            self.tempUrl = reader.result;
        };
        reader.readAsDataURL(newEvent.target.files[0]);
				this.formData.append('file', newEvent.target.files[0]);
			}else {
				this.layoutService.showActionNotification(MessageType.warning, 'Unsupported File Format!', 'Selected file format not supported. Please upload a jpg or png image.');
			}
    }
	}

	removeSelected(event) {
		event.target.value = '';
		this.cdr.detectChanges();
	}

	removeImage() {
		this.tempUrl = "";
    this.fileChanged = false;
		this.cdr.detectChanges();
	}

	onSubmit() {
		if (!this.fileChanged && !this.tempUrl) {
			this.layoutService.showActionNotification(MessageType.danger, 'File Required!', 'Need to upload an image');
			return;
		}
		this.loading = true;
		if (this.fileChanged) {
      this.subscriptions.push(this.uploadService.uploadFile(this.formData).subscribe(
				(res)=>{
					if (res.response.code === 201) {
						var _data = res.data[0];
						this.dialogRef.close({
							_data
						});
					}
		        },
		        error =>{
              this.loading = false;
		        	this.layoutService.showActionNotification(MessageType.danger, 'Servr Error!', 'Something went wrong, please try again later.');
		        }
	        ));
		}else {
			setTimeout(() => {
				this.dialogRef.close();
			}, 500);
		}
	}

	closeDialog() {
		this.dialogRef.close();
	}

}
