// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { Driver } from '../_models/driver.model';
// Models
import { QueryParamsModel } from '../../_base/crud';

export enum CugActionTypes {
    AllCugsRequested = '[Cugs Module] All Cugs Requested',
    AllCugsLoaded = '[Cugs API] All Cugs Loaded',
    CugsPageRequested = '[Cugs List Page] Cugs Page Requested',
    CugsPageLoaded = '[Cugs API] Cugs Page Loaded',
    CugsPageCancelled = '[Cugs API] Cugs Page Cancelled',
    CugsPageToggleLoading = '[Cugs] Cugs Page Toggle Loading',
    CugsActionToggleLoading = '[Cugs] Cugs Action Toggle Loading'
}

export class CugsPageRequested implements Action {
    readonly type = CugActionTypes.CugsPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class CugsPageLoaded implements Action {
    readonly type = CugActionTypes.CugsPageLoaded;
    constructor(public payload: { drivers: Driver[], masterDriver: Driver, totalCount: number, page: QueryParamsModel  }) { }
}


export class CugsPageCancelled implements Action {
    readonly type = CugActionTypes.CugsPageCancelled;
}

export class CugsPageToggleLoading implements Action {
    readonly type = CugActionTypes.CugsPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class CugsActionToggleLoading implements Action {
    readonly type = CugActionTypes.CugsActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type CugActions = CugsPageLoaded
| CugsPageCancelled
| CugsPageToggleLoading
| CugsPageRequested
| CugsActionToggleLoading;
