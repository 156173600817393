import { Injectable, PipeTransform } from "@angular/core";

import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import { debounceTime, delay, switchMap, tap, mergeMap, catchError, map } from "rxjs/operators";

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { QueryParamsModel } from '../../_base/crud';

const API_URL = environment.apiUrl + '/settings';

@Injectable({
  providedIn: "root",
})

export class TaxService {

  constructor(private http: HttpClient) { }

  // Get all taxess with filter
  getAllTax(queryParams: QueryParamsModel): Observable<any> {
    return this.http.get<any>(API_URL + '/tax?' +
    'pageSize=' + queryParams.pageSize +
    '&pageNumber=' + queryParams.pageNumber +
    '&sortOrder=' + queryParams.sortOrder +
    '&sortField=' + queryParams.sortField +
    '&searchText=' + queryParams.searchText + queryParams.filter).pipe(
      mergeMap(res => {
        console.log(res);
        if (res.response.code === 200) {
          const result = {
            items: res.data.list,
            totalCount: res.data.totalCount
          };
          return of(result);
        } else {
          const result = {
            items: [],
            totalCount: 0
          };
          return of(result);
        }
      })
    );
  }

  // Without filter
  getTax() {
    return this.http.get<any>(API_URL + '/tax');
  }

  // Add new tax
  createTax(tax: any): Observable<any> {
    return this.http.post(API_URL + '/tax', tax);
  }

  // Update tax
  updateTax(tax: any): Observable<any> {
    return this.http.put(API_URL + '/tax', tax);
  }

  // Delete tax
  deleteTax(tax: any): Observable<any> {
    return this.http.delete(API_URL + '/tax?taxId=' + tax._id + '&isDelete=' + tax.isDelete);
  }

}
