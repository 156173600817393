import { UploadFile } from '../../upload/_models/uploadFile.model';
import { User } from '../../auth/_models/user.model';


export class Document {
  _id: string;
  admin: {
    softCopy: {
      status: string,
      comments: string
    },
    hardCopy: {
      status: string,
      comments: string
    }
  };
  submission: {
    softCopy: string,
    hardCopy: string
  };
  driverId: string;
  documentType: number;

  back: UploadFile;
  front: UploadFile;
  left: UploadFile;
  right: UploadFile;

  documentName: string;
  uploadDate: string;
  comment: string;

  documentNumber: string;
  expiryDate: string;
  formatedExpiryDate: any;
  amount: number;
  brand: string;
  ifscCode: string;
  accountType: string;
  name: string;
  dob: string;
  formatedDob: any;
  address: string;
  bloodGroup: string;
  razorpayAccountId: string;
  digitallyVerified: boolean;
  digitalDocumentId: string;
  digitalDocument: any;

  lastUpdated: {
    who: User,
    whoIs: string,
    at: string
  };

  isInvalid: boolean;

  _isEditMode: boolean;
  _isLoading: boolean;
  _isEditable: boolean;
  _isExpanded: boolean;
  __v: number;

  clear(): void {
    this._id = undefined;
    this.admin = {
      softCopy: {
        status: 'Pending',
        comments: ''
      },
      hardCopy: {
        status: 'Pending',
        comments: ''
      }
    };
    this.submission = {
      softCopy: 'Pending',
      hardCopy: 'Pending'
    };
    this.driverId = undefined;
    this.documentType = undefined;

    this.back = new UploadFile();
    this.back.clear();
    this.front = new UploadFile();
    this.front.clear();
    this.left = new UploadFile();
    this.left.clear();
    this.right = new UploadFile();
    this.right.clear();

    this.documentName = '';
    this.uploadDate = '';
    this.comment = '';

    this.documentNumber = undefined;
    this.expiryDate = undefined;
    this.formatedExpiryDate = {};
    this.amount = undefined;
    this.brand = undefined;
    this.ifscCode = undefined;
    this.accountType = undefined;
    this.name = undefined;
    this.dob = undefined;
    this.formatedDob = {};
    this.address = undefined;
    this.bloodGroup = '';
    this.razorpayAccountId = undefined;
    this.digitallyVerified = false;
    this.digitalDocumentId = undefined;
    this.digitalDocument = undefined;

    this.lastUpdated = {
      who: undefined,
      whoIs: 'driver',
      at: ''
    };

    this.isInvalid = false;

    this._isEditMode = false;
    this._isLoading = false;
    this._isEditable = false;
    this._isExpanded = false;
    this.__v = 0;
  }
}
