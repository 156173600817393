import { Component, OnInit, Inject, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// RxJS
import { Observable, of, Subscription, Subject } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
// Layout
import { LayoutUtilsService, MessageType } from '../../../../core/_base/crud/utils/layout-utils.service';
// Service
import { UploadService } from '../../../../core/upload/_services/upload.service';

@Component({
  selector: 'app-bulk-upload',
  templateUrl: './bulk-upload.component.html',
  styleUrls: ['./bulk-upload.component.scss']
})
export class BulkUploadComponent implements OnInit, OnDestroy {

  loading: boolean = false;
  parsedData: any[] = [];

  private subscriptions: Subscription[] = [];

  constructor(public dialogRef: MatDialogRef<BulkUploadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cdr: ChangeDetectorRef,
    private layoutService: LayoutUtilsService) { }

  ngOnInit(): void {
    this.layoutService.showAlert();
    console.log(this.data);
  }

  ngOnDestroy() {
    this.layoutService.showAlert();
    this.subscriptions.forEach(el => el.unsubscribe());
  }

  handleUploadEventDrop(newEvent) {
    if (newEvent.target.files && newEvent.target.files[0]) {
      if (newEvent.target.files[0].type === 'text/csv') {
        const self = this;
        var reader = new FileReader();
        reader.readAsText(newEvent.target.files[0]);
        reader.onload = function (e) {
          self.parsedData = [];
          let tempUrl: any = reader.result.toString().replace('\r', '');
          let textByLine = tempUrl.split("\n")
          //console.log(textByLine);
          // read header
          let header = (textByLine[0]).split(',');
          //console.log(header);
          // read data
          let isValidCsv: string[] = self.isCsvValid(header);
          if (isValidCsv.length == 0) {
            for (var i = 1; i <= textByLine.length - 1; i++) {
              let temp_row = {}
              let row_data = textByLine[i].split(',');
              if (row_data.length == header.length) {
                //console.log(row_data);
                for (var j = 0; j < header.length; j++) {
                  temp_row[header[j]] = row_data[j]
                }
                self.parsedData.push(temp_row);
              }
            }
            if (self.parsedData.length > 0) {
              self.layoutService.showAlert(MessageType.success, self.parsedData.length + ' records parsed successfully! Click upload button to add record.', 500000000);
            }else {
              self.layoutService.showAlert(MessageType.warning, 'No valid records found in the file.');
            }
            //console.log(self.parsedData);
          }else {
            self.layoutService.showAlert(MessageType.danger, ' Mandatory fields missing in the uploaded file!');
          }
        };
      } else {
        this.layoutService.showAlert(MessageType.warning, 'Selected file format not supported. Please upload a csv file.');
      }
    }
  }

  removeSelected(event) {
    event.target.value = '';
    this.cdr.detectChanges();
  }

  removeFile() {
    this.parsedData = [];
    this.layoutService.showAlert();
    this.cdr.detectChanges();
  }

  downloadSample() {
    const blob = new Blob([this.generateCsv(this.data.sampleData)], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = this.data.fileName+'.csv';
    a.click();
  }

  isCsvValid(headers) {
    let missingField = [];
    const csvHeaders = Object.keys(this.data.sampleData);
    csvHeaders.forEach(el => {
      let selected = headers.filter(item => item == el);
      if (selected.length == 0) {
        missingField.push(el);
      }
    })
    //console.log(csvHeaders, headers);
    return missingField;
  }

  generateCsv(data){
    let csvRows = [];
    const headers = Object.keys(data);
    csvRows.push(headers.join(','));
    const values = Object.values(data).join(',');
    csvRows.push(values)
    return csvRows.join('\n')
  }

  onSubmit() {
    if (this.parsedData.length == 0) {
      this.layoutService.showAlert(MessageType.danger, 'Need to upload a valid csv');
      return;
    }
    this.loading = true;
    setTimeout(() => {
      this.dialogRef.close(this.parsedData);
    }, 500);
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
