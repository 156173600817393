// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, tap, delay, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { Observable, defer, of, forkJoin } from 'rxjs';
// NGRX
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store, select, Action } from '@ngrx/store';
// CRUD
import { QueryResultsModel, QueryParamsModel } from '../../_base/crud';
// Services
import { DriversService } from '../../../core/driver/_services/drivers.service';
// State
import { AppState } from '../../../core/reducers';
import {
    CugActionTypes,
    CugsPageRequested,
    CugsPageLoaded,
    CugsActionToggleLoading,
    CugsPageToggleLoading
} from '../_actions/cug.actions';

@Injectable()
export class CugEffects {
    showPageLoadingDistpatcher = new CugsPageToggleLoading({ isLoading: true });
    hidePageLoadingDistpatcher = new CugsPageToggleLoading({ isLoading: false });

    showActionLoadingDistpatcher = new CugsActionToggleLoading({ isLoading: true });
    hideActionLoadingDistpatcher = new CugsActionToggleLoading({ isLoading: false });

    @Effect()
    loadDriversPage$ = this.actions$
        .pipe(
            ofType<CugsPageRequested>(CugActionTypes.CugsPageRequested),
            debounceTime(50),
            distinctUntilChanged(),
            switchMap(( { payload } ) => {
                this.store.dispatch(this.showPageLoadingDistpatcher);
                const requestToServer = this.service.getAllUpStreamDriver(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                console.log(response);
                const result: QueryResultsModel = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new CugsPageLoaded({
                    drivers: result.items,
                    masterDriver: result.extra,
                    totalCount: result.totalCount,
                    page: lastQuery
                });
            }),
        );

    constructor(private actions$: Actions, private store: Store<AppState>, private service: DriversService) { }
}
