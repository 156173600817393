// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
// Actions
import { PendingRideActions, PendingRideActionTypes } from '../_actions/pendingRides.actions';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { RideReport } from '../_models/rideReport.model';

export interface PendingRidesState extends EntityState<RideReport> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedPendingRideId: string;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}
// export const adapter: EntityAdapter<PendingRide> = createEntityAdapter<PendingRide>();

export const adapter: EntityAdapter<RideReport> = createEntityAdapter({
  selectId: (pendingRide: RideReport) => pendingRide._id,
  });
export const initialPendingRidesState: PendingRidesState = adapter.getInitialState({
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastQuery:  new QueryParamsModel(),
    lastCreatedPendingRideId: undefined,
    showInitWaitingMessage: true
});
export function PendingRidesReducer(state = initialPendingRidesState, action: PendingRideActions): PendingRidesState {
    switch  (action.type) {
        case PendingRideActionTypes.PendingRidesPageToggleLoading: return {
            ...state, listLoading: action.payload.isLoading, lastCreatedPendingRideId: undefined
        };
        case PendingRideActionTypes.PendingRidesActionToggleLoading: return {
            ...state, actionsloading: action.payload.isLoading
        };
        case PendingRideActionTypes.PendingRidesPageCancelled: return {
            ...state, listLoading: false, lastQuery: new QueryParamsModel()
        };
        case PendingRideActionTypes.PendingRidesPageLoaded: {
            return adapter.addMany(action.payload.pendingRides, {
                ...initialPendingRidesState,
                totalCount: action.payload.totalCount,
                lastQuery: action.payload.page,
                listLoading: false,
                showInitWaitingMessage: false
            });
        }
        default: return state;
    }
}


export const getPendingRidestate = createFeatureSelector<PendingRidesState>('pendingRides');
export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
