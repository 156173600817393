import { Component, OnInit, AfterViewInit, ViewEncapsulation, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
// RxJS
import { Observable, Subscription } from 'rxjs';
// Layout
import { MenuConfigService, PageConfigService } from '../../../core/_base/layout';
import { MenuConfig } from '../../../core/_config/menu.config';
import { PageConfig } from '../../../core/_config/page.config';

// User modules
import { NgxPermissionsService } from 'ngx-permissions';
import { currentUserModules, Module } from '../../../core/auth';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../core/reducers';

// Messaging initialize
import { MessagingService } from '../../../core/fcm/_services/messaging.service';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  encapsulation: ViewEncapsulation.None
})
export class BaseComponent implements OnInit, AfterViewInit, OnDestroy {

  isCondensed = false;

  // Private properties
  private unsubscribe: Subscription[] = [];
  private currentUserModules$: Observable<Module[]>;

  constructor(private router: Router,
              private menuConfigService: MenuConfigService,
              private pageConfigService: PageConfigService,
              private cdr: ChangeDetectorRef,
              private store: Store<AppState>,
              private modulesService: NgxPermissionsService,
              private messagingService: MessagingService) {

    this.loadRolesWithModules();

    this.menuConfigService.loadConfigs(new MenuConfig().configs);
    this.pageConfigService.loadConfigs(new PageConfig().configs);

    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        document.body.classList.remove('sidebar-enable');
      }
    });
  }

  ngOnInit() {
    document.body.removeAttribute('data-layout');
    document.body.removeAttribute('data-topbar');
    document.body.removeAttribute('data-layout-size');
    document.body.classList.remove('sidebar-enable');
    document.body.classList.remove('vertical-collpsed');
    document.body.removeAttribute('data-sidebar-size');
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    this.unsubscribe.forEach(sb => sb.unsubscribe());
  }

  isMobile() {
    const ua = navigator.userAgent;
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua);
  }

  ngAfterViewInit() {
  }

  /**
   * on settings button clicked from topbar
   */
  onSettingsButtonClicked() {
    document.body.classList.toggle('right-bar-enabled');
  }

  /**
   * On mobile toggle button clicked
   */
  onToggleMobileMenu() {
    this.isCondensed = !this.isCondensed;
    document.body.classList.toggle('sidebar-enable');
    document.body.classList.toggle('vertical-collpsed');

    if (window.screen.width <= 768) {
      document.body.classList.remove('vertical-collpsed');
    }
  }

  /**
   * NGX Permissions, init roles
   */
  loadRolesWithModules() {
    this.currentUserModules$ = this.store.pipe(select(currentUserModules));
    const subscr = this.currentUserModules$.subscribe(res => {
      this.modulesService.addPermission('operationsDashboard');
      if (!res || res.length === 0) {
        return;
      }
      //console.log("flushing access");
      //this.modulesService.flushPermissions();

      res.forEach((module: Module) => {
        this.modulesService.addPermission(module.module);
        if (module.operations.view) {
          this.modulesService.addPermission(module.module+'View');
        }
        if (module.operations.edit) {
          this.modulesService.addPermission(module.module+'Edit');
        }
        if (module.operations.delete) {
          this.modulesService.addPermission(module.module+'Delete');
        }
      });
      //this.modulesService.addPermission('initiateDirectCallToRider');
      //this.modulesService.addPermission('initiateDirectCallToDriver');
      //console.log("restoring access");

    });
    this.unsubscribe.push(subscr);
  }
}
