
export class Attributes {
    _id: string;
    data: string;
    generalType: string;
    isDelete: boolean;

    clear(): void {
        this._id = undefined;
        this.data = '';
        this.generalType = '';
        this.isDelete = false;
    }
}
