// NGRX
import { Action } from '@ngrx/store';
// Models
import { City } from '../_models/city.model';

export enum CityActionTypes {
    AllCitiesRequested = '[Init] All Cities Requested',
    AllCitiesLoaded = '[Init] All Cities Loaded'
}

export class AllCitiesRequested implements Action {
    readonly type = CityActionTypes.AllCitiesRequested;
}

export class AllCitiesLoaded implements Action {
    readonly type = CityActionTypes.AllCitiesLoaded;
    constructor(public payload: { cities: City[] }) { }
}

export type CityActions = AllCitiesRequested | AllCitiesLoaded;
