// NGRX
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createFeatureSelector } from '@ngrx/store';
// Actions
import { CityActionTypes, CityActions } from '../_actions/city.actions';
// Models
import { City } from '../_models/city.model';

export interface CitiesState extends EntityState<City> {
    _isAllCitiesLoaded: boolean;
}

export const adapter: EntityAdapter<City> = createEntityAdapter({
  selectId: (city: City) => city._id,
});

export const initialCitiesState: CitiesState = adapter.getInitialState({
    _isAllCitiesLoaded: false
});

export function citiesReducer(state = initialCitiesState, action: CityActions): CitiesState {
    switch  (action.type) {
        case CityActionTypes.AllCitiesRequested:
            return {...state,
                _isAllCitiesLoaded: false
        };
        case CityActionTypes.AllCitiesLoaded:
            return adapter.setAll(action.payload.cities, {
                ...state,
                _isAllCitiesLoaded: true
            });
        default:
            return state;
    }
}

export const getRoleState = createFeatureSelector<CitiesState>('cities');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
