export class DocType {
  documentType: number;
  documentName: string;
  icon: string;
  back: boolean;
  front: boolean;
  left: boolean;
  right: boolean;
  pdfAllowed: boolean;
  digitalAvailable: boolean;

  clear(): void {
    this.documentType = undefined;
    this.documentName = '';
    this.icon = '';
    this.back = false;
    this.front = false;
    this.left = false;
    this.right = false;
    this.pdfAllowed = false;
    this.digitalAvailable = false;
  }
}
