import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Log } from '../_models/log.model';
import { QueryParamsModel } from '../../_base/crud';

export enum LogActionTypes {
    AllLogsRequested = '[Logs Module] All Logs Requested',
    AllLogsLoaded = '[Logs API] All Logs Loaded',
    LogsPageRequested = '[Logs List Page] Logs Page Requested',
    LogsPageLoaded = '[Logs API] Logs Page Loaded',
    LogsPageCancelled = '[Logs API] Logs Page Cancelled',
    LogsPageToggleLoading = '[Logs] Logs Page Toggle Loading',
    LogsActionToggleLoading = '[Logs] Logs Action Toggle Loading'
}

export class LogsPageRequested implements Action {
    readonly type = LogActionTypes.LogsPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class LogsPageLoaded implements Action {
    readonly type = LogActionTypes.LogsPageLoaded;
    constructor(public payload: { logs: Log[], totalCount: number, page: QueryParamsModel  }) { }
}

export class LogsPageCancelled implements Action {
    readonly type = LogActionTypes.LogsPageCancelled;
}
export class LogsPageToggleLoading implements Action {
    readonly type = LogActionTypes.LogsPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class LogsActionToggleLoading implements Action {
    readonly type = LogActionTypes.LogsActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}


export type LogActions = LogsPageLoaded
| LogsPageCancelled
| LogsPageRequested
| LogsPageToggleLoading
| LogsActionToggleLoading;

