export class Attribute {
  _id: undefined;
  isDelete: boolean;
  name: string;
  attribute: string;
  createdById: string;

  clear(): void {
    this._id = undefined;
    this.isDelete = false;
    this.name = '';
    this.attribute = '';
    this.createdById = '';
  }
}
