// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { PromotionState } from '../_reducers/promotion.reducers';
import { each } from 'lodash-es';
import { Promotion } from '../_models/promotion.model';


export const selectPromotionState = createFeatureSelector<PromotionState>('promotions');

export const selectPromotionById = (promotionId: string) => createSelector(
    selectPromotionState,
    promotionState => promotionState.entities[promotionId]
);

export const selectPromotionPageLoading = createSelector(
    selectPromotionState,
    promotionState => {
        return promotionState.listLoading;
    }
);

export const selectPromotionActionLoading = createSelector(
    selectPromotionState,
    promotionState => promotionState.actionsloading
);

export const selectLastCreatedPromotionId = createSelector(
    selectPromotionState,
    promotionState => promotionState.lastCreatedPromotionId
);

export const selectPromotionPageLastQuery = createSelector(
    selectPromotionState,
    promotionState => promotionState.lastQuery
);

export const selectPromotionInStore = createSelector(
    selectPromotionState,
    promotionState => {
        const items: Promotion[] = [];
        each(promotionState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: Promotion[] = httpExtension.sortArray(items, promotionState.lastQuery.sortField, promotionState.lastQuery.sortOrder);
        return new QueryResultsModel(result, promotionState.totalCount, '');

    }
);

export const selectPromotionShowInitWaitingMessage = createSelector(
    selectPromotionState,
    promotionState => promotionState.showInitWaitingMessage
);

export const selectHasPromotionInStore = createSelector(
    selectPromotionState,
    queryResult => {
        if (!queryResult.totalCount) {
            return false;
        }

        return true;
    }
);
