<ngb-pagination class="pagination pagination-rounded justify-content-end mb-2 mt-3"
  [directionLinks]="true"
  [boundaryLinks]="boundaryLinks"
  [maxSize]="maxSize"
  [ellipses]="ellipses"
  [collectionSize]="collectionSize"
  [pageSize]="pageSize"
  [(page)]="page"
  (pageChange)="onChange()">
</ngb-pagination>
