// Angular
import { Component, OnDestroy, OnInit, ChangeDetectorRef, Input } from '@angular/core';
// RxJS
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-page-counter',
  templateUrl: './page-counter.component.html',
  styleUrls: ['./page-counter.component.scss']
})
export class PageCounterComponent implements OnInit, OnDestroy {

  @Input() page: number;
  @Input() pageSize: number;
  @Input() collectionSize: number;
  @Input() direction: string = 'asc';

	// Private properties
	private subscriptions: Subscription[] = [];

	constructor(private cdr: ChangeDetectorRef) {
	}

	/**
	 * On init
	 */
	ngOnInit() {
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		this.subscriptions.forEach(sb => sb.unsubscribe());
	}

  getCount(type) {
    if (this.collectionSize > 0) {
      if (type === 'start') {
        return ((this.page - 1) * this.pageSize) + 1;
      } else {
        let last = this.page * this.pageSize;
        if (this.collectionSize > last) {
          return last;
        } else {
          return this.collectionSize;
        }
      }
    } else {
      return 0;
    }
  }

}
