import { Promotion } from './promotion.model';
import { RecipientRazorPay } from './recipientRazorPay.model';
import { RideReport } from '../../ride-report';

export class PromotionSettlement {
  _id: string;
  rideId: RideReport;
  promotionDetail: Promotion;
  driverId: string;
  cityId: string;
  zoneId: string;
  rideTime: string;
  paymentProcessedTime: string;
  paymentCompletedTime: string;
  settlementAmount: number;
  promotionSettlementStatus: string; // ['settled', 'processed', 'pending', 'failed']
  recipientRazorPay: RecipientRazorPay;
  comment: string;

  clear(): void {
    this._id = undefined;
    this.rideId = new RideReport();
    this.rideId.clear();
    this.promotionDetail = new Promotion();
    this.promotionDetail.clear();
    this.driverId = '';
    this.cityId = '';
    this.zoneId = '';
    this.rideTime = '';
    this.paymentProcessedTime = '';
    this.paymentCompletedTime = '';
    this.settlementAmount = 0;
    this.promotionSettlementStatus = 'pending'; // ['settled', 'processed', 'pending', 'failed']
    this.recipientRazorPay = new RecipientRazorPay();
    this.recipientRazorPay.clear();
    this.comment = '';
  }
}
