// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// State
import { CitiesState } from '../_reducers/city.reducers';
import * as fromCities from '../_reducers/city.reducers';

export const selectCitiesState = createFeatureSelector<CitiesState>('cities');

export const selectCityById = (cityId: string) => createSelector(
    selectCitiesState,
    ps => ps.entities[cityId]
);

export const selectAllCities = createSelector(
    selectCitiesState,
    fromCities.selectAll
);

export const selectAllCitiesIds = createSelector(
    selectCitiesState,
    fromCities.selectIds
);

export const allCitiesLoaded = createSelector(
    selectCitiesState,
    ps  => ps._isAllCitiesLoaded
);
