// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS
import { Subject, Subscription } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
// Store
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
// Layout
import { LayoutUtilsService, MessageType } from '../../../../core/_base/crud/';
// Auth
import { AuthService, Login, UserLoaded, User } from '../../../../core/auth';

import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-lock-screen',
  templateUrl: './lock-screen.component.html'
})
export class LockScreenComponent implements OnInit, OnDestroy {
	// Public params
	lockForm: FormGroup;
	loading = false;
	user: User;

  passwordType: string[] = ['password'];

  private subscriptions: Subscription[] = [];

	constructor(
		private router: Router,
		private auth: AuthService,
		private store: Store<AppState>,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private route: ActivatedRoute,
		private layoutService: LayoutUtilsService
	) {}

	ngOnInit(): void {
		this.initLoginForm();
		const user = localStorage.getItem(environment.userSession);
		if (!(user && user.length > 0)) {
			this.router.navigateByUrl('/auth/login');
		}else {
			this.user = JSON.parse(user);
			console.log(this.user);
		}
    this.subscriptions.push(this.route.queryParams.subscribe(params => {
			if (params.type === 'expired') {
				this.layoutService.showAlert(MessageType.info, "Active session has been expired, please login again to continue.");
			}
		}));
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		this.layoutService.showAlert();
		this.loading = false;
    this.subscriptions.forEach(el => el.unsubscribe());
	}

	initLoginForm() {
		this.lockForm = this.fb.group({
			password: ['', Validators.compose([
				Validators.required
			])
			]
		});
	}

  togglePassword(index) {
    if (this.passwordType[index] === 'password') {
      this.passwordType[index] = 'text';
    } else {
      this.passwordType[index] = 'password';
    }
  }

	onSubmit() {
		const controls = this.lockForm.controls;
		/** check form */
		if (this.lockForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}
		this.loading = true;

		const authData = {
			email: this.user.email,
			password: controls.password.value
		};
    this.subscriptions.push(this.auth.login(authData).subscribe(
      res =>{
        this.loading = false;
        this.cdr.detectChanges();
        console.log(res);
        if (res.response.code === 201) {
          const user = localStorage.getItem(environment.userSession);
                  if (user && user.length > 0) {
                      localStorage.setItem(environment.userSession, JSON.stringify(res.data));
                  }
          this.store.dispatch(new Login({authToken: res.data.token}));
          this.store.dispatch(new UserLoaded({user: res.data}));
          this.router.navigateByUrl('/dashboard'); // Main page
          this.layoutService.showActionNotification(MessageType.success, 'Welcome to viaDOTS', 'Manage dots taxi platform seamlessly, and get updates of latest statistics and analytics.');
        }
      },
      error =>{
        this.loading = false;
        this.cdr.detectChanges();
        console.log(error);
        if (error.status === 404) {
          this.layoutService.showAlert(MessageType.danger, "User does not exist");
        } else if (error.status === 401) {
          this.layoutService.showAlert(MessageType.danger, "Incorrect login password");
        } else if (error.status === 403) {
          this.layoutService.showAlert(MessageType.warning, "Oops! Your account has been suspended. Contact admin to reactivate account.");
        } else {
          this.layoutService.showAlert(MessageType.danger, "Something went wrong, Please try again later.");
        }
      }
    ));
	}

	isControlHasError(controlName: string, validationType: string = undefined): boolean {
		const control = this.lockForm.controls[controlName];
		if (!control) {
			return false;
		}
		if (validationType) {
			const result = control.hasError(validationType) && (control.dirty || control.touched);
			return result;
		}
		if (control.errors && (control.dirty || control.touched)) {
			return true;
		}
		return false;
	}
}

