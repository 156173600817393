import { Component, OnInit, AfterViewInit, ViewEncapsulation, ViewChild, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
// RxJS
import { Observable, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
import { NgbDropdownMenu } from "@ng-bootstrap/ng-bootstrap";
//Moment
import * as moment from 'moment';
// Messaging initialize
import { MessagingService, Message } from '../../../../core/fcm';

import {
  QueryParamsModel,
  LayoutUtilsService,
  MessageType
} from "src/app/core/_base/crud";


@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html'
})
export class NotificationsComponent implements OnInit, OnDestroy {

  configData;

  @ViewChild(NgbDropdownMenu) caseCodeDropdown: NgbDropdownMenu;

  notificationsList: Message[] = [];
  sortOrder: 'asc' | 'desc' = 'desc';
  sortField: string = 'time';
  pageNumber: number = 1;
  pageSize: number = 10;
  totalCount: number = 0;
  isLoading: boolean = false;
  isEmpty: boolean = true;

  unReadCount: number = 0;

  private subscriptions: Subscription[] = [];

  constructor(private router: Router,
    private cdr: ChangeDetectorRef,
    private store: Store<AppState>,
    private messagingService: MessagingService) { }

  ngOnInit(): void {
    this.configData = {
      suppressScrollX: true,
      wheelSpeed: 0.3
    };
    this.loadNotificationList();
    this.messagingService.requestPermission();
    this.messagingService.receiveMessage();
    this.messagingService.currentMessage.subscribe(message => {
      if (message) {
        console.log(message);
        this.loadNotificationList();
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sb => sb.unsubscribe());
  }

  loadNotificationList() {
    this.isLoading = true;
    const queryParms = new QueryParamsModel(
      this.filterConfiguration(),
      this.sortOrder,
      this.sortField,
      this.pageNumber,
      this.pageSize,
      ''
    );

    const notificationSub = this.messagingService.getAllNotifications(queryParms).subscribe(res => {
      console.log(res);
      this.isLoading = false;
      this.notificationsList = res.items;
      this.totalCount = res.totalCount;
      this.unReadCount = res.unReadCount;
      if (res.totalCount === 0) {
        this.isEmpty = true;
      } else {
        this.isEmpty = false;
      }
      this.cdr.detectChanges();
    })
  }

  markAllRead(_id: string = undefined) {
    let data = {
      allRead: true,
      id: undefined
    };
    if (_id) {
      data = {
        allRead: false,
        id: _id
      };
    }
    const notificationSub = this.messagingService.updateMessageReadStatus(data).subscribe(res => {
      console.log(res);
      if (res.response.code === 202) {
        this.loadNotificationList();
        this.cdr.detectChanges();
      }
    });
  }


  filterConfiguration() {
    let filter: string = "";
    return filter;
  }

  unreadCount(): number {
    if (this.unReadCount > 0) {
      return this.unReadCount;
    }
    let selected = this.notificationsList.filter(el => el.isRead === false);
    if (selected && selected.length > 0) {
      return selected.length;
    }
    return 0;
  }

  getTimeAgo(time) {
    return moment(time).fromNow();
  }

  navigateUrl(message) {
    if (!message.link) {
      //return '/notifications/' + message._id;
      return '/notifications';
    }
    return message.link;
  }

  closeDropDown() {
    this.caseCodeDropdown.dropdown.close();
  }

  getModuleIcon(module){
    switch (module) {
      case 'operationsTeamManagement':
        return 'bx-user';
        break;
      case 'driverManagement':
        return 'bx-taxi';
        break;
      case 'kycManagement':
        return 'bx-user-check';
        break;
      case 'riderManagement':
        return 'bx-briefcase';
        break;
      case 'subscriptionManagement':
      case 'subscribersManagement':
        return 'bx-money';
        break;
      case 'referralBonusSettlementManagement':
        return 'bx-user-voice';
        break;
      case 'rideReportsManagement':
        return 'bx-bar-chart-alt-2';
        break;
      case 'tripFareReportsManagement':
        return 'bx-notepad';
        break;
      case 'locationManagement':
        return 'bx-location';
        break;
      case 'vehicleAttributesManagement':
        return 'bx-car-mechanic';
        break;
      case 'reasonsManagement':
        return 'bx-notepad';
        break;
      case 'taxManagement':
        return 'bx-money';
        break;
      case 'tripFareSettings':
        return 'bx-cog';
        break;
      case 'commissionSettlementSettings':
        return 'bx-cog';
        break;
      case 'taxInvoiceSettings':
        return 'bx-cog';
        break;
      case 'referralBonusSettings':
        return 'bx-cog';
        break;
      case 'surgeChargeSettings':
        return 'bx-cog';
        break;
      case 'generalSettings':
        return 'bx-cog';
        break;
      default:
        return 'bx-user';
        break;
    }
  }

}
