<div class="dropdown d-inline-block" ngbDropdown>
  <button
    type="button"
    class="btn header-item"
    ngbDropdownToggle
    id="page-header-user-dropdown"
    *ngIf="user$ | async as _user"
  >
    <img
      class="rounded-circle header-profile-user"
      src="{{_user?.profileImage?.imageUrl ? _user?.profileImage?.imageUrl : '/assets/images/icons/profile.png'}}"
      alt=""
    />
    <span class="d-none d-xl-inline-block ml-1">{{ _user.name ? 'Hi '+(_user.name | titlecase) : 'Hi Nobie'}}</span>
    <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
  </button>
  <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
    <!-- item-->
    <a
      id="profileLink"
      class="dropdown-item"
      href="javascript: void(0);"
      routerLink="/profile" routerLinkActive="active"
      ><i class="bx bx-user font-size-16 align-middle mr-1"></i>
      Profile</a
    >
    <a id="lockScreenLink" class="dropdown-item" href="javascript: void(0);" (click)="lockScreen()"
      ><i class="bx bx-lock-open font-size-16 align-middle mr-1"></i> Lock
      screen</a
    >
    <div class="dropdown-divider"></div>
    <a id="logoutLink" class="dropdown-item text-danger" href="javascript: void(0);" (click)="logout()"
      ><i
        class="bx bx-power-off font-size-16 align-middle mr-1 text-danger"
      ></i>
      Logout</a
    >
  </div>
</div>
