import { Zone } from '../../master-data/_models/zone.model';
import { City } from '../../master-data/_models/city.model';

export class Commission {
  _id: string;
  zoneId: Zone;
  cityId: City;
  isPercentage: boolean;
  commissionFare: number;
  apThreshold: number;
  apDefaulterBuffer: number;
  apClearenceCycle: string;
  apClearenceDay: string;
  // case1: CommissionDetails;
  // case2: CommissionDetails;
  // case3: CommissionDetails;
  // case4: CommissionDetails;
  // case5: CommissionDetails;
  // case6: CommissionDetails;
  // case7: CommissionDetails;
  createdById: string;
  isDelete: boolean;

  clear(): void {
    this._id = undefined;
    this.zoneId = new Zone();
    this.zoneId.clear();
    this.cityId = new City();
    this.cityId.clear();
    this.isPercentage = true;
    this.commissionFare = 0;
    this.apThreshold = 0;
    this.apDefaulterBuffer = 0;
    this.apClearenceCycle = 'weekly';
    this.apClearenceDay = 'last';
    // this.case1 = new CommissionDetails();
    // this.case2 = new CommissionDetails();
    // this.case3 = new CommissionDetails();
    // this.case4 = new CommissionDetails();
    // this.case5 = new CommissionDetails();
    // this.case6 = new CommissionDetails();
    // this.case7 = new CommissionDetails();
    // this.case1.clear();
    // this.case2.clear()
    // this.case3.clear()
    // this.case4.clear()
    // this.case5.clear()
    // this.case6.clear()
    // this.case7.clear()
    this.createdById = '';
    this.isDelete = false;
  }
}

// export class CommissionDetails {
//   pD: number;
//   pDR: number;
//   prD: number;
//   prDR: number;

//   clear(): void {
//     this.pD = 0;
//     this.pDR = 0;
//     this.prD = 0;
//     this.prDR = 0;
//   }
// }

