// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

// RxJS
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
// Auth
import { AuthService } from '../../../../core/auth/';
import { ConfirmPasswordValidator } from './confirm-password.validator';
// Layout
import { LayoutUtilsService, MessageType } from '../../../../core/_base/crud/';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html'
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  resetForm: FormGroup;
  loading = false;

  private subscriptions: Subscription[] = [];
  private resetToken: any;

  passwordType: string[] = ['password', 'password'];

  constructor(
    private router: Router,
    private auth: AuthService,
    public fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private layoutService: LayoutUtilsService
  ) { }

  ngOnInit() {
    this.initForm();
    this.subscriptions.push(this.route.queryParams.subscribe(params => {
      this.resetToken = params.token || '/';
      console.log(this.resetToken);
      if (this.resetToken === '/') {
        this.router.navigateByUrl('/error/401');
      }
      if (params.type === 'first-login') {
        this.layoutService.showAlert(MessageType.info, "First time login requires a forced password reset. Please change the default password to continue.");
      }
    }));
  }

  /*
  * On destroy
  */
  ngOnDestroy(): void {
    this.layoutService.showAlert();
    this.loading = false;
    this.subscriptions.forEach(el => el.unsubscribe());
  }

  /**
   * Form initalization
   * Default params, validators
   */
  initForm() {
    this.resetForm = this.fb.group({
      password: ['', Validators.compose([
        Validators.required,
        Validators.pattern("(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&#^])[A-Za-z0-9\d$@$!%*?&].{7,}")
      ])
      ],
      confirmPassword: ['', Validators.compose([
        Validators.required
      ])
      ]
    }, {
      validator: ConfirmPasswordValidator.MatchPassword
    });
  }

  togglePassword(index) {
    if (this.passwordType[index] === 'password') {
      this.passwordType[index] = 'text';
    } else {
      this.passwordType[index] = 'password';
    }
  }

  onSubmit() {
    const controls = this.resetForm.controls;
    // check form
    if (this.resetForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      return;
    }
    this.loading = true;

    this.subscriptions.push(this.auth.resetPassword(controls.password.value, this.resetToken).subscribe(
      res => {
        this.loading = false;
        this.cdr.detectChanges();
        console.log(res);
        if (res.response.code === 201) {
          this.initForm();
          this.layoutService.showAlert(MessageType.success, "Password has been updated successfully. Redirecting in 3 sec.");
          setTimeout(() => {
            this.router.navigateByUrl('/auth/login');
          }, 3000);
        }
      },
      error => {
        this.loading = false;
        this.cdr.detectChanges();
        console.log(error);
        if (error.status === 404) {
          this.layoutService.showAlert(MessageType.danger, "User does not exist");
        } else if (error.status === 401) {
          this.layoutService.showAlert(MessageType.danger, "Reset password link you are using is expired or invalid.");
        } else if (error.status === 403) {
          this.layoutService.showAlert(MessageType.warning, "Oops! Your account has been suspended. Contact admin to reactivate account.");
        } else {
          this.layoutService.showAlert(MessageType.danger, "Something went wrong, Please try again later.");
        }
      }
    ));
  }


  isControlHasError(controlName: string, validationType: string = undefined): boolean {
    const control = this.resetForm.controls[controlName];
    if (!control) {
      return false;
    }
    if (validationType) {
      const result = control.hasError(validationType) && (control.dirty || control.touched);
      return result;
    }
    if (control.errors && (control.dirty || control.touched)) {
      return true;
    }
    return false;
  }

}
