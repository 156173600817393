import { Attribute } from './attribute.model';

export class Vehicle {
  _id:string;
  seats: Attribute;
  availableSeats: number;
  registrationNumber: string;
  manufacturingYear:number;
  vehicleModel: Attribute;
  brand: Attribute;
  vehicleType: Attribute;
  color: Attribute;
  engineNumber: string;
  isDeleted: boolean;
  readOnly: boolean;
  createdById: string;
  vehicleImageId:{
    imageUrl: string,
    fileUploadId: string
  };
  digitallyVerified: boolean;
  __v: number;

  clear(): void {
    this._id = undefined;
    this.seats = new Attribute();
    this.seats.clear();
    this.availableSeats = 2;
    this.registrationNumber = "";
    this.manufacturingYear = undefined;
    this.vehicleModel = new Attribute();
    this.vehicleModel.clear();
    this.brand = new Attribute();
    this.brand.clear();
    this.vehicleType = new Attribute();
    this.vehicleType.clear();
    this.color = new Attribute();
    this.color.clear();
    this.engineNumber = "";
    this.isDeleted = false;
    this.readOnly = false;
    this.createdById = '';
    this.vehicleImageId = {
      imageUrl : undefined,
      fileUploadId: undefined
    };
    this.digitallyVerified = false;
    this.__v = 0;
  }
}
