<div class="filter-dropdown d-inline-block mr-3">
  <div class="dropdown-mega" ngbDropdown>
    <button
      id="toggleFilterByButton"
      type="button"
      class="btn btn-no-effect rounded dropdown-toggle w-100 font-size-12 text-left"
      ngbDropdownToggle
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      i18n="@@megamenuButton"
    >
      <i class="bx bx-filter-alt font-size-14 mr-1"></i> Filter By
      <span *ngIf="!isFilterApplied()">
        <i class="bx bx-braille filter-custom-icon font-size-18"></i>
      </span>
      <span id="clearFilterByButton" (click)="clearFilter()" *ngIf="isFilterApplied()">
        <i
          class="fas fa-times filter-custom-icon text-danger"
          style="cursor: pointer"
        ></i>
      </span>
    </button>
    <div class="dropdown-menu dropdown-megamenu" [ngStyle]="{'width.px': 180*(filterBy?.length || 0)}" ngbDropdownMenu>
      <div class="row">
        <div class="col-sm-12">
          <div class="row">
            <ng-container *ngFor="let collection of filterBy">
              <div class="col-md" *ngIf="(collection.isAdvanced && isAdvancedSearch) || !collection.isAdvanced">
                <h5 class="font-size-13 mb-3">{{collection?.title || ''}}</h5>
                <ul class="list-unstyled megamenu-list font-size-12" *ngIf="collection?.value?.length > 0">
                  <li *ngFor="let item of collection?.value; let i = index">
                    <div class="custom-control custom-checkbox mb-2">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        [(ngModel)]="item.isChecked"
                        id="{{ item.value+collection.key }}"
                        (change)="reloadList(collection, item, item.isChecked)"
                      />
                      <label
                        class="custom-control-label"
                        for="{{ item.value+collection.key }}"
                        value="item.value"
                      >
                        {{ item.label }}</label
                      >
                    </div>
                  </li>
                </ul>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
