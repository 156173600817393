// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
// Actions
import { LogActions, LogActionTypes } from '../_actions/logs.actions';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { Log } from '../_models/log.model';
export interface LogsState extends EntityState<Log> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedLogId: string;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}
// export const adapter: EntityAdapter<Log> = createEntityAdapter<Log>();

export const adapter: EntityAdapter<Log> = createEntityAdapter({
    selectId: (log: Log) => log._id,
  });
export const initialLogsState: LogsState = adapter.getInitialState({
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastQuery:  new QueryParamsModel(),
    lastCreatedLogId: undefined,
    showInitWaitingMessage: true
});
export function LogsReducer(state = initialLogsState, action: LogActions): LogsState {
    switch  (action.type) {
        case LogActionTypes.LogsPageToggleLoading: return {
            ...state, listLoading: action.payload.isLoading, lastCreatedLogId: undefined
        };
        case LogActionTypes.LogsActionToggleLoading: return {
            ...state, actionsloading: action.payload.isLoading
        };
        case LogActionTypes.LogsPageCancelled: return {
            ...state, listLoading: false, lastQuery: new QueryParamsModel()
        };
        case LogActionTypes.LogsPageLoaded: {
            return adapter.addMany(action.payload.logs, {
                ...initialLogsState,
                totalCount: action.payload.totalCount,
                lastQuery: action.payload.page,
                listLoading: false,
                showInitWaitingMessage: false
            });
        }
        default: return state;
    }
}


export const getLogstate = createFeatureSelector<LogsState>('logs');
export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
