import { Zone } from '../../master-data/_models/zone.model';
import { City } from '../../master-data/_models/city.model';
import { Attribute } from '../../master-data/_models/attribute.model';

export class Charge {
  _id: string;
  zoneId: string;
  cityId: string;
  vehicleType: string;
  rideNow: FareDetails;
  rental: FareDetails;
  halfDay: FareDetails;
  fullDay: FareDetails;
  outStationOneWay: FareDetails;
  outStationRound: FareDetails;
  airport: FareDetails;
  allowedSeats: {
    seats: number,
  };
  createdById: string;
  isDelete: boolean;

  clear(): void {
    this._id = undefined;
    this.zoneId = '';
    this.cityId = '';
    this.vehicleType = '';
    this.rideNow = new FareDetails();
    this.rental = new FareDetails();
    //this.halfDay = new FareDetails();
    //this.fullDay = new FareDetails();
    this.outStationOneWay = new FareDetails();
    this.outStationRound = new FareDetails();
    this.airport = new FareDetails();
    this.rideNow.clear();
    this.rental.clear();
    //this.halfDay.clear();
    //this.fullDay.clear();
    this.outStationOneWay.clear();
    this.outStationRound.clear();
    this.airport.clear();
    this.allowedSeats = {
      seats: 0,
    };
    this.createdById = '';
    this.isDelete = false;
  }
}

export class FareDetails {
  isEnabled: boolean;
  isSurgeApplicable: boolean;
  isExceptionApplicable: boolean;
  searchRange: number; //Meters
  searchRangeMin: number; //Meters
  fromSearchRange: number; //Meters
  fromSearchRangeMin: number; //Meters
  searchTime: number; //Milliseconds
  isOnDemandApplicable: boolean; // new
  isScheduleApplicable: boolean; // new
  isAirportFareFixed: boolean; // new

  baseFare: BaseFare[];
  extraDistancePrice: {
    isFixed: boolean,
    fixedPrice: number,
    minPrice: number,
    maxPrice: number
  };
  extraTimePrice: {
    isExtraTimePriceHourly: boolean;
    isFixed: boolean,
    fixedPrice: number,
    minPrice: number,
    maxPrice: number
  };
  driverBatta: {
    isFixed: boolean,
    fixedPrice: number,
    minPrice: number,
    maxPrice: number,
    isMinimumTimeEnabled: boolean,                        // mintime for enable, for that time finish only batta will enable
    minTime: number,                          // in minutes only applicable to outstation
  };
  permitCharge: {
    isFixed: boolean,
    fixedPrice: number,
    minPrice: number,
    maxPrice: number
  };
  freeWaitingTime: {
    isFixed: boolean,
    fixedTime: number,
    minTime: number,
    maxTime: number
  };
  waitingCharge: {
    isFixed: boolean,
    fixedPrice: number,
    minPrice: number,
    maxPrice: number
  };
  cancellationCharge: {
    isFixed: boolean,
    fixedPrice: number,
    minPrice: number,
    maxPrice: number,
    fixedTime: number,
    minTime: number,
    maxTime: number,
    fixedRange: number,
    minRange: number,
    maxRange: number,
  };
  gracePeriodTime: {
    isFixed: boolean,
    fixedTime: number,
    minTime: number,
    maxTime: number,
  };
  // new
  scheduleSettings: {
    minTime: number,
    maxTime: number,
    isMinFareEnable: boolean,
    minFare: number,
    rideConfirmationNoticeTime: number, // determine the cut off time before the scheduled ride when the assignment needs to be confirmed {{in seconds}}
    autoAssignmentWaitTime: number, // period of time scheduled ride will publish for nearby drivers {{in seconds}}
    manualAssignmentWaitTime: number, // last chance to assign a ride to driver after publish the ride {{in seconds}}
  };
  scheduleCancellationReward: {
    minFare: number,
    isPercentage: boolean,
    fixedPrice: number,
    isTimeSpecific: boolean,
    startTime: string,
    endTime: string,
    isDaySpecific: boolean,
    daysOfWeek: number[],
  };
  reservationCharge: {
    isFixed: boolean,
    fixedPrice: number,
    minPrice: number,
    maxPrice: number,
  };
  pickUpCharge: {
    paidBy: string; // rider. viaDots
    isFixed: boolean, // true: fixed price else km charge
    fixedPrice: number, // value
    fixedRange: number, // km limit
  }

  clear(): void {
    this.isEnabled = false;
    this.isSurgeApplicable = false;
    this.isExceptionApplicable = false;
    this.searchRange = 0;
    this.searchRangeMin = 0; //Meters
    this.fromSearchRange = 0;
    this.fromSearchRangeMin = 0; //Meters
    this.searchTime = 0;
    this.isOnDemandApplicable = true; // new
    this.isScheduleApplicable = true; // new
    this.isAirportFareFixed = false; // new
    this.baseFare = [];
    this.extraDistancePrice = {
      isFixed: true,
      fixedPrice: 0,
      minPrice: 0,
      maxPrice: 0
    };
    this.extraTimePrice = {
      isExtraTimePriceHourly: false,
      isFixed: true,
      fixedPrice: 0,
      minPrice: 0,
      maxPrice: 0
    };
    this.driverBatta = {
      isFixed: true,
      fixedPrice: 0,
      minPrice: 0,
      maxPrice: 0,
      isMinimumTimeEnabled: false,
      minTime: 0,
    };
    this.permitCharge = {
      isFixed: true,
      fixedPrice: 0,
      minPrice: 0,
      maxPrice: 0
    };
    this.freeWaitingTime = {
      isFixed: true,
      fixedTime: 0,
      minTime: 0,
      maxTime: 0
    };
    this.waitingCharge = {
      isFixed: true,
      fixedPrice: 0,
      minPrice: 0,
      maxPrice: 0
    };
    this.cancellationCharge = {
      isFixed: true,
      fixedPrice: 0,
      minPrice: 0,
      maxPrice: 0,
      fixedTime: 0,
      minTime: 0,
      maxTime: 0,
      fixedRange: 0,
      minRange: 0,
      maxRange: 0,
    };
    this.gracePeriodTime = {
      isFixed: true,
      fixedTime: 0,
      minTime: 0,
      maxTime: 0
    };
    this.scheduleSettings = {
      minTime: 60, // minutes
      maxTime: 7, // days
      isMinFareEnable: false,
      minFare: 0,
      rideConfirmationNoticeTime: 1800, // determine the cut off time before the scheduled ride when the assignment needs to be confirmed {{in seconds}}
      autoAssignmentWaitTime: 900, // period of time scheduled ride will publish for nearby drivers {{in seconds}}
      manualAssignmentWaitTime: 900,// last chance to assign a ride to driver after publish the ride {{in seconds}}
    };
    this.scheduleCancellationReward = {
      minFare: 0,
      isPercentage: false,
      fixedPrice: 0,
      isTimeSpecific: false,
      startTime: '',
      endTime: '',
      isDaySpecific: false,
      daysOfWeek: [],
    };
    this.reservationCharge = {
      isFixed: true,
      fixedPrice: 0,
      minPrice: 0,
      maxPrice: 0,
    };
    this.pickUpCharge = {
      paidBy: 'rider', // rider. viaDots
      isFixed: true, // true: fixed price else km charge
      fixedPrice: 0, // value
      fixedRange: 2, // km limit
    }
  }
}

// Base Price for charge and Slab for rental package models
export class BaseFare {
  isFixed: boolean;
  fixedRange: number;
  fixedPrice: number;
  fixedTime: number;
  minRange: number;
  maxRange: number;
  minTime: number;
  maxTime: number;
  minPrice: number;
  maxPrice: number;
  slab: number;

  clear(): void {
    this.isFixed = true;
    this.fixedRange = 0;
    this.fixedPrice = 0;
    this.fixedTime = 0;
    this.minRange = 0;
    this.maxRange = 0;
    this.minTime = 0;
    this.maxTime = 0;
    this.minPrice = 0;
    this.maxPrice = 0;
    this.slab = undefined;
  }
}

