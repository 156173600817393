// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, tap, delay, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { Observable, defer, of, forkJoin } from 'rxjs';
// NGRX
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store, select, Action } from '@ngrx/store';
// CRUD
import { QueryResultsModel, QueryParamsModel } from '../../_base/crud';
// Services
import { KycService } from '../../../core/kyc/_services/kyc.service';
// State
import { AppState } from '../../../core/reducers';
import {
    KycActionTypes,
    KycPageRequested,
    KycPageLoaded,
    KycActionToggleLoading,
    KycPageToggleLoading
} from '../_actions/kyc.actions';

@Injectable()
export class KycEffects {
    showPageLoadingDistpatcher = new KycPageToggleLoading({ isLoading: true });
    hidePageLoadingDistpatcher = new KycPageToggleLoading({ isLoading: false });

    showActionLoadingDistpatcher = new KycActionToggleLoading({ isLoading: true });
    hideActionLoadingDistpatcher = new KycActionToggleLoading({ isLoading: false });

    @Effect()
    loadKycPage$ = this.actions$
        .pipe(
            ofType<KycPageRequested>(KycActionTypes.KycPageRequested),
            debounceTime(50),
            distinctUntilChanged(),
          switchMap(( { payload } ) => {
                this.store.dispatch(this.showPageLoadingDistpatcher);
                const requestToServer = this.service.getAllKycList(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                console.log(response);
                const result: QueryResultsModel = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new KycPageLoaded({
                    kyc: result.items,
                    totalCount: result.totalCount,
                    page: lastQuery
                });
            }),
        );

    constructor(private actions$: Actions, private service: KycService, private store: Store<AppState>) { }
}
