// Angular
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-entity',
  templateUrl: './confirmation-entity.component.html',
  styleUrls: ['./confirmation-entity.component.scss']
})
export class ConfirmationEntityComponent implements OnInit {
	// Public properties
	viewLoading: boolean = false;

	constructor(
		public dialogRef: MatDialogRef<ConfirmationEntityComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {}

	ngOnInit() {
    console.log(this.data);
	}

	onCancel(): void {
		this.dialogRef.close();
	}

	onConfirm(): void {
		this.viewLoading = true;
		setTimeout(() => {
			this.dialogRef.close(true); // Keep only this row
		}, 500);
	}

	getIcon(type) {
		switch (type) {
			case "delete":
				return 'fa-trash-alt';
				break;
			case "suspend":
				return 'fa-times';
				break;
			case "reinstate":
				return 'fa-check';
				break;
			case "info":
				return 'fa-info';
				break;
			case "warning":
				return 'fa-exclamation';
				break;
			default:
				return 'fa-trash-alt';
				break;
		}
	}
}
