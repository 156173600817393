<div class="account-pages mt-5 mb-5">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card overflow-hidden mt-5">
          <div class="bg-soft-primary pt-5">
            <div class="row">
              <div class="col-12">
                <img src="assets/images/building.svg" alt="" class="img-fluid" />
              </div>
              <div class="position-absolute pr-4">
                <img src="assets/images/loginimg.svg" alt="" class="img-fluid" />
              </div>
            </div>
          </div>
          <div class="card-body pt-0" style="z-index: 2;">
            <div class="avatar-xl profile-user-wid">
              <a href="/">
                <img src="assets/images/logo.svg" alt="" class="img-thumbnail"/>
              </a>
            </div>
            <div class="p-2">
              <router-outlet></router-outlet>
            </div>
          </div>
        </div>
        <div class="mt-5 text-center" *ngIf="showSignIn === 1">
          <p>Already have an account?, Please<a id="signInLink1" href="javascript:void(0)" routerLink="/auth/login" class="font-weight-medium text-secondary"> Sign In here</a></p>
        </div>
        <div class="mt-5 text-center" *ngIf="showSignIn === 2">
          <p>Not you ? return to <a id="signInLink2" href="javascript:void(0)" (click)="login()" class="font-weight-medium text-secondary"> Sign In</a></p>
        </div>
      </div>
    </div>
  </div>
</div>
