
export class SurgeCharge {
  _id?: string;
  isRecurr?: boolean;
  daysOfWeek?: number[];
  isDelete: boolean;
  zoneId: string;
  cityId: string;
  timeType: string;
  startRecur: string;
  endRecur: string;
  startTime: string;
  endTime: string;
  titleMain: string;
  title: string;
  description: string;
  pricePercentage: number;
  className: string;
  createdById: string;
  readOnly: boolean;
  __v: number;

    clear(): void {
      this._id = undefined;
      this.isRecurr = false;
      this.daysOfWeek = [];
      this.isDelete = false,
      this.zoneId = undefined;
      this.cityId = undefined;
      this.timeType = 'surgeCharge';
      this.startRecur = '';
      this.endRecur = '';
      this.startTime = '';
      this.endTime = '';
      this.titleMain = '';
      this.title = '';
      this.description = '';
      this.pricePercentage = 1;
      this.className = '';
      this.createdById = '';
      this.readOnly = false;
      this.__v = 0;
    }
}
