import { Zone } from '../../master-data/_models/zone.model';
import { City } from '../../master-data/_models/city.model';

export class GeneralSettings {
  _id: string;
  searchRange: number; //na
  searchTime: number; //na
  isSurgeApplicable: boolean;
  isExceptionApplicable: boolean;
  isAutoIncrementalSearchEnabled: boolean;
  rentalThresholdTime: number;
  rentalThresholdDistance: number;
  freeCancellationCount: number; //na
  halfDayRange: number; //na
  fullDayRange: number; //na
  hardCopySubmissionSpan: number; //na
  rideTypeStatus: RideTypeStatus[];
  kycVerification: string[]; //  'zoopOne', 'surePass', 'all'

  freeCancellationDuration: number; //na
  /* For scheduled ride only */
  rideConfirmationNoticeTime: number; // determine the cut off time before the scheduled ride when the assignment needs to be confirmed {{in seconds}}
  autoAssignmentWaitTime: number; // period of time scheduled ride will publish for nearby drivers {{in seconds}}
  manualAssignmentWaitTime: number; // last chance to assign a ride to driver after publish the ride {{in seconds}}
  scheduleEmailNotificationList: string[];
  coolOffTime: number; // Minimum time between two rides for assign a schedule ride or accept ride {{in seconds}}

  rideExtraRequestTime: number; // determine the time period gave to publish onDemand rides {{in seconds}}
  driverLastUpdatedTime: number; // Minimum time to consider him as RFR {{in seconds}}

  rideActivityNotification: boolean;
  enableActualDriverList: boolean;
  enableNotificationForOfflineDriver: boolean;

  zoneId: Zone;
  cityId: City;
  createdById: string;
  __v: number;

    clear(): void {
      this._id = undefined;
      this.searchRange = 0;
      this.searchTime = 0;
      this.isSurgeApplicable = false;
      this.isExceptionApplicable = false;
      this.isAutoIncrementalSearchEnabled = false;
      this.rentalThresholdTime = 0;
      this.rentalThresholdDistance = 0;
      this.freeCancellationCount = 0;
      this.halfDayRange = 0;
      this.fullDayRange = 0;
      this.hardCopySubmissionSpan = 7;
      this.rideTypeStatus = [];
      this.kycVerification = [];
      this.rideActivityNotification = false;

      this.freeCancellationDuration = 0;
      this.rideConfirmationNoticeTime = 0;
      this.autoAssignmentWaitTime = 0;
      this.manualAssignmentWaitTime = 0;
      this.rideExtraRequestTime = 0;
      this.driverLastUpdatedTime = 0;
      this.coolOffTime = 0;
      this.enableActualDriverList = false;
      this.enableNotificationForOfflineDriver = false;
      this.scheduleEmailNotificationList = [];

      this.zoneId = new Zone();
      this.zoneId.clear();
      this.cityId = new City();
      this.cityId.clear();
      this.createdById = '';
      this.__v = 0;
    }
}

export class RideTypeStatus {
  type: string;
  status: boolean;
  enabledService: string[]; // "hourly", "package", "oneway", "roundTrip"
  enabledBooking: string[]; // "onDemand", "schedule"
  reason: string;

  clear(): void {
    this.type = '';
    this.status = false;
    this.enabledService = [];
    this.enabledBooking = ['onDemand', 'schedule'];
    this.reason = 'Unavailable';
  }
}
