import { Rider } from '../../rider/_models/rider.model';
import { Driver } from '../../driver/_models/driver.model';
import { Vehicle } from '../../master-data';
import { TripFareDetails } from './tripFareDetails.model';
import { PromotionSettlement } from '../../offer';
import { VehicleGroup } from '../../master-data';


export class RideReport {
  _id: string;
  driverCancel: {
    isCancel: boolean,
    reason: string,
    comment: string,
  };
  riderCancel: {
    isCancel: boolean,
    reason: string,
    comment: string,
  };
  adminCancel: {
    isCancel: boolean,
    reason: string,
    comment: string,
  };
  otpManagement : {
    time: string,
    otp: number
  };
  rideRequestStatus: string;
  ridersCount: number;
  isMeterTaxi: boolean;
  isDelete: boolean;
  isWaiting: boolean;
  waitingTimeDuration: number;
  waitingTime: string;
  isExceptionPresent: boolean;
  rideType: string;
  vehicleType: string;
  riderId: Rider;
  pickUpLat: string;
  pickUpLong: string;
  dropOffLat: string;
  dropOffLong: string;
  pickUpLocation: string;
  dropOffLocation: string;
  rideDistance: number;
  tripId: string;
  rideRequestTime: string;
  rideFare: number;
  driverId: Driver;
  isPrimaryDriver: boolean;
  riderType: string;
  rideStatus: string;
  vehicleId: Vehicle;
  cityId: string;
  zoneId: string;
  rideTime: string;
  ridePickupTime: string;
  rideStartTime: string;
  rideCompleteTime: string;
  rideScheduleTime: string;
  returnTime: string;
  rideDuration: number;
  rideFareId: string;
  isDue: boolean;
  taxSettled : boolean;

  paymentStatus: boolean;
  paymentMode: string;
  riderReview: {
    comment: string,
    rating: number
  };
  driverReview: {
    comment: string,
    rating: number
  };
  tripFare: TripFareDetails;
  rideLink: string;
  rideExpiryTime: string;

  locationChange: LocationChange[];
  isRentalPackage: boolean;
  rentalPackage: string;
  commitDropOffLat: string;
  commitDropOffLong: string;
  committedDropOffLocation: string;

  promotionSettlementDetail: PromotionSettlement;
  driverPromotionSettlementDetail: PromotionSettlement;
  isPromotionApplied: boolean;
  isDriverPromotionApplied: boolean;
  settlementStatus: boolean;

  vehicleGroupDetail: VehicleGroup;
  isVehicleGroup: boolean;
  rideReject: any[];

  walletUsageType: string; // viaCash, viaCoin
  useWalletBalance: boolean;

  isGenuine: boolean;
  verifiedTime: string;

  rideAutoAssignmentTime: string;
  rideManualAssignmentTime: string;
  rideRequestExpiryTime: string;

  notified: boolean;               //To notify admin
  isNewRiderOnboarded: boolean;
  isPromotionSettledOnWallet: boolean;
  scheduleCancelReward: {
    isEligible: boolean,
    reason: string,
  }

  odoMeterReading: {
    start: {
      meterValue: number,
      image: string,
      verifiedTime: string
    },
    end: {
      meterValue: number,
      image: string,
      verifiedTime: string
    },
  }

  __v: number;


  clear(): void {
    this._id = undefined;
    this.driverCancel = {
      isCancel: false,
      reason: '',
      comment: '',
    };
    this.riderCancel = {
      isCancel: false,
      reason: '',
      comment: '',
    };
    this.adminCancel = {
      isCancel: false,
      reason: '',
      comment: '',
    };
    this.otpManagement = {
      time: '',
      otp: undefined
    };
    this.rideRequestStatus = 'accept';
    this.ridersCount = 2;
    this.isMeterTaxi = false;
    this.isDelete = false;
    this.isWaiting = false;
    this.waitingTimeDuration = 0;
    this.waitingTime = null;
    this.isExceptionPresent = false;
    this.rideType = 'rideNow';
    this.vehicleType = '';
    this.riderId = new Rider();
    this.riderId.clear();
    this.pickUpLat = undefined;
    this.pickUpLong = undefined;
    this.dropOffLat = undefined;
    this.dropOffLong = undefined;
    this.pickUpLocation = '';
    this.dropOffLocation = '';
    this.rideDistance = 0;
    this.tripId = undefined;
    this.rideRequestTime = '';
    this.rideFare = 0;
    this.driverId = new Driver();
    this.driverId.clear();
    this.isPrimaryDriver = false;
    this.riderType = 'Guest';
    this.rideStatus = 'upComing';
    this.vehicleId = new Vehicle();
    this.vehicleId.clear();
    this.cityId = undefined;
    this.zoneId = undefined;
    this.rideTime = '';
    this.ridePickupTime = '';
    this.rideStartTime = '';
    this.rideCompleteTime = '';
    this.rideScheduleTime = '';
    this.returnTime = '';
    this.rideDuration = 0;
    this.rideFareId = undefined;
    this.isDue = false;
    this.taxSettled = false;
    this.paymentStatus = false;
    this.paymentMode = 'online';
    this.riderReview = {
      comment: '',
      rating: 0
    };
    this.driverReview = {
      comment: '',
      rating: 0
    };
    this.tripFare = new TripFareDetails();
    this.tripFare.clear();
    this.rideLink = undefined;
    this.rideExpiryTime = '';

    this.locationChange = [];
    this.isRentalPackage = false;
    this.rentalPackage = '';
    this.commitDropOffLat = '';
    this.commitDropOffLong = '';
    this.committedDropOffLocation = '';

    this.promotionSettlementDetail = new PromotionSettlement();
    this.driverPromotionSettlementDetail = new PromotionSettlement();
    this.isPromotionApplied = false;
    this.isDriverPromotionApplied = false;
    this.settlementStatus = true;

    this.vehicleGroupDetail = new VehicleGroup();
    this.vehicleGroupDetail.clear();
    this.isVehicleGroup = false;
    this.rideReject = [];

    this.walletUsageType = '';
    this.useWalletBalance = false;

    this.isGenuine = false;
    this.verifiedTime = undefined;

    this.rideAutoAssignmentTime = '';
    this.rideManualAssignmentTime = '';
    this.rideRequestExpiryTime = '';

    this.notified = false;               //To notify admin
    this.isNewRiderOnboarded = false;
    this.isPromotionSettledOnWallet = false;
    this.scheduleCancelReward = {
      isEligible: false,
      reason: '',
    }

    this.odoMeterReading = {
      start: {
        meterValue: 0,
        image: '',
        verifiedTime: ''
      },
      end: {
        meterValue: 0,
        image: '',
        verifiedTime: ''
      },
    }

    this.__v = 0;
  }
}

export class LocationChange {
  lat: string;
  long: string;
  time: string;
  address: string;
  comment: string;
  isComplete: boolean;

  clear(): void {
    this.lat = undefined;
    this.long = undefined;
    this.time = '';
    this.address = '';
    this.comment = '';
    this.isComplete = false;
  }
}


