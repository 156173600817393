
export class RecipientRazorPay {
  amount: number;
  razorpayAccountId: string;
  description: string;
  orderId: string;
  transferId: string;
  transferDetail: any;
  settlementId: string;
  utr: string;
  fee: number;
  tax: number;

  clear(): void {
    this.amount = 0;
    this.razorpayAccountId = '';
    this.description = '';
    this.orderId = '';
    this.transferId = '';
    this.transferDetail = {};
    this.settlementId = '';
    this.utr = '';
    this.fee = 0;
    this.tax = 0;
  }
}
