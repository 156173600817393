<ng-container *ngIf="userType == 'driver'">
  <ng-template ngxPermissionsOnly="initiateDirectCallToDriver">
    <ng-container [ngTemplateOutlet]="clickToCall"></ng-container>
  </ng-template>
</ng-container>
<ng-container *ngIf="userType == 'rider'">
  <ng-template ngxPermissionsOnly="initiateDirectCallToRider">
    <ng-container [ngTemplateOutlet]="clickToCall"></ng-container>
  </ng-template>
</ng-container>

<!--- Click To Call Starts -->
<ng-template #clickToCall>
  <span *ngIf="(id != currentCall)" ngbTooltip="Click to initiate call">
    <a
      id="initiateCallLink"
      href="javascript:void(0)"
      (click)="exotelService.initiateCall(id, userType, isExotelId)"
      ><i class="fa fa-phone font-size-14 ml-2"></i
    ></a>
  </span>
  <span *ngIf="id == currentCall" ngbTooltip="Calling is in progress">
    <i class="bx bx-loader bx-spin font-size-14 ml-2"></i>
  </span>
</ng-template>
<!--- Click To Call Ends -->
