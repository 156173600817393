export class Activity {
  _id: string;
  userId: string;
  clientip4: string;
  deviceid: string;
  devicename: string;
  language: string;
  platform: string;
  version: string;
  versioncode: string;
  versionname: string;
  userAgent: string;
  time: string;

  clear(): void {
    this._id = undefined;
    this.userId = undefined;
    this.clientip4 = '';
    this.deviceid = '';
    this.devicename = '';
    this.language = 'en';
    this.platform = '';
    this.version = '';
    this.versioncode = '';
    this.versionname = '';
    this.userAgent = '';
    this.time = '';
  }
}
