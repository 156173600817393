import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { RideReport } from '../_models/rideReport.model';
import { QueryParamsModel } from '../../_base/crud';

export enum RideReportActionTypes {
    AllRideReportsRequested = '[RideReports Module] All RideReports Requested',
    AllRideReportsLoaded = '[RideReports API] All RideReports Loaded',
    RideReportsPageRequested = '[RideReports List Page] RideReports Page Requested',
    RideReportsPageLoaded = '[RideReports API] RideReports Page Loaded',
    RideReportsPageCancelled = '[RideReports API] RideReports Page Cancelled',
    RideReportsPageToggleLoading = '[RideReports] RideReports Page Toggle Loading',
    RideReportsActionToggleLoading = '[RideReports] RideReports Action Toggle Loading'
}

export class RideReportsPageRequested implements Action {
    readonly type = RideReportActionTypes.RideReportsPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class RideReportsPageLoaded implements Action {
    readonly type = RideReportActionTypes.RideReportsPageLoaded;
    constructor(public payload: { rideReports: RideReport[], totalCount: number, totalAmount: number, page: QueryParamsModel }) { }
}

export class RideReportsPageCancelled implements Action {
    readonly type = RideReportActionTypes.RideReportsPageCancelled;
}
export class RideReportsPageToggleLoading implements Action {
    readonly type = RideReportActionTypes.RideReportsPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class RideReportsActionToggleLoading implements Action {
    readonly type = RideReportActionTypes.RideReportsActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}


export type RideReportActions = RideReportsPageLoaded
| RideReportsPageCancelled
| RideReportsPageRequested
| RideReportsPageToggleLoading
| RideReportsActionToggleLoading;

