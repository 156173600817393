// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
// Actions
import { DriverActions, DriverActionTypes } from '../_actions/driver.actions';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { Driver } from '../_models/driver.model';
// RxJS
import { Observable, of } from 'rxjs';

// tslint:disable-next-line:no-empty-interface
export interface DriversState extends EntityState<Driver> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedDriverId: string;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<Driver> = createEntityAdapter({
  selectId: (driver: Driver) => driver._id,
});

export const initialDriversState: DriversState = adapter.getInitialState({
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastQuery:  new QueryParamsModel(),
    lastCreatedDriverId: undefined,
    showInitWaitingMessage: true
});

export function driversReducer(state = initialDriversState, action: DriverActions): DriversState {
    switch  (action.type) {
        case DriverActionTypes.DriversPageToggleLoading: return {
            ...state, listLoading: action.payload.isLoading, lastCreatedDriverId: undefined
        };
        case DriverActionTypes.DriversActionToggleLoading: return {
            ...state, actionsloading: action.payload.isLoading
        };
        case DriverActionTypes.DriversPageCancelled: return {
            ...state, listLoading: false, lastQuery: new QueryParamsModel()
        };
        case DriverActionTypes.DriversPageLoaded: {
            // console.log(action.payload.drivers);
            return adapter.setAll(action.payload.drivers, {
                ...initialDriversState,
                totalCount: action.payload.totalCount,
                lastQuery: action.payload.page,
                listLoading: false,
                showInitWaitingMessage: false
            });
        }
        default: return state;
    }
}

export const getDriverState = createFeatureSelector<DriversState>('drivers');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
