// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
// Actions
import { RideReportActions, RideReportActionTypes } from '../_actions/rideReport.actions';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { RideReport } from '../_models/rideReport.model';
export interface RideReportsState extends EntityState<RideReport> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedRideReportId: string;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
    extra: any;
}
// export const adapter: EntityAdapter<RideReport> = createEntityAdapter<RideReport>();

export const adapter: EntityAdapter<RideReport> = createEntityAdapter({
    selectId: (rideReport: RideReport) => rideReport._id,
  });
export const initialRideReportsState: RideReportsState = adapter.getInitialState({
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastQuery:  new QueryParamsModel(),
    lastCreatedRideReportId: undefined,
    showInitWaitingMessage: true,
    extra: null,
});
export function RideReportsReducer(state = initialRideReportsState, action: RideReportActions): RideReportsState {
    switch  (action.type) {
        case RideReportActionTypes.RideReportsPageToggleLoading: return {
            ...state, listLoading: action.payload.isLoading, lastCreatedRideReportId: undefined
        };
        case RideReportActionTypes.RideReportsActionToggleLoading: return {
            ...state, actionsloading: action.payload.isLoading
        };
        case RideReportActionTypes.RideReportsPageCancelled: return {
            ...state, listLoading: false, lastQuery: new QueryParamsModel()
        };
        case RideReportActionTypes.RideReportsPageLoaded: {
            return adapter.addMany(action.payload.rideReports, {
                ...initialRideReportsState,
                totalCount: action.payload.totalCount,
                lastQuery: action.payload.page,
                listLoading: false,
                showInitWaitingMessage: false,
                extra: action.payload.totalAmount,
            });
        }
        default: return state;
    }
}


export const getRideReportstate = createFeatureSelector<RideReportsState>('rideReports');
export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
