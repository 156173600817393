<!--- Sidebar Starts -->
<ng-template #contentTemplate>
  <div id="sidebar-menu">
    <ul class="metismenu list-unstyled" id="side-menu" #sideMenu>
      <ng-container [ngTemplateOutlet]="menuListTemplate"></ng-container>
    </ul>
  </div>
</ng-template>
<!-- Sidebar Ends -->

<!--- Menu Starts -->
<ng-template #menuListTemplate>
  <ng-container *ngFor="let child of menuConfigService.menuList$ | async">
    <ng-container
      *ngIf="child.section"
      [ngTemplateOutlet]="menuItemSectionTemplate"
      [ngTemplateOutletContext]="{ item: child }"
    ></ng-container>
    <ng-container
      *ngIf="child.title"
      [ngTemplateOutlet]="menuItemTemplate"
      [ngTemplateOutletContext]="{ item: child }"
    ></ng-container>
  </ng-container>
</ng-template>
<!--- Menu Ends -->

<!--- Menu Section Starts -->
<ng-template
  #menuItemSectionTemplate
  let-item="item"
  let-parentItem="parentItem"
>
  <ng-container *ngIf="!item.permissions">
    <li class="menu-title">
      {{ item.section }}
      <span
        class="badge badge-{{ item.badge.variant }} float-right"
        *ngIf="item.badge"
        >{{ item.badge.text }}</span
      >
    </li>
  </ng-container>
  <ng-template *ngIf="item.permissions" [ngxPermissionsOnly]="item.permissions">
    <li class="menu-title">
      {{ item.section }}
      <span
        class="badge badge-{{ item.badge.variant }} float-right"
        *ngIf="item.badge"
        >{{ item.badge.text }}</span
      >
    </li>
  </ng-template>
</ng-template>
<!--- Menu Section Ends -->

<!--- Menu Items Starts -->
<ng-template #menuItemTemplate let-item="item" let-parentItem="parentItem">
  <ng-container
    *ngIf="!item.permission && !item.permissions"
    [ngTemplateOutlet]="menuItemInnerTemplate"
    [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"
  ></ng-container>
  <ng-template
    *ngIf="item.permission"
    ngxPermissionsOnly="{{ item.permission }}"
  >
    <ng-container
      [ngTemplateOutlet]="menuItemInnerTemplate"
      [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"
    ></ng-container>
  </ng-template>
  <ng-template *ngIf="item.permissions" [ngxPermissionsOnly]="item.permissions">
    <ng-container
      [ngTemplateOutlet]="menuItemInnerTemplate"
      [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"
    ></ng-container>
  </ng-template>
</ng-template>
<!--- Menu Items Ends -->

<!--- Menu Items inner Starts -->
<ng-template #menuItemInnerTemplate let-item="item" let-parentItem="parentItem">
  <li>
    <!-- if menu item hasn't submenu -->
    <a
      id="{{item.page}}"
      *ngIf="!item.submenu"
      [routerLink]="item.page"
      class="side-nav-link-ref menu-item-title"
      routerLinkActive="active"
    >
      <i class="bx {{ item.icon }}" *ngIf="item.icon"></i>
      <span> {{ item.title }}</span>
      <span
        class="badge badge-{{ item.badge.variant }} float-right"
        *ngIf="item.badge"
        >{{ item.badge.text }}</span
      >
    </a>
    <!-- if menu item has sumnenu child  -->
    <a
      *ngIf="item.submenu"
      href="javascript:void(0);"
      [ngClass]="{ 'has-arrow': !item.badge, 'has-dropdown': item.badge }"
      class="menu-item-title"
    >
      <i class="bx {{ item.icon }}" *ngIf="item.icon"></i>
      <span> {{ item.title }}</span>
      <span
        class="badge badge-{{ item.badge.variant }} float-right"
        *ngIf="item.badge"
        >{{ item.badge.text }}</span
      >
    </a>

    <!-- if menu item has submenu child then recursively call new menu item component -->
    <ul *ngIf="item.submenu" class="sub-menu" aria-expanded="false">
      <li *ngFor="let submenu of item.submenu">
        <ng-container *ngIf="!submenu.permission && !submenu.permissions">
          <ng-container
            [ngTemplateOutlet]="menuItemInnerTemplate"
            [ngTemplateOutletContext]="{
              item: submenu,
              parentItem: parentItem
            }"
          ></ng-container>
        </ng-container>
        <ng-template
          *ngIf="submenu.permission"
          ngxPermissionsOnly="{{ submenu.permission }}"
        >
          <ng-container
            [ngTemplateOutlet]="menuItemInnerTemplate"
            [ngTemplateOutletContext]="{
              item: submenu,
              parentItem: parentItem
            }"
          ></ng-container>
        </ng-template>
        <ng-template
          *ngIf="submenu.permissions"
          [ngxPermissionsOnly]="submenu.permissions"
        >
          <ng-container
            [ngTemplateOutlet]="menuItemInnerTemplate"
            [ngTemplateOutletContext]="{
              item: submenu,
              parentItem: parentItem
            }"
          ></ng-container>
        </ng-template>
      </li>
    </ul>
  </li>
</ng-template>
<!--- Menu Items inner Ends -->

<!--- Vertical Menu Starts -->
<div class="vertical-menu">
  <!-- <perfect-scrollbar [config]="configData"> -->
  <ngx-simplebar class="h-100" #componentRef>
    <div *ngIf="!isCondensed">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
    <div *ngIf="isCondensed">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
  </ngx-simplebar>
  <!-- </perfect-scrollbar> -->
</div>
<!--- Vertical Menu Ends -->

<!-- ========== Left Sidebar Ends ========== -->
