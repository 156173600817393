// Angular
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
// Components
import { BaseComponent } from "./views/theme/base/base.component";
import { ErrorPageComponent } from "./views/partials/general/error-page/error-page.component";
// Auth
import { AuthGuard } from "./core/auth";

const routes: Routes = [
  // Authentication
  {
    path: "auth",
    loadChildren: () =>
      import("./views/pages/auth/auth.module").then(m => m.AuthModule)
  },
  // Auth Guard Rotes
  {
    path: "",
    component: BaseComponent,
    canActivate: [AuthGuard],
    children: [
      // Dashboards
      {
        path: "dashboard",
        loadChildren: () =>
          import("./views/pages/dashboard/dashboard.module").then(
            m => m.DashboardModule
          )
      },
      // Management
      {
        path: "operations-team",
        loadChildren: () =>
          import("./views/pages/operations/operations.module").then(
            m => m.OperationsModule
          )
      },
      {
        path: "driver-management",
        loadChildren: () =>
          import("./views/pages/driver/driver.module").then(m => m.DriverModule)
      },
      {
        path: "kyc-management",
        loadChildren: () =>
          import("./views/pages/kyc/kyc.module").then(m => m.KycModule)
      },
      {
        path: "rider-management",
        loadChildren: () =>
          import("./views/pages/rider/rider.module").then(m => m.RiderModule)
      },
      {
        path: "customer-groups",
        loadChildren: () =>
          import("./views/pages/customer-group/customer-group.module").then(m => m.CustomerGroupModule)
      },
      {
        path: "subscription-management",
        loadChildren: () =>
          import("./views/pages/subscription/subscription.module").then(
            m => m.SubscriptionModule
          )
      },
      {
        path: "referral-management",
        loadChildren: () =>
          import("./views/pages/referral-bonus/referral-bonus.module").then(m => m.ReferralBonusModule)
      },
      {
        path: "offer-management",
        loadChildren: () =>
          import("./views/pages/offer/offer.module").then(m => m.OfferModule)
      },
      {
        path: "wallet",
        loadChildren: () =>
          import("./views/pages/wallet/wallet.module").then(m => m.WalletModule)
      },
      {
        path: "archives",
        loadChildren: () =>
          import("./views/pages/archives/archives.module").then(m => m.ArchivesModule)
      },
      {
        path: "invited",
        loadChildren: () =>
          import("./views/pages/invited/invited.module").then(m => m.InvitedModule)
      },
      // Reports
      {
        path: "ride-reports",
        loadChildren: () =>
          import("./views/pages/ride-report/ride-report.module").then(
            m => m.RideReportModule
          )
      },
      {
        path: "earnings",
        loadChildren: () =>
          import("./views/pages/earnings/earnings.module").then(
            m => m.EarningsModule
          )
      },
      {
        path: "trip-fare",
        loadChildren: () =>
          import("./views/pages/trip-fare/trip-fare.module").then(
            m => m.TripFareModule
          )
      },
      // Customer Engagement
      {
        path: "customer-engagement",
        loadChildren: () =>
          import("./views/pages/customer-engagement/customer-engagement.module").then(
            m => m.CustomerEngagementModule
          )
      },
      // Master Configuration
      {
        path: "master-data",
        loadChildren: () =>
          import("./views/pages/master-data/master-data.module").then(
            m => m.MasterDataModule
          )
      },
      {
        path: "control-panel",
        loadChildren: () =>
          import("./views/pages/control-panel/control-panel.module").then(
            m => m.ControlPanelModule
          )
      },
      // Logs and reports
      {
        path: "logs",
        loadChildren: () =>
          import("./views/pages/logs/logs.module").then(
            m => m.LogsModule
          )
      },
      // Tools
      {
        path: "tools",
        loadChildren: () =>
          import("./views/pages/tools/tools.module").then(
            m => m.ToolsModule
          )
      },
      // Others
      {
        path: "profile",
        loadChildren: () =>
          import("./views/pages/profile/profile.module").then(
            m => m.ProfileModule
          )
      },
      {
        path: "notifications",
        loadChildren: () =>
          import("./views/pages/notifications/notifications.module").then(
            m => m.NotificationsModule
          )
      },
      // Extra Routes With Auth Guard
      {
        path: "",
        redirectTo: "dashboard",
        pathMatch: "full"
      },
      {
        path: "**",
        redirectTo: "error/404",
        pathMatch: "full"
      }
    ]
  },
  // Extra Routes Without Auth Guard
  {
    path: "error/:code",
    component: ErrorPageComponent
  },
  {
    path: "**",
    redirectTo: "error/404",
    pathMatch: "full"
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
