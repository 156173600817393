export class MenuConfig {
	public defaults: any = {
		aside: {
			self: {},
			items: [
				{
					section: 'Dashboards',
          permissions: ['operationsDashboard', 'operationsTeamManagement', 'driverManagement', 'kycManagement', 'subscriptionManagement', 'subscribersManagement', 'rideReportsManagement']
				},
				{
					title: 'Dashboards',
					icon: 'bx-home-circle',
          permissions: ['operationsDashboard', 'operationsTeamManagement', 'driverManagement', 'kycManagement', 'subscriptionManagement', 'subscribersManagement', 'rideReportsManagement'],
					submenu: [
						{
							title: 'Operations',
							page: '/dashboard/operations',
              permission: 'operationsDashboard'
						},
            {
              title: 'Rides',
              page: '/dashboard/rides',
              permission: 'rideReportsManagement',
              badge: {
                variant: 'danger',
                text: 'Beta'
              },
            },
						// {
						// 	title: 'KYC',
						// 	page: '/dashboard/kyc',
						// 	permission: 'kycManagement'
						// },
						{
							title: 'Subscription',
							page: '/dashboard/subscription',
              permissions: ['subscriptionManagement', 'subscribersManagement']
						},
						{
							title: 'Driver',
							page: '/dashboard/driver',
							permission: 'driverManagement'
						},
					]
				},
				{
					section: 'Manage',
          //permissions: ['operationsTeamManagement', 'rolesManagement', 'workflowManagement', 'driverManagement', 'kycManagement', 'riderManagement', 'subscriptionManagement', 'subscribersManagement', 'referralBonusSettlementManagement', 'archivedUsersManagement']
          permissions: ['operationsTeamManagement', 'rolesManagement', 'workflowManagement', 'driverManagement', 'kycManagement', 'riderManagement', 'subscriptionManagement', 'subscribersManagement', 'archivedUsersManagement', 'offerManagement', 'promotionSettlementManagement']
				},
				{
					title: 'Operations Team',
					icon: 'bx-user',
          permissions: ['operationsTeamManagement', 'rolesManagement', 'workflowManagement'],
					submenu: [
						{
							title: 'Users',
							page: '/operations-team/users',
              permission: 'operationsTeamManagement'
						},
						{
							title: 'Roles',
							page: '/operations-team/roles',
              permission: 'rolesManagement'
						},
						{
							title: 'Work Flow',
							page: '/operations-team/work-flow',
              permission: 'workflowManagement'
						}
					]
				},
				// {
				// 	title: 'Aggregators',
				// 	icon: 'bx-user-plus',
				// 	page: '/aggregator-management'
				// },
				// {
				// 	title: 'Drivers',
				// 	icon: 'bx-taxi',
				// 	page: '/driver-management',
				// 	permission: 'driverManagement'
				// },
				// {
				// 	title: 'KYC',
				// 	icon: 'bx-user-check',
				// 	page: '/kyc-management',
				// 	permission: 'kycManagement'
				// },
				// {
				// 	title: 'Riders',
				// 	icon: 'bx-briefcase',
				// 	page: '/rider-management',
				// 	permission: 'riderManagement'
				// },
        {
          title: 'Customers',
          icon: 'bx-face',
          permissions: ['driverManagement', 'riderManagement', 'customerGroupManagement'],
          submenu: [
            {
              title: 'Drivers',
              icon: 'bx-taxi',
              page: '/driver-management',
              permission: 'driverManagement'
            },
            {
              title: 'Riders',
              icon: 'bx-briefcase',
              page: '/rider-management',
              permission: 'riderManagement'
            },
            {
              title: 'Customer Groups',
              icon: 'bx-group',
              page: '/customer-groups',
              permission: 'customerGroupManagement'
            },
          ]
        },
				{
					title: 'Subscriptions',
					icon: 'bx-money',
          permissions: ['subscriptionManagement', 'subscribersManagement'],
          submenu: [
            {
              title: 'Packages',
              page: '/subscription-management/packages',
              permission: 'subscriptionManagement'
            },
            {
              title: 'Subscribers',
              page: '/subscription-management/subscribers',
              permission: 'subscribersManagement'
            }
          ]
				},
        {
          title: 'Referral',
          icon: 'bx-user-voice',
          permissions: ['referralManagement', 'referralBonusSettlementManagement'],
          badge: {
            variant: 'success',
            text: 'New'
          },
          submenu: [
            {
              title: 'Referral Promotions',
              page: '/referral-management/promotions',
              badge: {
                variant: 'warning',
                text: 'v3.1'
              },
              permission: 'referralManagement'
            },
            {
              title: 'Referral Settlement',
              page: '/referral-management/referrals',
              permission: 'referralBonusSettlementManagement'
            },
            // {
            //   title: 'Redeem Requests',
            //   page: '/referral-management/requests',
            //   permission: 'referralBonusSettlementManagement'
            // },
            // {
            //   title: 'Processed Orders',
            //   page: '/referral-management/orders',
            //   permission: 'referralBonusSettlementManagement'
            // }
          ]
        },
        {
          title: 'Offers',
          icon: 'bx-gift',
          permissions: ['offerManagement', 'promotionSettlementManagement'],
          submenu: [
            {
              title: 'Promotions',
              page: '/offer-management/promotions',
              permission: 'offerManagement',
              badge: {
                variant: 'info',
                text: 'v4.0'
              },
            },
            {
              title: 'Promotion Settlements',
              page: '/offer-management/promotion-settlements',
              permission: 'promotionSettlementManagement'
            },
          ]
        },
        {
          title: 'Wallet',
          icon: 'bx-wallet',
          permissions: ['walletSettlementManagement'],
          submenu: [
            {
              title: 'Wallet Transactions',
              page: '/wallet/wallet-transactions',
              permission: 'walletSettlementManagement',
              badge: {
                variant: 'warning',
                text: 'New'
              },
            },
            {
              title: 'Withdrawal Request',
              page: '/wallet/withdrawal-request',
              permission: 'walletSettlementManagement',
              // badge: {
              //   variant: 'info',
              //   text: 'Supr!'
              // },
            },
          ]
        },
				{
					title: 'Archived Users',
					icon: 'bx-archive',
          permissions: ['operationsTeamManagement', 'driverManagement', 'riderManagement'],
					submenu: [
						{
							title: 'Archived Member',
							page: '/archives/operations',
              permission: 'operationsTeamManagement'
						},
						{
							title: 'Archived Drivers',
							page: '/archives/drivers',
              permission: 'driverManagement'
						},
						{
							title: 'Archived Riders',
							page: '/archives/riders',
              permission: 'riderManagement'
						}
					]
				},
        {
          title: 'Invited Users',
          icon: 'bx-paper-plane',
          permissions: ['driverManagement', 'riderManagement'],
          submenu: [
            {
              title: 'Invited Drivers',
              page: '/invited/drivers',
              permission: 'driverManagement'
            },
            {
              title: 'Invited Riders',
              page: '/invited/riders',
              permission: 'riderManagement'
            }
          ]
        },
				{
					section: 'Reports',
          //permissions: ['rideReportsManagement', 'pendingRequestNotification', 'tripFareReportsManagement']
          permissions: ['rideReportsManagement', 'pendingRequestNotification']
				},
				{
					title: 'Ride Report',
					icon: 'bx-bar-chart-alt-2',
					page: '/ride-reports',
					permission: 'rideReportsManagement'
				},
        {
          title: 'Pending Request',
          icon: 'bx-time',
          page: '/ride-reports/pending-rides',
          permission: 'pendingRequestNotification'
        },
				// {
				// 	title: 'Earnings',
				// 	icon: 'bx-credit-card',
				// 	page: '/earnings',
				// 	permission: 'earningsReportManagement'
				// },
				// {
				// 	title: 'Trip Fare Settlements',
				// 	icon: 'bx-notepad',
				// 	page: '/trip-fare',
				// 	permission: 'tripFareReportsManagement'
				// },
        {
          section: 'Customer Engagement',
          badge: {
            variant: 'success',
            text: 'Engage'
          },
          //permissions: ['customerEngagementTips', 'customerEngagementSections', 'customerEngagementVideos']
          permissions: ['customerEngagementTips', 'customerEngagementSections']
        },
        {
          title: 'Dynamic Sections',
          icon: 'bx-image-alt',
          page: '/customer-engagement/sections',
          badge: {
            variant: 'warning',
            text: 'Whooo!'
          },
          permission: 'customerEngagementSections'
        },
        // {
        //   title: 'Training Videos',
        //   icon: 'bxs-videos',
        //   page: '/customer-engagement/videos',
        //   badge: {
        //     variant: 'warning',
        //     text: 'New'
        //   },
        //   permission: 'customerEngagementSections'
        // },
        {
          title: 'Ride Request Tips',
          icon: 'bx-bulb',
          page: '/customer-engagement/tips',
          // badge: {
          //   variant: 'warning',
          //   text: 'New'
          // }
          permission: 'customerEngagementTips'
        },
        // {
        //   title: 'Custom Notifications',
        //   icon: 'bx-bell',
        //   page: '/customer-engagement/notification',
        //   badge: {
        //     variant: 'info',
        //     text: 'Soon'
        //   }
        //   //permission: 'pendingRequestNotification'
        // },
				{
					section: 'Admin Configurations',
          permissions: ['locationManagement', 'vehicleAttributesManagement', 'reasonsManagement', 'taxManagement', 'tripFareSettings', 'commissionSettlementSettings', 'taxInvoiceSettings', 'referralBonusSettings', 'surgeChargeSettings', 'generalSettings']
				},
				{
					title: 'Master Data',
					icon: 'bx-data',
          permissions: ['locationManagement', 'vehicleAttributesManagement', 'attributesManagement', 'reasonsManagement', 'taxManagement'],
					submenu: [
						{
							title: 'Zone Management',
							page: '/master-data/zone',
							permission: 'locationManagement'
						},
						{
							title: 'City Management',
							page: '/master-data/city',
							permission: 'locationManagement'
						},
						{
							title: 'Airport Management',
							page: '/master-data/airport',
							permission: 'locationManagement'
						},
            {
              title: 'Popular Packages',
			        page: '/master-data/popular/packages',
              permissions: ['locationManagement'],
              badge: {
                variant: 'warning',
                text: 'v1.0'
              },
            },
						{
							title: 'Vehicle Management',
							page: '/master-data/vehicles',
              permission: 'vehicleAttributesManagement'
						},
						{
							title: 'Reasons Management',
							page: '/master-data/reasons',
							permission: 'reasonsManagement'
						},
            {
              title: 'Attributes Management',
              page: '/master-data/attributes',
              permission: 'attributesManagement'
            },
						{
							title: 'Tax Management',
							page: '/master-data/tax',
              permission: 'taxManagement'
						}
					]
				},
				{
					title: 'Control Panel',
					icon: 'bx-cog',
          permissions: ['tripFareSettings', 'commissionSettlementSettings', 'taxInvoiceSettings', 'referralBonusSettings', 'surgeChargeSettings', 'generalSettings', 'targetConfiguration'],
          //permissions: ['tripFareSettings', 'taxInvoiceSettings', 'generalSettings'],
					submenu: [
						{
							title: 'Trip Fare',
							page: '/control-panel/fare-charge',
              permission: 'tripFareSettings'
						},
						{
							title: 'Commission Settlement',
							page: '/control-panel/commission',
              permission: 'commissionSettlementSettings'
						},
            {
              title: 'Tax & Invoicing',
              page: '/control-panel/tax-invoice',
              permission: 'taxInvoiceSettings'
            },
						// {
						// 	title: 'Referral Bonus Settings',
						// 	page: '/control-panel/referral-bonus',
            //   permission: 'referralBonusSettings'
						// },
            {
            	title: 'Wallet Configuration',
            	page: '/control-panel/wallet-configuration',
              permission: 'walletConfiguration'
            },
						{
							title: 'Surge Charge',
							page: '/control-panel/surge-charge',
              permission: 'surgeChargeSettings'
						},
            {
              title: 'Vehicle Groups',
              page: '/control-panel/vehicle-groups',
              permission: 'vehicleGroupManagement'
            },
            {
              title: 'Target Configuration',
              page: '/control-panel/target-configuration',
              permission: 'targetConfiguration'
            },
            {
              title: 'General Settings',
              page: '/control-panel/general-settings',
              permission: 'generalSettings'
            },
					]
				},
				{
					section: 'Logs',
          permissions: ['logsManagement']
				},
				{
					title: 'Logs',
					icon: 'bx-file',
					page: '/logs',
          permission: 'logsManagement'
				},
        {
          section: 'Tools',
          permissions: ['urlShortenerTool']
        },
        {
          title: 'URL Shortener',
          icon: 'bx-link',
          page: '/tools/url-shortener',
          permission: 'urlShortenerTool',
        },
        // {
        //   title: 'Data Sync',
        //   icon: 'bx-sync',
        //   page: '/tools/data-sync',
        //   permission: 'urlShortenerTool',
        // },
			]
		},
	};

	public get configs(): any {
		return this.defaults;
	}
}
