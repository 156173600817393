
export class Reason {
    _id: string;
    reason: string;
    reasonType: string;
    isDelete: boolean;

    clear(): void {
        this._id = undefined;
        this.reason = '';
        this.reasonType = '';
        this.isDelete = false;
    }
}
