<ngb-progressbar class="app-loading-bar" *ngIf="(loader.progress$|async) > 0" [value]="loader.progress$|async" height="2px" type="primary"></ngb-progressbar>
<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box" style="background-color: #192951;">
        <a id="logoActionLink1" href="/dashboard" class="logo logo-dark">
          <span class="logo-sm">
            <!-- <img src="assets/images/logo.svg" alt="" /> -->
            <img src="assets/images/logo.svg" style="width: 120px;" alt="" />
          </span>
          <!-- <span class="logo-lg">
            <img src="assets/images/logo-dark.png" alt="" />
          </span> -->
        </a>

        <a id="logoActionLink2" href="/dashboard" class="logo logo-light">
          <span class="logo-sm">
            <img src="assets/images/sm-logo.png" style="width: 30px;" alt="" />
          </span>
          <span class="logo-lg">
            <!-- <img src="assets/images/logo.svg" alt="" /> -->
            <img src="assets/images/logo.svg" style="width: 120px;" alt="" />
          </span>
        </a>
      </div>

      <button
        type="button"
        class="btn btn-sm px-3 font-size-16 header-item"
        id="vertical-menu-btn"
        (click)="toggleMobileMenu($event)"
      >
        <i class="fa fa-fw fa-bars"></i>
      </button>

      <div class="search-box d-inline-block mr-3 mt-3" style="width: 300px;">
        <div class="position-relative" (click)="openGlobalSearch()">
          <i class="bx bx-search-alt search-icon font-size-16"></i>
          <input id="searchTextInput" placeholder="Global Search..." type="text" class="form-control font-size-12" autocomplete="off" />
          <i class="bx bx-info-circle information-icon" [ngbTooltip]="'Search for drivers, riders, rides, kyc document etc globally'"></i>
        </div>
      </div>
    </div>

    <div class="d-flex">

      <div class="dropdown d-none d-lg-inline-block ml-1">
        <button
          id="fullScreenToggleButton"
          type="button"
          class="btn header-item noti-icon"
          data-toggle="fullscreen"
          (click)="fullscreen()"
        >
          <i class="bx bx-fullscreen"></i>
        </button>
      </div>

      <app-notifications></app-notifications>

      <app-profile></app-profile>
      <ng-template ngxPermissionsOnly="tripFareSettings">
      <div class="dropdown d-inline-block">
        <button
          id="settingsButton"
          type="button"
          class="btn header-item noti-icon right-bar-toggle"
          (click)="settingsClick()">
          <i class="bx bx-cog bx-spin"></i>
        </button>
      </div>
      </ng-template>
    </div>
  </div>
</header>

<app-global-search></app-global-search>


