// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { Driver } from '../_models/driver.model';
// Models
import { QueryParamsModel } from '../../_base/crud';

export enum DriverActionTypes {
    AllDriversRequested = '[Drivers Module] All Drivers Requested',
    AllDriversLoaded = '[Drivers API] All Drivers Loaded',
    DriversPageRequested = '[Drivers List Page] Drivers Page Requested',
    DriversPageLoaded = '[Drivers API] Drivers Page Loaded',
    DriversPageCancelled = '[Drivers API] Drivers Page Cancelled',
    DriversPageToggleLoading = '[Drivers] Drivers Page Toggle Loading',
    DriversActionToggleLoading = '[Drivers] Drivers Action Toggle Loading'
}

export class DriversPageRequested implements Action {
    readonly type = DriverActionTypes.DriversPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class DriversPageLoaded implements Action {
    readonly type = DriverActionTypes.DriversPageLoaded;
    constructor(public payload: { drivers: Driver[], totalCount: number, page: QueryParamsModel  }) { }
}


export class DriversPageCancelled implements Action {
    readonly type = DriverActionTypes.DriversPageCancelled;
}

export class DriversPageToggleLoading implements Action {
    readonly type = DriverActionTypes.DriversPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class DriversActionToggleLoading implements Action {
    readonly type = DriverActionTypes.DriversActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type DriverActions = DriversPageLoaded
| DriversPageCancelled
| DriversPageToggleLoading
| DriversPageRequested
| DriversActionToggleLoading;
