<div class="modal-header border-bottom-0">
  <button id="closeDialogButton" type="button" class="close" aria-hidden="true" (click)="closeDialog()">
    ×
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-12 pt-0 mt-0 pb-2">
      <div class="col-md-12">
        <app-alert></app-alert>
      </div>
      <div class="col-md-12">
        <div class="drop-area">
          <div class="upload-text" *ngIf="parsedData.length == 0">
            <span>Drag and drop or click to upload csv</span>
          </div>
          <input type="file" title="" id="fileInputArea" accept="text/csv/*" (change)="handleUploadEventDrop($event)"
            (click)="removeSelected($event)" />
          <div class="image-box" *ngIf="parsedData.length > 0">
            <img src="assets/images/csv-placeholder.jpeg">
            <div id="removeFileLink" class="remove-btn" (click)="removeFile()" ngbTooltip="Remove File">
              <i class="fas fa-trash-alt text-danger font-size-13"></i>
            </div>
            <div id="changeFileLink" class="update-btn" onclick="document.getElementById('fileInputArea').click()"
              ngbTooltip="Change File">
              <span><i class="fas fa-pencil-alt text-success"></i></span>
            </div>
          </div>
        </div>
        <p class="mt-2"><a id="downloadSampleLink" href="javascript:void(0)" (click)="downloadSample()">Click here</a> to download sample file.</p>
      </div>
      <div class="mt-3 col-md-12 text-right">
        <button id="cancelButton" type="button" class="btn btn-secondary w-sm mr-3" (click)="closeDialog()">
          Cancel
        </button>
        <button id="uploadButton" type="button" class="btn btn-primary w-sm" [disabled]="loading" (click)="onSubmit()">
          Upload
          <i *ngIf="loading" class="bx bx-loader bx-spin"></i>
        </button>
      </div>
    </div>
  </div>
</div>
