// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, tap } from 'rxjs/operators';
import { defer, Observable, of } from 'rxjs';
// NGRX
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
// Services
import { LocationService } from '../_services/location.service';
// Actions
import {
    AllCitiesLoaded,
    AllCitiesRequested,
    CityActionTypes
} from '../_actions/city.actions';
// Models
import { City } from '../_models/city.model';

@Injectable()
export class CityEffects {
    @Effect()
    loadAllCities$ = this.actions$
        .pipe(
          ofType<AllCitiesRequested>(CityActionTypes.AllCitiesRequested),
          mergeMap(() => this.service.getCities()),
            map((result: City[]) => {
              console.log(result);
                return  new AllCitiesLoaded({
                    cities: result
                });
            })
          );

    @Effect()
    init$: Observable<Action> = defer(() => {
        return of(new AllCitiesRequested());
    });

  constructor(private actions$: Actions, private service: LocationService) { }
}
