import { Tax } from '../../master-data';

export class TaxInvoice {
  _id: string;
  taxApplicable: Tax[];
  isDelete: boolean;
  zoneId: string;
  invoiceName: string;
  invoiceAddress: {
    line1: string,
    line2: string
  };
  taxNumberLabel: string;
  taxNumber: string;
  createdById: string;
  __v: number;

  clear(): void {
    this._id = undefined;
    this.taxApplicable = [];
    this.isDelete = false;
    this.zoneId = undefined;
    this.invoiceName = '';
    this.invoiceAddress = {
      line1: '',
      line2: ''
    };
    this.taxNumberLabel = '';
    this.taxNumber = '';
    this.createdById = '';
    this.__v = 0;
  }
}
