// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { RideReportsState } from '../_reducers/rideReport.reducers';
import { each } from 'lodash-es';
import { RideReport } from '../_models/rideReport.model';

export const selectRideReportsState = createFeatureSelector<RideReportsState>('rideReports');

export const selectRideReportById = (_id: string) => createSelector(
    selectRideReportsState,
    rideReportsState => rideReportsState.entities[_id]
);

export const selectRideReportsPageLoading = createSelector(
    selectRideReportsState,
    rideReportsState => {
        return rideReportsState.listLoading;
    }
);

export const selectRideReportsActionLoading = createSelector(
    selectRideReportsState,
    rideReportsState => rideReportsState.actionsloading
);

export const selectLastCreatedRideReportId = createSelector(
    selectRideReportsState,
    rideReportsState => rideReportsState.lastCreatedRideReportId
);

export const selectRideReportsPageLastQuery = createSelector(
    selectRideReportsState,
    rideReportsState => rideReportsState.lastQuery
);

export const selectRideReportsInStore = createSelector(
    selectRideReportsState,
    rideReportsState => {
        const items: RideReport[] = [];
        each(rideReportsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: RideReport[] = httpExtension.sortArray(items, rideReportsState.lastQuery.sortField, rideReportsState.lastQuery.sortOrder);
      return new QueryResultsModel(result, rideReportsState.totalCount, '', rideReportsState.extra);

    }
);

export const selectRideReportsShowInitWaitingMessage = createSelector(
    selectRideReportsState,
    rideReportsState => rideReportsState.showInitWaitingMessage
);

export const selectHasRideReportsInStore = createSelector(
    selectRideReportsState,
    queryResult => {
        if (!queryResult.totalCount) {
            return false;
        }

        return true;
    }
);

