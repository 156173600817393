import { Component, OnInit, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, ElementRef, ViewChild, Input, OnChanges, OnDestroy } from '@angular/core';
import MetisMenu from 'metismenujs';
import { Router, NavigationEnd } from '@angular/router';
// RxJs
import { filter } from 'rxjs/operators';
// Object Path
import * as objectPath from 'object-path';
// Layout
import { LayoutConfigService, MenuConfigService } from '../../../core/_base/layout';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

/**
 * Sidebar component
 */
export class SidebarComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @ViewChild('componentRef') scrollRef;
  @ViewChild('sideMenu') sideMenu: ElementRef;
  @Input() isCondensed = false;

  currentRouteUrl = '';
  menu: any;
  configData = {
    suppressScrollX: true,
    wheelSpeed: 0.3
  };

  menuList: any[] = [];

  menuItemLoaded: boolean = false;

  // Private properties
  private subscriptions: Subscription[] = [];

  constructor(private layoutConfigService: LayoutConfigService,
              public menuConfigService: MenuConfigService,
              private router: Router,
              private cdr: ChangeDetectorRef) {

    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.currentRouteUrl = this.router.url.split(/[?#]/)[0];
        if (this.sideMenu) {
          setTimeout(() => {
            if (this.menuItemLoaded) {
              this._activateMenuDropdown();
              //this._scrollElement();
            }
          }, 300);
        }
      }
    });
  }

  ngOnInit() {
    document.body.setAttribute('data-sidebar', 'dark');

    this.currentRouteUrl = this.router.url.split(/[?#]/)[0];

    setTimeout(() => {
      this.menu = new MetisMenu(this.sideMenu.nativeElement);
      if (this.menuItemLoaded) {
        this._activateMenuDropdown();
        //this._scrollElement();
      }
    }, 2000);

    this.subscriptions.push(this.menuConfigService.menuList$.subscribe(res=>{
      this.menuItemLoaded = true;
      this._activateMenuDropdown();
    }));

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sb => sb.unsubscribe());
  }

  ngAfterViewInit() {
    this.menu = new MetisMenu(this.sideMenu.nativeElement);
    // if (this.menuItemLoaded) {
    //   this._activateMenuDropdown();
    //   //this._scrollElement();
    // }
  }

  ngOnChanges() {
    if (!this.isCondensed && this.sideMenu || this.isCondensed) {
      this.menu = new MetisMenu(this.sideMenu.nativeElement);
    } else if (this.menu) {
      this.menu.dispose();
    }
  }

  _scrollElement() {
    setTimeout(() => {
      if (document.getElementsByClassName("mm-active").length > 0) {
        const currentPosition = document.getElementsByClassName("mm-active")[0]['offsetTop'];
        if (currentPosition > 500)
          if (this.scrollRef.SimpleBar.getScrollElement() !== null)
            this.scrollRef.SimpleBar.getScrollElement().scrollTop =
              currentPosition + 300;
      }
    }, 300);
  }

  /**
   * remove active and mm-active class
   */
  _removeAllClass(className) {
    const els = document.getElementsByClassName(className);
    while (els[0]) {
      els[0].classList.remove(className);
    }
  }

  /**
   * Activate the parent dropdown
   */
  _activateMenuDropdown() {
    //console.log("reload menu list");
    this._removeAllClass('mm-active');
    this._removeAllClass('mm-show');
    const links = document.getElementsByClassName('side-nav-link-ref');
    let menuItemEl = null;
    // tslint:disable-next-line: prefer-for-of
    // for (let i = 0; i < links.length; i++) {
    //   // tslint:disable-next-line: no-string-literal
    //   if (links[i]['pathname'].includes(this.currentRouteUrl)) {
    //     console.log("menu matching");
    //     menuItemEl = links[i];
    //     break;
    //   }
    // }

    const paths = [];
    for (let i = 0; i < links.length; i++) {
      paths.push(links[i]['pathname']);
    }
    var itemIndex = paths.indexOf(window.location.pathname);
    if (itemIndex === -1) {
      const strIndex = window.location.pathname.lastIndexOf('/');
      const item = window.location.pathname.substr(0, strIndex).toString();
      menuItemEl = links[paths.indexOf(item)];
    } else {
      menuItemEl = links[itemIndex];
    }

    if (menuItemEl) {
      console.log("inside menu item");
      menuItemEl.classList.add('active');
      const parentEl = menuItemEl.parentElement;
      if (parentEl) {
        parentEl.classList.add('mm-active');
        const parent2El = parentEl.parentElement.closest('ul');
        if (parent2El && parent2El.id !== 'side-menu') {
          parent2El.classList.add('mm-show');
          const parent3El = parent2El.parentElement;
          if (parent3El && parent3El.id !== 'side-menu') {
            parent3El.classList.add('mm-active');
            const childAnchor = parent3El.querySelector('.has-arrow');
            const childDropdown = parent3El.querySelector('.has-dropdown');
            if (childAnchor) { childAnchor.classList.add('mm-active'); }
            if (childDropdown) { childDropdown.classList.add('mm-active'); }
            const parent4El = parent3El.parentElement;
            if (parent4El && parent4El.id !== 'side-menu') {
              parent4El.classList.add('mm-show');
              const parent5El = parent4El.parentElement;
              if (parent5El && parent5El.id !== 'side-menu') {
                parent5El.classList.add('mm-active');
                const childanchor = parent5El.querySelector('.is-parent');
                if (childanchor && parent5El.id !== 'side-menu') { childanchor.classList.add('mm-active'); }
              }
            }
          }
        }
      }
    }
  }

  /**
   * Returns true or false if given menu item has child or not
   * @param item menuItem
   */
  hasItems(item: any) {
    return item.subItems !== undefined ? item.subItems.length > 0 : false;
  }
}
