// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
// Actions
import { PromotionSettlementActions, PromotionSettlementActionTypes } from '../_actions/promotionSettlement.actions';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { PromotionSettlement } from '../_models/promotionSettlement.model';
// RxJS
import { Observable, of } from 'rxjs';

// tslint:disable-next-line:no-empty-interface
export interface PromotionSettlementState extends EntityState<PromotionSettlement> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedPromotionSettlementId: string;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<PromotionSettlement> = createEntityAdapter({
  selectId: (request: PromotionSettlement) => request._id,
});

export const initialPromotionSettlementState: PromotionSettlementState = adapter.getInitialState({
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastQuery:  new QueryParamsModel(),
    lastCreatedPromotionSettlementId: undefined,
    showInitWaitingMessage: true
});

export function PromotionSettlementReducer(state = initialPromotionSettlementState, action: PromotionSettlementActions): PromotionSettlementState {
    switch  (action.type) {
        case PromotionSettlementActionTypes.PromotionSettlementPageToggleLoading: return {
            ...state, listLoading: action.payload.isLoading, lastCreatedPromotionSettlementId: undefined
        };
        case PromotionSettlementActionTypes.PromotionSettlementActionToggleLoading: return {
            ...state, actionsloading: action.payload.isLoading
        };
        case PromotionSettlementActionTypes.PromotionSettlementPageCancelled: return {
            ...state, listLoading: false, lastQuery: new QueryParamsModel()
        };
        case PromotionSettlementActionTypes.PromotionSettlementPageLoaded: {
            console.log(action.payload , "reducers");
            return adapter.setAll(action.payload.promotionSettlements, {
                ...initialPromotionSettlementState,
                totalCount: action.payload.totalCount,
                lastQuery: action.payload.page,
                listLoading: false,
                showInitWaitingMessage: false
            });
        }
        default: return state;
    }
}

export const getPromotionSettlementState = createFeatureSelector<PromotionSettlementState>('promotionSettlements');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
