export class DocTypes {
  public static docTypes: any = [
    {
      documentType: 1001,
      documentName: 'Vehicle Image',
      icon: 'mdi-camera-wireless',
      front: true,
      back: true,
      left: true,
      right: true,
      pdfAllowed: false,
      digitalAvailable: false
    },
    {
      documentType: 1002,
      documentName: 'Driver Image',
      icon: 'mdi-camera',
      front: true,
      pdfAllowed: false,
      digitalAvailable: false
    },
    {
      documentType: 1003,
      documentName: 'Driving License',
      icon: 'mdi-card-account-details',
      front: true,
      back: true,
      pdfAllowed: true,
      digitalAvailable: true
    },
    {
      documentType: 1004,
      documentName: 'Aadhaar Card',
      icon: 'mdi-card-bulleted',
      front: true,
      back: true,
      pdfAllowed: true,
      digitalAvailable: false
    },
    {
      documentType: 1005,
      documentName: 'PAN Card',
      icon: 'mdi-card-account-details-star-outline',
      front: true,
      pdfAllowed: true,
      digitalAvailable: true
    },
    {
      documentType: 1006,
      documentName: 'Present Address Proof',
      icon: 'mdi-map-check-outline',
      front: true,
      pdfAllowed: true,
      digitalAvailable: false
    },
    {
      documentType: 1007,
      documentName: 'Medical/Life Insurance',
      icon: 'mdi-medical-bag',
      front: true,
      pdfAllowed: true,
      digitalAvailable: false
    },
    {
      documentType: 1008,
      documentName: 'Vehicle Registration',
      icon: 'mdi-taxi',
      front: true,
      back: true,
      pdfAllowed: true,
      digitalAvailable: true
    },
    {
      documentType: 1009,
      documentName: 'Road Tax Receipt',
      icon: 'mdi-road-variant',
      front: true,
      pdfAllowed: true,
      digitalAvailable: false
    },
    {
      documentType: 1010,
      documentName: 'Pollution Control Certificate',
      icon: 'mdi-apple-icloud',
      front: true,
      pdfAllowed: true,
      digitalAvailable: false
    },
    {
      documentType: 1011,
      documentName: 'Vehicle Loan',
      icon: 'mdi-file-document',
      front: true,
      pdfAllowed: true,
      digitalAvailable: false
    },
    {
      documentType: 1012,
      documentName: 'Vehicle Insurance',
      icon: 'mdi-file-document-edit',
      front: true,
      pdfAllowed: true,
      digitalAvailable: false
    },
    {
      documentType: 1013,
      documentName: 'Bank Account',
      icon: 'mdi-bank',
      front: true,
      pdfAllowed: true,
      digitalAvailable: true
    },
    {
      documentType: 1014,
      documentName: 'Voters ID',
      icon: 'mdi-card-account-details-star',
      front: true,
      back: true,
      pdfAllowed: true,
      digitalAvailable: true
    }
  ];
}
