// Angular
import { Component, Input, OnDestroy, OnInit, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from "@angular/common";

// RXJS
import { Subscription, Subject } from 'rxjs';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ErrorPageComponent implements OnInit, OnDestroy {
	// full background image
	image: string;
	// error code, some error types template has it
	code: string;
	// error title
	title: string;
	// error subtitle, some error types template has it
	subtitle: string;
	// error descriptions
	desc: string;
	// return back button title
	return: any;

	private sub: Subscription;

	constructor(private route: ActivatedRoute,
				private router: Router,
    private location: Location,
				private cdr: ChangeDetectorRef) {}

	ngOnInit() {
		this.sub = this.route.params.subscribe(params => {
			this.code = params.code;
			switch (this.code) {
				case '404':
					this.code = '404';
					this.title = 'Lost In Desert...';
					this.subtitle = 'Hmm... Sorry we can\'t seem to find the page you\'re looking for. There may be a misspelling in the URL entered or the page you are looking for may no longer exist.';
					this.image = './assets/media/error/404.jpg';
					this.return = {
						label: 'Back to dashboard',
						returnUrl: '/dashboard'
				    };
				    break;
				case '403':
					this.code = '403';
					this.title = 'Access Forbidden...';
					this.subtitle = 'Oops! You don\'t have permission to access this module. Contact your administrator to get access to this module and reload this page again.';
					this.image = './assets/media/error/403.jpg';
					this.return = {
						label: 'Back to dashboard',
						returnUrl: '/dashboard'
				    };
					break;
				case '401':
					this.code = '401';
					this.title = 'Unauthorized...';
					this.subtitle = 'Oops! You don\'t have a valid access token to perform this operation. Contact your administrator for more details.';
					this.image = './assets/media/error/403.jpg';
					this.return = {
						label: 'Back to dashboard',
						returnUrl: '/dashboard'
				    };
					break;
				case '500':
					this.code = '500';
					this.title = 'Internal Server Error...';
					this.subtitle = 'Something went wrong, please try again after sometime. Server is not responding to your request or the server is temporarily offline.';
					this.image = './assets/media/error/500.jpg';
					this.return = {
						label: 'Back to dashboard',
						returnUrl: '/dashboard'
				    };
					break;
				case '504':
					this.code = '504';
					this.title = 'Gateway Timeout...';
					this.subtitle = 'Your request is not resolved by the server. There may be any issue with the request or the srever is temporarily offline.';
					this.image = './assets/media/error/504.jpg';
					this.return = {
						label: 'Back to dashboard',
						returnUrl: '/dashboard'
				    };
					break;
				default:
					this.code = '404';
					this.title = 'Lost In Desert...';
					this.subtitle = 'Hmm... Sorry we can\'t seem to find the page you\'re looking for. There may be a misspelling in the URL entered or the page you are looking for may no longer exist.';
					this.image = './assets/media/error/404.jpg';
					this.return = {
						label: 'Back to dashboard',
						returnUrl: '/dashboard'
				    };
			}
			this.cdr.detectChanges();
		});
	}

	ngOnDestroy(): void {
		this.sub.unsubscribe();
	}

  goBack() {
    this.location.back();
  }
}
