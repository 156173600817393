// NGRX
import { createSelector } from '@ngrx/store';
// Lodash
import { each, find, some } from 'lodash-es';
// Selectors
import { selectAllRoles } from './role.selectors';
import { selectAllModules } from './module.selectors';
// Models
import { Role } from '../_models/role.model';
import { Module } from '../_models/module.model';

export const selectAuthState = state => state.auth;

export const isLoggedIn = createSelector(
    selectAuthState,
    auth => auth.loggedIn
);

export const isLoggedOut = createSelector(
    isLoggedIn,
    loggedIn => !loggedIn
);


export const currentAuthToken = createSelector(
    selectAuthState,
    auth => auth.authToken
);

export const isUserLoaded = createSelector(
    selectAuthState,
    auth => auth.isUserLoaded
);

export const currentUser = createSelector(
    selectAuthState,
    auth => auth.user
);

export const currentUserRoleIds = createSelector(
    currentUser,
    user => {
        if (!user) {
            return [];
        }
        return [user.access];
    }
);

export const checkHasUserPermission = (moduleName: string) => createSelector(
    currentUserModules,
    (allModules: Module[]) => {
        return allModules.some(module => module.module === moduleName);
    }
);

export const checkHasUserPermissions = (moduleNames: string[]) => createSelector(
  currentUserModules,
  (allModules: Module[]) => {
    return allModules.some(module => moduleNames.some(name => (module.module === name || (module.module + 'View' === name && module.operations.view) || (module.module + 'Edit' === name && module.operations.edit) || (module.module + 'Delete' === name && module.operations.delete))));
  }
);

export const currentUserModules = createSelector(
    currentUserRoleIds,
    selectAllRoles,
    (roleIds: string[], allRoles: Role[]) => {
        const result: Module[] = [];
        each(roleIds, id => {
            //console.log(id, allRoles);
            const userModule = find(allRoles, elem => elem._id === id);
            //console.log(userModule);
            if (userModule) {
                userModule.modules.forEach(item=>{
                    result.push(item);
                });
            }
        });
        return result;
    }
);
