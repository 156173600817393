export class PageConfig {
  public defaults: any = {
    // Dashboard
    'dashboard': {
      'operations': {
        page: {
          title: 'Operations Dashboard',
          breadcrumb: [
            {
              title: 'Dashboards',
              page: '/dashboard',
            },
            {
              title: 'Operations',
            }
          ],
        }
      },
      'rides': {
        page: {
          title: 'Rides Dashboard',
          breadcrumb: [
            {
              title: 'Dashboards',
              page: '/dashboard',
            },
            {
              title: 'Rides',
            }
          ],
        }
      },
      'kyc': {
        page: {
          title: 'KYC Dashboard',
          breadcrumb: [
            {
              title: 'Dashboards',
              page: '/dashboard',
            },
            {
              title: 'KYC',
            }
          ],
        }
      },
      'subscription': {
        page: {
          title: 'Subscription Dashboard',
          breadcrumb: [
            {
              title: 'Dashboards',
              page: '/dashboard',
            },
            {
              title: 'Subscription',
            }
          ],
        }
      },
      'driver': {
        page: {
          title: 'Driver Dashboard',
          breadcrumb: [
            {
              title: 'Dashboards',
              page: '/dashboard',
            },
            {
              title: 'Driver',
            }
          ],
        }
      },
    },
    // Management
    'operations-team': {
      'users': {
        page: {
          title: 'Operations Team',
          breadcrumb: [
            {
              title: 'Management',
              page: '/operations-team',
            },
            {
              title: 'Operations Team',
              page: '/operations-team'
            },
            {
              title: 'Users'
            }
          ],
        }
      },
      'roles': {
        page: {
          title: 'Roles Management',
          breadcrumb: [
            {
              title: 'Management',
              page: '/operations-team',
            },
            {
              title: 'Operations Team',
              page: '/operations-team'
            },
            {
              title: 'Roles'
            }
          ],
        }
      },
      'work-flow': {
        page: {
          title: 'Work Flow Editor',
          breadcrumb: [
            {
              title: 'Management',
              page: '/operations-team',
            },
            {
              title: 'Operations Team',
              page: '/operations-team'
            },
            {
              title: 'Work Flow'
            }
          ],
        }
      }
    },
    'driver-management': {
      page: {
        title: 'Driver Management',
        breadcrumb: [
          {
            title: 'Management',
            page: '/operations-team',
          },
          {
            title: 'Driver Management',
          }
        ],
      }
    },
    'kyc-management': {
      page: {
        title: 'KYC Management',
        breadcrumb: [
          {
            title: 'Management',
            page: '/operations-team',
          },
          {
            title: 'KYC Management',
          }
        ],
      }
    },
    'rider-management': {
      page: {
        title: 'Rider Management',
        breadcrumb: [
          {
            title: 'Management',
            page: '/operations-team',
          },
          {
            title: 'Rider Management',
          }
        ],
      }
    },
    'customer-groups': {
      'groups': {
        page: {
          title: 'Customer Groups Management',
          breadcrumb: [
            {
              title: 'Management',
              page: '/operations-team',
            },
            {
              title: 'Customer Groups Management',
            }
          ],
        }
      }

    },
    'subscription-management': {
      'packages': {
        page: {
          title: 'Package Management',
          breadcrumb: [
            {
              title: 'Management',
              page: '/operations-team',
            },
            {
              title: 'Package Management',
            }
          ],
        },
        'trial': {
          page: {
            title: 'Trial Package Management',
            breadcrumb: [
              {
                title: 'Management',
                page: '/operations-team',
              },
              {
                title: 'Package Management',
                page: '/subscription-management/packages',
              },
              {
                title: 'Trial Package',
              }
            ],
          }
        },
      },
      'subscribers': {
        page: {
          title: 'Subscribers Management',
          breadcrumb: [
            {
              title: 'Management',
              page: '/operations-team',
            },
            {
              title: 'Subscribers Management',
            }
          ],
        }
      }
    },
    'referral-management': {
      'promotions': {
        page: {
          title: 'Referral Promotions List',
          breadcrumb: [
            {
              title: 'Management',
              page: '/operations-team',
            },
            {
              title: 'Referral Management',
              page: '/referral-management',
            },
            {
              title: 'Referral Promotions List',
            }
          ],
        }
      },
      'referrals': {
        page: {
          title: 'Referral Settlement List',
          breadcrumb: [
            {
              title: 'Management',
              page: '/operations-team',
            },
            {
              title: 'Referral Management',
              page: '/referral-management',
            },
            {
              title: 'Referral Settlement List',
            }
          ],
        }
      },
      // 'requests': {
      //   page: {
      //     title: 'Redeem Requests',
      //     breadcrumb: [
      //       {
      //         title: 'Management',
      //         page: '/operations-team',
      //       },
      //       {
      //         title: 'Referral Bonus Management',
      //         page: '/referral-management',
      //       },
      //       {
      //         title: 'Redeem Requests',
      //       }
      //     ],
      //   }
      // },
      // 'orders': {
      //   page: {
      //     title: 'Processed Orders',
      //     breadcrumb: [
      //       {
      //         title: 'Management',
      //         page: '/operations-team',
      //       },
      //       {
      //         title: 'Referral Bonus Management',
      //         page: '/referral-management',
      //       },
      //       {
      //         title: 'Processed Orders',
      //       }
      //     ],
      //   }
      // },
    },
    'offer-management': {
      'promotions': {
        page: {
          title: 'Promotions List',
          breadcrumb: [
            {
              title: 'Management',
              page: '/operations-team',
            },
            {
              title: 'Offer Management',
              page: '/offer-management',
            },
            {
              title: 'Promotions List',
            }
          ],
        }
      },
      'promotion-settlements': {
        page: {
          title: 'Promotion Settlements',
          breadcrumb: [
            {
              title: 'Management',
              page: '/operations-team',
            },
            {
              title: 'Offer Management',
              page: '/offer-management',
            },
            {
              title: 'Promotion Settlements List',
            }
          ],
        }
      },
      'orders': {
        page: {
          title: 'Processed Orders',
          breadcrumb: [
            {
              title: 'Management',
              page: '/operations-team',
            },
            {
              title: 'Offer Management',
              page: '/offer-management',
            },
            {
              title: 'Processed Orders',
            }
          ],
        }
      },
    },
    'wallet': {
      'wallet-transactions': {
        page: {
          title: 'Wallet Transactions',
          breadcrumb: [
            {
              title: 'Management',
              page: '/operations-team',
            },
            {
              title: 'Wallet',
              page: '/wallet',
            },
            {
              title: 'Wallet Transactions',
            }
          ],
        }
      },
      'withdrawal-request': {
        page: {
          title: 'Withdrawal Request',
          breadcrumb: [
            {
              title: 'Management',
              page: '/operations-team',
            },
            {
              title: 'Wallet',
              page: '/wallet',
            },
            {
              title: 'Withdrawal Request',
            }
          ],
        }
      },
    },
    'archives': {
      'operations': {
        page: {
          title: 'Archived Operations Member',
          breadcrumb: [
            {
              title: 'Management',
              page: '/archives/operations',
            },
            {
              title: 'Archived Users',
              page: '/archives/operations',
            },
            {
              title: 'Operations',
            }
          ],
        }
      },
      'drivers': {
        page: {
          title: 'Archived Drivers',
          breadcrumb: [
            {
              title: 'Management',
              page: '/archives/drivers',
            },
            {
              title: 'Archived Users',
              page: '/archives/drivers',
            },
            {
              title: 'Drivers',
            }
          ],
        }
      },
      'riders': {
        page: {
          title: 'Archived Riders',
          breadcrumb: [
            {
              title: 'Management',
              page: '/archives/riders',
            },
            {
              title: 'Archived Users',
              page: '/archives/riders',
            },
            {
              title: 'Riders',
            }
          ],
        }
      }
    },
    'invited': {
      'drivers': {
        page: {
          title: 'Invited Drivers',
          breadcrumb: [
            {
              title: 'Management',
              page: '/invited/drivers',
            },
            {
              title: 'Invited Users',
              page: '/invited/drivers',
            },
            {
              title: 'Drivers',
            }
          ],
        }
      },
      'riders': {
        page: {
          title: 'Invited Riders',
          breadcrumb: [
            {
              title: 'Management',
              page: '/invited/riders',
            },
            {
              title: 'Invited Users',
              page: '/invited/riders',
            },
            {
              title: 'Riders',
            }
          ],
        }
      }
    },
    // Reports
    'ride-reports': {
      page: {
        title: 'Ride Reports',
        breadcrumb: [
          {
            title: 'Reports',
            page: '/ride-reports',
          },
          {
            title: 'Ride Reports',
          }
        ],
      },
      'pending-rides': {
        page: {
          title: 'Pending Ride Requests',
          breadcrumb: [
            {
              title: 'Reports',
              page: '/ride-reports',
            },
            {
              title: 'Pending Requests',
            }
          ],
        }
      },
    },
    'earnings': {
      page: {
        title: 'Earnings',
        breadcrumb: [
          {
            title: 'Reports',
            page: '/ride-reports',
          },
          {
            title: 'Earnings',
          }
        ],
      }
    },
    'trip-fare': {
      page: {
        title: 'Trip Fare Settlements',
        breadcrumb: [
          {
            title: 'Reports',
            page: '/ride-reports',
          },
          {
            title: 'Trip Fare Settlements',
          }
        ],
      }
    },
    // Customer Engagement
    'customer-engagement': {
      'tips': {
        page: {
          title: 'Manage Ride Request Tips',
          breadcrumb: [
            {
              title: 'Customer Engagement',
              page: '/customer-engagement',
            },
            {
              title: 'Tips',
            }
          ],
        }
      },
      'sections': {
        page: {
          title: 'Manage Rider Home Screen Sections',
          breadcrumb: [
            {
              title: 'Customer Engagement',
              page: '/customer-engagement',
            },
            {
              title: 'Sections',
            }
          ],
        }
      },
      'videos': {
        page: {
          title: 'Manage Training Video Sections',
          breadcrumb: [
            {
              title: 'Customer Engagement',
              page: '/customer-engagement',
            },
            {
              title: 'Training Videos',
            }
          ],
        }
      },
    },
    // Master Configurations
    'master-data': {
      'zone': {
        page: {
          title: 'Zone Management',
          breadcrumb: [
            {
              title: 'Admin Configurations',
              page: '/master-data',
            },
            {
              title: 'Master Data',
              page: '/master-data',
            },
            {
              title: 'Zone Management',
            }
          ],
        }
      },
      'city': {
        page: {
          title: 'City Management',
          breadcrumb: [
            {
              title: 'Admin Configurations',
              page: '/master-data',
            },
            {
              title: 'Master Data',
              page: '/master-data',
            },
            {
              title: 'City Management',
            }
          ],
        }
      },
      'airport': {
        page: {
          title: 'Airport Management',
          breadcrumb: [
            {
              title: 'Admin Configurations',
              page: '/master-data',
            },
            {
              title: 'Master Data',
              page: '/master-data',
            },
            {
              title: 'Airport Management',
            }
          ],
        }
      },
      'popular': {
        'packages': {
          page: {
            title: 'Popular Packages Management',
            breadcrumb: [
              {
                title: 'Admin Configurations',
                page: '/master-data',
              },
              {
                title: 'Master Data',
                page: '/master-data',
              },
              {
                title: 'Popular Packages',
              }
            ],
          },
          'locations': {
            page: {
              title: 'Popular Locations Management',
              breadcrumb: [
                {
                  title: 'Admin Configurations',
                  page: '/master-data',
                },
                {
                  title: 'Master Data',
                  page: '/master-data',
                },
                {
                  title: 'Popular Locations',
                }
              ],
            }
          },
        },
      },
      'reasons': {
        page: {
          title: 'Reasons Management',
          breadcrumb: [
            {
              title: 'Admin Configurations',
              page: '/master-data',
            },
            {
              title: 'Master Data',
              page: '/master-data',
            },
            {
              title: 'Reasons Management',
            }
          ],
        }
      },
      'attributes': {
        page: {
          title: 'Attributes Management',
          breadcrumb: [
            {
              title: 'Admin Configurations',
              page: '/master-data',
            },
            {
              title: 'Master Data',
              page: '/master-data',
            },
            {
              title: 'Attributes Management',
            }
          ],
        }
      },
      'vehicles': {
        page: {
          title: 'Vehicles Management',
          breadcrumb: [
            {
              title: 'Admin Configurations',
              page: '/master-data',
            },
            {
              title: 'Master Data',
              page: '/master-data',
            },
            {
              title: 'Vehicles Management',
            }
          ],
        },
        'attributes': {
          page: {
            title: 'Manage Vehicle Attributes',
            breadcrumb: [
              {
                title: 'Admin Configurations',
                page: '/master-data',
              },
              {
                title: 'Master Data',
                page: '/master-data',
              },
              {
                title: 'Vehicles Management',
                page: '/master-data/vehicles',
              },
              {
                title: 'Vehicle Attributes',
              }
            ],
          }
        }
      },
      'tax': {
        page: {
          title: 'Tax Management',
          breadcrumb: [
            {
              title: 'Admin Configurations',
              page: '/master-data',
            },
            {
              title: 'Master Data',
              page: '/master-data',
            },
            {
              title: 'Tax Management',
            }
          ],
        }
      }
    },
    'control-panel': {
      'fare-charge': {
        page: {
          title: 'Trip Fare',
          breadcrumb: [
            {
              title: 'Admin Configurations',
              page: '/master-data',
            },
            {
              title: 'Control Panel',
              page: '/control-panel',
            },
            {
              title: 'Trip Fare',
            }
          ],
        },
        'ride-now': {
          page: {
            title: 'Trip Fare Configuration',
            breadcrumb: [
              {
                title: 'Admin Configurations',
                page: '/master-data',
              },
              {
                title: 'Control Panel',
                page: '/control-panel',
              },
              {
                title: 'Trip Fare Configuration',
                page: '/control-panel/fare-charge',
              },
              {
                title: 'City Ride',
              }
            ],
          }
        },
        'half-day': {
          page: {
            title: 'Trip Fare Configuration',
            breadcrumb: [
              {
                title: 'Admin Configurations',
                page: '/master-data',
              },
              {
                title: 'Control Panel',
                page: '/control-panel',
              },
              {
                title: 'Trip Fare Configuration',
                page: '/control-panel/fare-charge',
              },
              {
                title: 'Half Day',
              }
            ],
          }
        },
        'full-day': {
          page: {
            title: 'Trip Fare Configuration',
            breadcrumb: [
              {
                title: 'Admin Configurations',
                page: '/master-data',
              },
              {
                title: 'Control Panel',
                page: '/control-panel',
              },
              {
                title: 'Trip Fare Configuration',
                page: '/control-panel/fare-charge',
              },
              {
                title: 'Full Day',
              }
            ],
          }
        },
        'outstation-oneway': {
          page: {
            title: 'Trip Fare Configuration',
            breadcrumb: [
              {
                title: 'Admin Configurations',
                page: '/master-data',
              },
              {
                title: 'Control Panel',
                page: '/control-panel',
              },
              {
                title: 'Trip Fare Configuration',
                page: '/control-panel/fare-charge',
              },
              {
                title: 'Outstation One Way',
              }
            ],
          }
        },
        'outstation-round': {
          page: {
            title: 'Trip Fare Configuration',
            breadcrumb: [
              {
                title: 'Admin Configurations',
                page: '/master-data',
              },
              {
                title: 'Control Panel',
                page: '/control-panel',
              },
              {
                title: 'Trip Fare Configuration',
                page: '/control-panel/fare-charge',
              },
              {
                title: 'Outstation Round Trip',
              }
            ],
          }
        },
        'airport': {
          page: {
            title: 'Trip Fare Configuration',
            breadcrumb: [
              {
                title: 'Admin Configurations',
                page: '/master-data',
              },
              {
                title: 'Control Panel',
                page: '/control-panel',
              },
              {
                title: 'Trip Fare Configuration',
                page: '/control-panel/fare-charge',
              },
              {
                title: 'Airport',
              }
            ],
          }
        },
        'cancellation': {
          page: {
            title: 'Trip Fare Configuration',
            breadcrumb: [
              {
                title: 'Admin Configurations',
                page: '/master-data',
              },
              {
                title: 'Control Panel',
                page: '/control-panel',
              },
              {
                title: 'Trip Fare Configuration',
                page: '/control-panel/fare-charge',
              },
              {
                title: 'Cancellation Charge',
              }
            ],
          }
        },
        'waiting': {
          page: {
            title: 'Trip Fare Configuration',
            breadcrumb: [
              {
                title: 'Admin Configurations',
                page: '/master-data',
              },
              {
                title: 'Control Panel',
                page: '/control-panel',
              },
              {
                title: 'Trip Fare Configuration',
                page: '/control-panel/fare-charge',
              },
              {
                title: 'Waiting Charge',
              }
            ],
          }
        }
      },
      'commission': {
        page: {
          title: 'Commission Settlement',
          breadcrumb: [
            {
              title: 'Admin Configurations',
              page: '/master-data',
            },
            {
              title: 'Control Panel',
              page: '/control-panel',
            },
            {
              title: 'Commission Settlement',
            }
          ],
        }
      },
      'tax-invoice': {
        page: {
          title: 'Tax & Invoice Management',
          breadcrumb: [
            {
              title: 'Admin Configurations',
              page: '/master-data',
            },
            {
              title: 'Control Panel',
              page: '/control-panel',
            },
            {
              title: 'Tax & Invoicing',
            }
          ],
        }
      },
      'referral-bonus': {
        page: {
          title: 'Referral Bonus',
          breadcrumb: [
            {
              title: 'Admin Configurations',
              page: '/master-data',
            },
            {
              title: 'Control Panel',
              page: '/control-panel',
            },
            {
              title: 'Referral Bonus',
            }
          ],
        }
      },
      'wallet-configuration': {
        page: {
          title: 'Wallet Configuration',
          breadcrumb: [
            {
              title: 'Admin Configurations',
              page: '/master-data',
            },
            {
              title: 'Control Panel',
              page: '/control-panel',
            },
            {
              title: 'Wallet Configuration',
            }
          ],
        }
      },
      'surge-charge': {
        page: {
          title: 'Surge Charge',
          breadcrumb: [
            {
              title: 'Admin Configurations',
              page: '/master-data',
            },
            {
              title: 'Control Panel',
              page: '/control-panel',
            },
            {
              title: 'Surge Charge',
            }
          ],
        }
      },
      'vehicle-groups': {
        page: {
          title: 'Vehicle Groups',
          breadcrumb: [
            {
              title: 'Admin Configurations',
              page: '/master-data',
            },
            {
              title: 'Control Panel',
              page: '/control-panel',
            },
            {
              title: 'Vehicle Groups',
            }
          ],
        }
      },
      'general-settings': {
        page: {
          title: 'General Settings',
          breadcrumb: [
            {
              title: 'Admin Configurations',
              page: '/master-data',
            },
            {
              title: 'Control Panel',
              page: '/control-panel',
            },
            {
              title: 'General Settings',
            }
          ],
        }
      },
      'target-configuration': {
        page: {
          title: 'Target Configuration (KPI)',
          breadcrumb: [
            {
              title: 'Admin Configurations',
              page: '/master-data',
            },
            {
              title: 'Control Panel',
              page: '/control-panel',
            },
            {
              title: 'Target Configuration (KPI)',
            }
          ],
        }
      },
    },
    // Logs and reports
    'logs': {
      page: {
        title: 'Logs',
        breadcrumb: [
          {
            title: 'Logs',
            page: '/logs'
          },
          {
            title: 'Logs Management'
          }
        ],
      }
    },
    // Tools
    'tools': {
      'url-shortener': {
        page: {
          title: 'URL Shortener',
          breadcrumb: [
            {
              title: 'Tools',
              page: '/tools'
            },
            {
              title: 'URL Shortener'
            }
          ],
        }
      },
      'data-sync': {
        page: {
          title: 'Data Sync',
          breadcrumb: [
            {
              title: 'Tools',
              page: '/tools'
            },
            {
              title: 'Data Sync'
            }
          ],
        }
      },
    },
    // Other
    'profile': {
      page: {
        title: 'PROFILE',
        breadcrumb: [
          {
            title: 'Profile Management',
            page: '/dashboard',
          },
          {
            title: 'Profile',
          }
        ],
      }
    },
    'notifications': {
      page: {
        title: 'Notifications',
        breadcrumb: [
          {
            title: 'Dashboard',
            page: '/dashboard',
          },
          {
            title: 'Notifications',
          }
        ],
      }
    },
    error: {
      404: {
        page: {
          title: '404 Not Found',
          subheader: false
        }
      },
      403: {
        page: {
          title: '403 Access Forbidden',
          subheader: false
        }
      }
    }
  };

  public get configs(): any {
    return this.defaults;
  }
}
