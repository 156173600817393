
export class Country {
  _id: string;
  countryId: number;
  name: string;
  capital: string;
  currency: string;
  isActive: boolean;
  iso2: string;
  iso3: string;
  native: string;
  phone_code: string;
  region: string;
  subregion: string;
  timezones: any[];

  clear(): void {
    this._id = undefined;
    this.countryId = 101;
    this.name = 'India';
    this.capital = '';
    this.currency = '';
    this.isActive = true;
    this.iso2 = undefined;
    this.iso3 = undefined;
    this.native = '';
    this.phone_code = '';
    this.region = '';
    this.subregion = '';
    this.timezones = [];
  }
}

