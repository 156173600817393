export class FundAccount {
  _id: string;
  userId: string;
  contactId: string;
  type: string;
  accountNo: string;
  bankName: string;
  ifscCode: string;
  accountHolderName: string;
  fundAccountId: string;
  isSuspended: boolean;
  isDelete: boolean;
  isPrimary: boolean;
  __v: number;

  clear(): void {
    this._id = undefined;
    this.userId = undefined;
    this.contactId = '';
    this.type = '';
    this.accountNo = '';
    this.bankName = '';
    this.ifscCode = '';
    this.accountHolderName = '';
    this.fundAccountId = '';
    this.isSuspended = false;
    this.isDelete = false;
    this.isPrimary = false;
    this.__v = 0;
  }
}
