export class RideNotificationLog {
  _id: string;
  rideId: string;
  pD: NotificationLog[];
  pDFav: NotificationLog[];
  pDCug: NotificationLog[];
  others: NotificationLog[];
  notifiedTime: string
  __v: number;

  clear(): void {
    this._id = undefined;
    this.rideId = undefined;
    this.pD = [];
    this.pDFav = [];
    this.pDCug = [];
    this.others = [];
    this.notifiedTime = '';
    this.__v = 0;
  }
}

export class NotificationLog {
  lat: number;
  long: number;
  driverId: string; // db id
  name: string; // name
  mobileNumber: string; // mobile number
  driverID: string; // readable id
  vehicleType: string;
  seats: number;
  dist: {
    distance: string,
    distanceValue: number,
    duration: string,
    durationValue: number,
    pickUp: string, // Driver Location
    dropOff: string, // Rider Location
  }

  clear(): void {
    this.lat = undefined;
    this.long = undefined;
    this.driverId = undefined;
    this.name = undefined;
    this.mobileNumber = undefined;
    this.driverID = undefined;
    this.vehicleType = undefined;
    this.seats = 0;
    this.dist = {
      distance: '',
      distanceValue: 0,
      duration: '',
      durationValue: 0,
      pickUp: '', // Rider pickup Location
      dropOff: '', // Driver current Location
    }
  }
}
