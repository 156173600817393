// Angular
import { Component, OnDestroy, OnInit, ChangeDetectorRef, Input, Output, OnChanges, EventEmitter } from '@angular/core';
// RxJS
import { Subscription } from 'rxjs';
import { ExotelService } from '../../../../core/external-integrations';

@Component({
  selector: 'app-initiate-call',
  templateUrl: './initiate-call.component.html',
  styleUrls: ['./initiate-call.component.scss']
})
export class InitiateCallComponent implements OnInit, OnDestroy, OnChanges {

  @Input() id: string;
  @Input() userType: string;
  @Input() isExotelId: boolean;

  currentCall: any;

	// Private properties
	private subscriptions: Subscription[] = [];

  constructor(private cdr: ChangeDetectorRef,
              public exotelService: ExotelService) {

    this.exotelService.currentCall.subscribe(call => {
      this.currentCall = call;
      this.cdr.markForCheck();
    });
	}

	/**
	 * On init
	 */
	ngOnInit() {
    //console.log(this.mobile);
	}

  ngOnChanges(): void {
    //console.log(this.filterBy);
  }

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		this.subscriptions.forEach(sb => sb.unsubscribe());
	}

}
