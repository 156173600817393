import { Component, OnInit, ChangeDetectorRef, OnDestroy, Inject, ViewChild, ElementRef, NgZone } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS
import { Observable, of, Subscription, Subject } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
// Service
import { Remark, RemarkService } from "src/app/core/remark";
// Layout
import { LayoutUtilsService, MessageType, QueryParamsModel, UploadPopupService } from 'src/app/core/_base/crud';

import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { UploadService } from "src/app/core/upload/_services/upload.service";
import { UploadFile } from "src/app/core/upload/_models/uploadFile.model";

@Component({
  selector: 'app-remark-editor',
  templateUrl: './remark-editor.component.html',
  styleUrls: ['./remark-editor.component.scss']
})
export class RemarkEditorComponent implements OnInit, OnDestroy {

  remarkForm: FormGroup;
  loading: boolean = false;
  isUpdated: boolean = false;

  remark: Remark = new Remark();
  attachments: UploadFile[] = [];
  formData: FormData;
  uploadLoading: boolean = false;
  public Editor = ClassicEditor;
  public config: any = {
    collaboration: {
      channelId: 'foobar-baz'
    },
    toolbar: {
      items: [
        'exportPDF', 'exportWord', '|',
        'findAndReplace', 'selectAll', '|',
        'heading', '|',
        'bold', 'italic', 'strikethrough', 'underline', 'code', 'subscript', 'superscript', 'removeFormat', '|',
        'bulletedList', 'numberedList', 'todoList', '|',
        'outdent', 'indent', '|',
        'undo', 'redo', '|',
        'fontSize', 'fontFamily', 'fontColor', 'fontBackgroundColor', 'highlight', '|',
        'alignment', '|',
        'blockQuote', 'insertTable', 'codeBlock', 'htmlEmbed', '|',
        'specialCharacters', 'horizontalLine', 'pageBreak', '|',
        'textPartLanguage', '|',
        'sourceEditing'
      ],
      shouldNotGroupWhenFull: true
    },
  };

  private subscriptions: Subscription[] = [];

  constructor(public dialogRef: MatDialogRef<RemarkEditorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    private uploadservice: UploadService,
    private service: RemarkService,
    private layoutService: LayoutUtilsService) { }

  ngOnInit(): void {
    this.remark = JSON.parse(JSON.stringify(this.data.remark));
    this.initForm();
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.layoutService.showAlert();
    this.subscriptions.forEach(el => el.unsubscribe());
  }

  public onReady(editor) {
    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()
    );
  }

  initForm() {
    this.remarkForm = this.formBuilder.group({
      title: [this.remark.title, Validators.compose([
        Validators.required
      ])
      ],
      comments: [this.remark.comments, Validators.compose([
        Validators.required
      ])
      ],
    });
  }

  onSubmit() {
    const controls = this.remarkForm.controls;
    /** check form */
    if (this.remarkForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      return;
    }
    this.loading = true;
    this.isUpdated = false;
    this.cdr.detectChanges();

    const data = {
      _id: this.remark._id,
      rideId: this.remark.rideId ?? undefined,
      riderId: this.remark.riderId ?? undefined,
      driverId: this.remark.driverId ?? undefined,
      userId: this.remark.userId ?? undefined,
      title: controls.title.value,
      attachments: this.getIdArray(this.attachments),
      comments: controls.comments.value,
    }
    console.log(data);
    const createSubscriptions = this.service.createRemark(data).subscribe(
      (res: any) => {
        console.log(res);
        if (res.response.code === 201) {
          this.dialogRef.close(true);
          this.layoutService.showActionNotification(MessageType.success, 'Remark Created!', 'Remark has been created successfully.');
        } else {
          this.layoutService.showAlert(MessageType.danger, 'Something went wrong, please try again later.');
        }
        this.loading = false;
      },
      error => {
        this.loading = false;
        if (error.status === 409) {
          this.layoutService.showAlert(MessageType.danger, 'Duplicate Remark Found! Similar entry already exist in server. Please update and try again.');
        } else {
          this.layoutService.showAlert(MessageType.danger, 'Something went wrong, please try again later.');
        }
      }
    );
    this.subscriptions.push(createSubscriptions);
  }

  closeDialog() {
    this.dialogRef.close();
  }

  isControlHasError(controlName: string, validationType: string = undefined): boolean {
    const control = this.remarkForm.controls[controlName];
    if (!control) {
      return false;
    }
    if (validationType) {
      const result = control.hasError(validationType) && (control.dirty || control.touched);
      return result;
    }
    if (control.errors && (control.dirty || control.touched)) {
      return true;
    }
    return false;
  }

  handleUploadEventDoc(event, doc: UploadFile = new UploadFile()) {
    console.log('calling deoc upload', doc);
    this.layoutService.showAlert();
    this.formData = new FormData();
    if (event.target.files && event.target.files[0]) {
      //if (event.target.files[0].size > 20971520) {
      if (event.target.files[0].size > 10485760) {
        this.layoutService.showActionNotification(MessageType.warning, "File size too large!", "Maximum uploadable file size is 10MB. Please upload a smaller image and try again.");
        return;
      }
      if (event.target.files[0].type === 'image/jpeg' || event.target.files[0].type === 'image/jpg' || event.target.files[0].type === 'image/png') {
        this.formData.append('file', event.target.files[0]);
        if (doc.fileUploadId) {
          doc.isUploading = true;
        } else {
          this.uploadLoading = true;
        }
        this.cdr.detectChanges();
        this.subscriptions.push(this.uploadservice.uploadFile(this.formData).subscribe(
          (res) => {
            if (res.response.code === 201) {
              var _data = res.data[0];
              if (doc.fileUploadId) {
                doc.isUploading = false;
                this.replaceDocument(doc, _data);
              } else {
                this.uploadLoading = false;
                this.attachments.push(_data);
              }
              this.cdr.detectChanges();
            }
          },
          error => {
            this.layoutService.showActionNotification(MessageType.danger, "Server Error!", "Something went wrong, please try again later.");
          }));
      } else {
        this.layoutService.showActionNotification(MessageType.warning, "Unsupported File!", "Selected file format not supported. Please upload a jpg or png image.");
      }
    }
  }

  removeSelected(event) {
    event.target.value = '';
    this.cdr.detectChanges();
  }

  replaceDocument(oldDoc: UploadFile, newDoc: UploadFile) {
    let newDocArray = [];
    this.attachments.forEach(doc => {
      if (oldDoc.fileUploadId === doc.fileUploadId) {
        newDocArray.push(newDoc);
      } else {
        newDocArray.push(doc);
      }
    });
    this.attachments = newDocArray;
    this.cdr.detectChanges();
  }

  removeDocument(doc: UploadFile) {
    let newDocArray = [];
    this.attachments.forEach(item => {
      if (doc.fileUploadId !== item.fileUploadId) {
        newDocArray.push(item);
      }
    });
    this.attachments = newDocArray;
    this.cdr.detectChanges();
  }

  getIdArray(array) {
    let newArray = [];
    array.forEach(item => {
      newArray.push(item.fileUploadId);
    });
    return newArray;
  }
}

