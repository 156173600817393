
import { User, Role} from '../../auth';

export class Log {
    _id: string;
    employeeId: string;
    employee: User;
    typeOfEvent: string;
    eventDescription: string;
    ipAddress: string;
    macAddress: string;
    userAgent: string;
    time: string;

    clear(): void {
      this._id = undefined;
      this.employeeId = undefined;
      this.employee = new User();
      this.typeOfEvent = 'created';
      this.eventDescription = '';
      this.ipAddress = '';
      this.macAddress = '';
      this.userAgent = '';
      this.time = '';
    }
}
