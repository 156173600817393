import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Message } from '../_models/message.model';
import { QueryParamsModel } from '../../_base/crud';

export enum MessagingActionTypes {
    AllMessagingsRequested = '[Messagings Module] All Messagings Requested',
    AllMessagingsLoaded = '[Messagings API] All Messagings Loaded',
    MessagingsPageRequested = '[Messagings List Page] Messagings Page Requested',
    MessagingsPageLoaded = '[Messagings API] Messagings Page Loaded',
    MessagingsPageCancelled = '[Messagings API] Messagings Page Cancelled',
    MessagingsPageToggleLoading = '[Messagings] Messagings Page Toggle Loading',
    MessagingsActionToggleLoading = '[Messagings] Messagings Action Toggle Loading'
}

export class MessagingsPageRequested implements Action {
    readonly type = MessagingActionTypes.MessagingsPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class MessagingsPageLoaded implements Action {
    readonly type = MessagingActionTypes.MessagingsPageLoaded;
    constructor(public payload: { messagings: Message[], totalCount: number, page: QueryParamsModel  }) { }
}

export class MessagingsPageCancelled implements Action {
    readonly type = MessagingActionTypes.MessagingsPageCancelled;
}
export class MessagingsPageToggleLoading implements Action {
    readonly type = MessagingActionTypes.MessagingsPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class MessagingsActionToggleLoading implements Action {
    readonly type = MessagingActionTypes.MessagingsActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}


export type MessagingActions = MessagingsPageLoaded
| MessagingsPageCancelled
| MessagingsPageRequested
| MessagingsPageToggleLoading
| MessagingsActionToggleLoading;

