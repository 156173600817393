import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
// import { ClickOutsideModule } from 'ng-click-outside';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgSelectModule } from '@ng-select/ng-select';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { ImageCropperModule } from 'ngx-image-cropper';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgxPermissionsModule } from 'ngx-permissions';

import {
  NgbDropdownModule,
  NgbTooltipModule,
  NgbNavModule,
  NgbPaginationModule,
  NgbCarouselModule,
  NgbProgressbarModule,
  NgbDatepickerModule,
  NgbDateParserFormatter
} from "@ng-bootstrap/ng-bootstrap";

// google maps
import { AgmCoreModule } from "@agm/core";

// Material
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { ErrorPageComponent } from './general/error-page/error-page.component';
import { SplashScreenComponent } from './general/splash-screen/splash-screen.component';
import { NoInternetComponent } from './general/no-internet/no-internet.component';
import { ActionNotificationComponent } from './crud/action-notification/action-notification.component';
import { AlertComponent } from './crud/alert/alert.component';
import { ConfirmationEntityComponent } from './crud/confirmation-entity/confirmation-entity.component';
import { SubheaderComponent } from './layout/subheader/subheader.component';
import { ProfileComponent } from './layout/profile/profile.component';
import { NotificationsComponent } from './layout/notifications/notifications.component';
import { DropzoneUploadComponent } from './crud/dropzone-upload/dropzone-upload.component';
import { UpdateStatusEntityComponent } from './crud/update-status-entity/update-status-entity.component';
import { LocationPickerComponent } from './crud/location-picker/location-picker.component';
import { CardLoadingComponent } from './general/card-loading/card-loading.component';
import { PhotoEditorComponent } from './crud/photo-editor/photo-editor.component';
// Table components
import { PageCounterComponent } from './layout/table-components/page-counter/page-counter.component';
import { FilterByPreviewComponent } from './layout/table-components/filterby-preview/filterby-preview.component';
import { FilterBySelectorComponent } from './layout/table-components/filterby-selector/filterby-selector.component';
import { PageSizeSelectorComponent } from './layout/table-components/pagesize-selector/pagesize-selector.component';
import { PaginatorComponent } from './layout/table-components/paginator/paginator.component';
import { SearchPanelComponent } from './layout/table-components/search-panel/search-panel.component';
import { FareWarningComponent } from './general/fare-warning/fare-warning.component';

import { InitiateCallComponent } from './layout/initiate-call/initiate-call.component';



// const config: DropzoneConfigInterface = {
//   // Change this to your upload POST address:
//   url: "http://13.127.255.159:6000/fileUpload",
//   maxFilesize: 1,
//   // acceptedFiles: 'image/*',
//   createImageThumbnails: true
// };

// Environment Variables
import { environment } from '../../../environments/environment';
import { RemarkEditorComponent } from './crud/remark-editor/remark-editor.component';
import { DriverDetailsSidebarComponent } from './layout/driver-details-sidebar/driver-details-sidebar.component';
import { BulkUploadComponent } from './crud/bulk-upload/bulk-upload.component';
import { GlobalSearchComponent } from './layout/global-search/global-search.component';
import { DriverStatusVerifyComponent } from './crud/driver-status-verify/driver-status-verify.component';


@NgModule({
  declarations: [
  	ErrorPageComponent,
  	SplashScreenComponent,
  	ActionNotificationComponent,
  	AlertComponent,
  	ConfirmationEntityComponent,
  	SubheaderComponent,
  	ProfileComponent,
  	NotificationsComponent,
  	DropzoneUploadComponent,
  	UpdateStatusEntityComponent,
  	LocationPickerComponent,
    NoInternetComponent,
    CardLoadingComponent,
    PhotoEditorComponent,
    // Table components
    PageCounterComponent,
    FilterByPreviewComponent,
    FilterBySelectorComponent,
    PageSizeSelectorComponent,
    PaginatorComponent,
    SearchPanelComponent,
    FareWarningComponent,
    RemarkEditorComponent,
    InitiateCallComponent,
    DriverDetailsSidebarComponent,
    BulkUploadComponent,
    GlobalSearchComponent,
    DriverStatusVerifyComponent,
  ],
  imports: [
    CommonModule,
    PerfectScrollbarModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgbPaginationModule,
    // ClickOutsideModule,
    RouterModule,
    MatDialogModule,
    NgxDropzoneModule,
    MatTooltipModule,
    MatProgressBarModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    Ng2SearchPipeModule,
    ImageCropperModule,
    CKEditorModule,
    NgxPermissionsModule.forChild(),
    AgmCoreModule.forRoot({
      apiKey: environment.googleApiKey,
      libraries: ['geometry', 'drawing'],
      language: 'en',
    }),
  ],
  providers: [
    // {
    //   provide: DROPZONE_CONFIG,
    //   useValue: config,
    // }
  ],
  exports: [
    SplashScreenComponent,
    NoInternetComponent,
    AlertComponent,
    SubheaderComponent,
    ProfileComponent,
    NotificationsComponent,
    ConfirmationEntityComponent,
    DropzoneUploadComponent,
    BulkUploadComponent,
    UpdateStatusEntityComponent,
    Ng2SearchPipeModule,
    CardLoadingComponent,
    PhotoEditorComponent,
    // Table components
    PageCounterComponent,
    FilterByPreviewComponent,
    FilterBySelectorComponent,
    PageSizeSelectorComponent,
    PaginatorComponent,
    SearchPanelComponent,
    FareWarningComponent,
    RemarkEditorComponent,
    InitiateCallComponent,
    DriverDetailsSidebarComponent,
    GlobalSearchComponent,
  ],
  entryComponents: [
    ConfirmationEntityComponent,
    DropzoneUploadComponent,
    BulkUploadComponent,
    UpdateStatusEntityComponent,
    LocationPickerComponent,
    PhotoEditorComponent,
    RemarkEditorComponent
  ],
})
export class PartialsModule {}
