import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef, OnDestroy, Input, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router, NavigationCancel, NavigationEnd, NavigationStart, RouteConfigLoadEnd, RouteConfigLoadStart } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { fromEvent, Subscription, Observable } from 'rxjs';
// Material
import { MatDialog } from '@angular/material/dialog';
// rxjs
import { distinctUntilChanged, skip, debounceTime, tap } from 'rxjs/operators';
import { AppState } from '../../../../core/reducers';

// Services
import { LayoutUtilsService, MessageType, QueryParamsModel } from '../../../../core/_base/crud/';

import { Driver, DriversService } from "../../../../core/driver";
import { Rider, RiderService } from "../../../../core/rider";
import { RideReport, RideReportService } from "../../../../core/ride-report";
import { Document, KycService } from "../../../../core/kyc";

//Moment
import * as moment from 'moment';

declare var $: any;

@Component({
  selector: 'app-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss']
})
export class GlobalSearchComponent implements OnInit, OnDestroy {

  searchText: string = '';
  searchInAll: boolean = false;
  searchInitiated: boolean = false;

  @ViewChild("globalSearchInput") globalSearchInput: ElementRef;

  queryParms: QueryParamsModel = new QueryParamsModel();

  expandedSearch: any[] = [
    {
      key: 'document',
      title: 'KYC Document List',
      label: 'KYC Documents',
      value: true,
      loading: false,
      items: [],
      totalCount: 0,
      pageNumber: 1,
      pageSize: 5,
    },
    {
      key: 'driver',
      title: 'Drivers List',
      label: 'Drivers',
      value: false,
      loading: false,
      items: [],
      totalCount: 0,
      pageNumber: 1,
      pageSize: 5,
    },
    {
      key: 'rider',
      title: 'Riders List',
      label: 'Riders',
      value: false,
      loading: false,
      items: [],
      totalCount: 0,
      pageNumber: 1,
      pageSize: 5,
    },
    {
      key: 'ride',
      title: 'Rides List',
      label: 'Rides',
      value: false,
      loading: false,
      items: [],
      totalCount: 0,
      pageNumber: 1,
      pageSize: 5,
    },
  ];

  // Subscriptions
  private subscriptions: Subscription[] = [];

  constructor(private router: Router,
    private cdr: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private store: Store<AppState>,
    private driverService: DriversService,
    private riderService: RiderService,
    private rideService: RideReportService,
    private kycService: KycService,
    private layoutService: LayoutUtilsService) { }

  ngOnInit(): void {
    console.log("initializing")
  }

  ngOnDestroy() {
    this.hide();
    this.subscriptions.forEach(el => el.unsubscribe());
  }

  checkDataAvailable() {
    let noData = true;
    this.expandedSearch.forEach(el => {
      if (el.totalCount > 0) {
        noData = false;
      }
    });
    return noData;
  }

  checkGlobalLoading() {
    let loading = false;
    this.expandedSearch.forEach(el => {
      if (el.loading) {
        loading = true;
      }
    });
    return loading;
  }

  checkSelected(key) {
    let selected = this.expandedSearch.filter(el => (el.key === key && el.value));
    if (selected.length > 0) {
      return true;
    }
    return false;
  }

  toggleAll(value) {
    console.log(value)
    this.searchInAll = value;
    this.expandedSearch.forEach(el => {
      el.value = value;
    });
    this.cdr.detectChanges();
  }

  search(key: string = null) {
    console.log("searching...", this.searchText)
    if (!(this.searchText && this.searchText.length > 3)) {
      this.layoutService.showAlert(MessageType.warning, "Seach text should contain atleast 4 characters!");
      return;
    }
    this.searchInitiated = true;
    this.queryParms = new QueryParamsModel(
      '',
      'desc',
      '_id',
      1,
      5,
      this.searchText
    );
    this.expandedSearch.forEach(el => {
      if ((key && key == el.key) || !key) {
        this.queryParms.pageNumber = el.pageNumber;
        this.queryParms.pageSize = el.pageSize;
        if (el.value) {
          switch (el.key) {
            case 'driver':
              this.searchDriver(this.queryParms, el);
              break;
            case 'rider':
              this.searchRider(this.queryParms, el);
              break;
            case 'ride':
              this.searchRide(this.queryParms, el);
              break;
            case 'document':
              this.searchDocument(this.queryParms, el);
              break;
            default:
              break;
          }
        } else {
          el.items = [];
          el.totalCount = 0;
          el.pageNumber = 1;
          el.pageSize = 5;
        }
      }

    })
  }

  searchDriver(query, el) {
    el.loading = true;
    this.cdr.detectChanges();
    this.subscriptions.push(this.driverService.getAllDriver(query).subscribe(
      (res: any) => {
        console.log(res);
        el.items = res.items;
        el.totalCount = res.totalCount;
        this.cdr.markForCheck();
        el.loading = false;
        this.cdr.detectChanges();
      },
      error => {
        el.loading = false;
        this.cdr.detectChanges();
      }
    ));
  }

  searchRider(query, el) {
    el.loading = true;
    this.cdr.detectChanges();
    this.subscriptions.push(this.riderService.getAllRiders(query).subscribe(
      (res: any) => {
        console.log(res);
        el.items = res.items;
        el.totalCount = res.totalCount;
        this.cdr.markForCheck();
        el.loading = false;
        this.cdr.detectChanges();
      },
      error => {
        el.loading = false;
        this.cdr.detectChanges();
      }
    ));
  }

  searchRide(query, el) {
    el.loading = true;
    this.cdr.detectChanges();
    this.subscriptions.push(this.rideService.getAllRideReports(query).subscribe(
      (res: any) => {
        console.log(res);
        el.items = res.items;
        el.totalCount = res.totalCount;
        this.cdr.markForCheck();
        el.loading = false;
        this.cdr.detectChanges();
      },
      error => {
        el.loading = false;
        this.cdr.detectChanges();
      }
    ));
  }

  searchDocument(query, el) {
    el.loading = true;
    this.cdr.detectChanges();
    this.subscriptions.push(this.kycService.searchKycDosument(query).subscribe(
      (res: any) => {
        console.log(res);
        el.items = res.items;
        el.totalCount = res.totalCount;
        this.cdr.markForCheck();
        el.loading = false;
        this.cdr.detectChanges();
      },
      error => {
        el.loading = false;
        this.cdr.detectChanges();
      }
    ));
  }

  public hide() {
    document.body.classList.remove('global-search-bar-enabled');
  }

  getPrimaryNumber(numbers) {
    let selected = numbers.filter(item => item.primary === true);
    if (selected.length > 0) {
      return this.formatMobileNumber(selected[0]);
    }
    return '-';
  }

  formatMobileNumber(mobile: any) {
    if (!mobile) {
      return '-';
    }
    if (mobile.countryCode && mobile.number) {
      return '+' + mobile.countryCode + ' ' + mobile.number;
    } else if (mobile.countryCode && mobile.contact) {
      return '+' + mobile.countryCode + ' ' + mobile.contact;
    } else {
      return '-';
    }
  }

  formatTime(time, noTime: boolean = false): string {
    if (!time) {
      return '-'
    }
    if (noTime) {
      return moment(time).format('DD MMM YYYY');
    }
    return moment(time).format('DD MMM YYYY, hh:mm A');
  }

  getStatusLabel(rideDetails: RideReport) {
    if (rideDetails.rideStatus === 'cancelled') {
      if (rideDetails.rideStartTime != null) {
        return 'On Ride Cancelled'
      } else if (rideDetails?.driverCancel?.isCancel) {
        return 'Driver Cancelled';
      } else if (rideDetails?.riderCancel?.isCancel) {
        return 'Rider Cancelled';
      } else if (rideDetails?.adminCancel?.isCancel) {
        return 'Admin Cancelled';
      } else {
        return 'Cancelled';
      }
    } else if (rideDetails.rideStatus === 'scheduleCancel') {
      return 'Schedule Cancelled';
    } else {
      return rideDetails.rideStatus;
    }
  }

  getVerificationStatus(rideDetails) {
    if (rideDetails.isGenuine === true) {
      return 'Genuine'
    } else if ((rideDetails.isGenuine === false) && (rideDetails.verifiedTime)) {
      return 'Fake';
    } else {
      return 'Not Verified';
    }
  }

  viewKycDetails(_id) {
    this.router.navigate(['kyc-management/details/view', _id]);
  }
}
