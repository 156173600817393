// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS
import { Subject, Subscription } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
// Store
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
// Layout
import { LayoutUtilsService, MessageType } from '../../../../core/_base/crud/';
// Auth
import { AuthService, Login, UserLoaded } from '../../../../core/auth';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html'
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
	// Public params
	forgotForm: FormGroup;
	loading = false;

  private subscriptions: Subscription[] = [];

	constructor(
		private router: Router,
		private auth: AuthService,
		private store: Store<AppState>,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private layoutService: LayoutUtilsService
	) {}

	ngOnInit(): void {
		this.layoutService.showAlert(MessageType.info, "Enter your Email and instructions will be sent to you!");
		this.initLoginForm();
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		this.layoutService.showAlert();
		this.loading = false;
    this.subscriptions.forEach(el => el.unsubscribe());
	}

	initLoginForm() {
		this.forgotForm = this.fb.group({
			email: ['', Validators.compose([
				Validators.required,
				Validators.pattern("[a-zA-Z0-9.-]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}")
			])
			]
		});
	}

	onSubmit() {
		const controls = this.forgotForm.controls;
		/** check form */
		if (this.forgotForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}
		this.loading = true;

		const authData = {
			email: controls.email.value,
		};
    this.subscriptions.push(this.auth.forgotPassword(authData).subscribe(
      res =>{
        this.loading = false;
        this.cdr.detectChanges();
        console.log(res);
        if (res.response.code === 201) {
          this.initLoginForm();
          this.layoutService.showAlert(MessageType.success, "Reset password link has been sent your email. Redirecting in 3 sec.");
          setTimeout(() => {
            this.router.navigateByUrl('/auth/login');
          }, 3000);
        }
      },
      error =>{
        this.loading = false;
        this.cdr.detectChanges();
        console.log(error);
        this.loading = false;
        if (error.status === 404) {
          this.layoutService.showAlert(MessageType.danger, "User does not exist");
        } else if (error.status === 403) {
          this.layoutService.showAlert(MessageType.warning, "Oops! Your account has been suspended. Contact admin to reactivate account.");
        } else {
          this.layoutService.showAlert(MessageType.danger, "Something went wrong, Please try again later.");
        }
      }
    ));
	}

	isControlHasError(controlName: string, validationType: string = undefined): boolean {
		const control = this.forgotForm.controls[controlName];
		if (!control) {
			return false;
		}
		if (validationType) {
			const result = control.hasError(validationType) && (control.dirty || control.touched);
			return result;
		}
		if (control.errors && (control.dirty || control.touched)) {
			return true;
		}
		return false;
	}
}
