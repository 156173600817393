// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { KycState } from '../_reducers/kyc.reducers';
import { each } from 'lodash-es';
import { Kyc } from '../_models/kyc.model';


export const selectKycState = createFeatureSelector<KycState>('kyc');

export const selectKycById = (_id: string) => createSelector(
    selectKycState,
    kycState => kycState.entities[_id]
);

export const selectKycPageLoading = createSelector(
    selectKycState,
    kycState => {
        return kycState.listLoading;
    }
);

export const selectKycActionLoading = createSelector(
    selectKycState,
    kycState => kycState.actionsloading
);

export const selectLastCreatedKycId = createSelector(
    selectKycState,
    kycState => kycState.lastCreatedKycId
);

export const selectKycPageLastQuery = createSelector(
    selectKycState,
    kycState => kycState.lastQuery
);

export const selectKycInStore = createSelector(
    selectKycState,
    kycState => {
        const items: Kyc[] = [];
        each(kycState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: Kyc[] = httpExtension.sortArray(items, kycState.lastQuery.sortField, kycState.lastQuery.sortOrder);
        return new QueryResultsModel(result, kycState.totalCount, '');

    }
);

export const selectKycShowInitWaitingMessage = createSelector(
    selectKycState,
    kycState => kycState.showInitWaitingMessage
);

export const selectHasKycInStore = createSelector(
    selectKycState,
    queryResult => {
        if (!queryResult.totalCount) {
            return false;
        }

        return true;
    }
);
