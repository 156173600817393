// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
// Actions
import { TripFareActions, TripFareActionTypes } from '../_actions/tripFare.actions';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { TripFare } from '../_models/tripFare.model';
// RxJS
import { Observable, of } from 'rxjs';

// tslint:disable-next-line:no-empty-interface
export interface TripFareState extends EntityState<TripFare> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedTripFareId: string;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<TripFare> = createEntityAdapter({
  selectId: (tripFare: TripFare) => tripFare._id,
});

export const initialTripFareState: TripFareState = adapter.getInitialState({
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastQuery:  new QueryParamsModel(),
    lastCreatedTripFareId: undefined,
    showInitWaitingMessage: true
});

export function tripFareReducer(state = initialTripFareState, action: TripFareActions): TripFareState {
    switch  (action.type) {
        case TripFareActionTypes.TripFarePageToggleLoading: return {
            ...state, listLoading: action.payload.isLoading, lastCreatedTripFareId: undefined
        };
        case TripFareActionTypes.TripFareActionToggleLoading: return {
            ...state, actionsloading: action.payload.isLoading
        };
        case TripFareActionTypes.TripFarePageCancelled: return {
            ...state, listLoading: false, lastQuery: new QueryParamsModel()
        };
        case TripFareActionTypes.TripFarePageLoaded: {
            console.log(action.payload.tripFare);
            return adapter.setAll(action.payload.tripFare, {
                ...initialTripFareState,
                totalCount: action.payload.totalCount,
                lastQuery: action.payload.page,
                listLoading: false,
                showInitWaitingMessage: false
            });
        }
        default: return state;
    }
}

export const getDriverState = createFeatureSelector<TripFareState>('tripFare');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
