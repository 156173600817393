
export class State {
    _id: string;
    countryId: number;
    country_code: string;
    name: string;
    stateId: number;
    state_code: string;

    clear(): void {
        this._id = undefined;
        this.countryId = undefined;
        this.country_code = '';
        this.name = '';
        this.stateId = undefined;
        this.state_code = '';
    }
}

