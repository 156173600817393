<div class="modal-header border-bottom-0 mb-0 pb-0">
  <button id="closeDialogButton" type="button" class="close" aria-hidden="true" (click)="onCancel()">×</button>
</div>
<div class="modal-body p-4 pt-0 text-center">
  <div class="icon-box-c icon-{{status}}">
    <i class="fas {{getIcon(status)}}"></i>
  </div>
  <h5 class="modal-title font-size-16 mt-2 mb-1 text-dark text-center">Confirm Driver</h5>
  <p class="font-size-4 text-dark text-center">Are you sure to assign this driver to the ride.</p>
  <div class="row">
    <div class="col-12">
      <div class="alert alert-{{status}} text-left" role="alert">
        <div class="table-responsive">
          <table class="table table-centered">
            <thead>
              <tr>
                <th>Driver Details</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Driver Status</td>
                <td>{{ data.driver?.status?.isActive | uppercase}}</td>
              </tr>
              <tr>
                <td>KYC Status</td>
                <td>{{ data.driver?.kycStatus }}</td>
              </tr>
              <tr>
                <td>Subscription Status</td>
                <td> {{ data.driver?.subscription?.status ? 'Active' : (data.driver?.subscription?.totalExpiryDate ? 'Expired' : 'Inactive') }} </td>
              </tr>
              <!-- <tr>
                <td>RFR Status</td>
                <td>RFR Ready</td>
              </tr> -->
              <tr>
                <td>Vehicle Type Match</td>
                <td>{{ (data.driver?.vehicleType == data.rideDetails?.vehicleType ||
                data.rideDetails?.vehicleGroupDetail?.vehicleTypes.includes(data.driver?.vehicleType)) ? 'Maching' : 'Not Matching' }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="col-md-12 text-center">
        <button id="cancelButton" type="button" class="btn btn-secondary w-sm" (click)="onCancel()">
          {{data.cancelButton}}
        </button>
        <button id="confirmButton" *ngIf="data.confirmButton" type="button" class="btn btn-primary w-sm ml-3"
          (click)="onConfirm()" [disabled]="viewLoading">
          {{data.confirmButton}}
          <i *ngIf="viewLoading" class="bx bx-loader bx-spin"></i>
        </button>
      </div>
    </div>
  </div>
</div>
