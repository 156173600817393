<div class="row" *ngIf="filterBy">
  <div class="col-lg-12 filter-collection-container">
    <ng-container *ngFor="let collection of filterBy">
      <span class="filter-collection" *ngIf="getActive(collection)">
        <span class="title">{{collection.title}}:</span>
        <ng-container *ngFor="let item of collection.value">
          <span class="filter-item" *ngIf="item.isChecked">
            <p>{{item.label}}</p>
            <i id="{{item.value}}CloseButton" class="fas fa-times" (click)="clearFilter(item)"></i>
          </span>
        </ng-container>
      </span>
    </ng-container>
  </div>
</div>
