export class WalletConfiguration {
  _id: string;
  cashType: string; // "viaCoin", "viaCash"
  customer: string; //"rider", "driver"
  transactionHead: string[]; // "referalReward", "discountAdjustment", "withdrawal", "refund", "cashback", "couponRedeem", "rideAdjustment", "subscriptionAdjustment", "loyaltyReward", "earlyAdoptorReward"
  valuation: number;
  maxUpto: number;
  usageLimit: number;
  perDayWithdrawalLimit: number;
  isPercentage: boolean;
  isSuspended: boolean;
  isDelete: boolean;
  modifiedById: string;
  createdById: string;
  zoneId: string;
  cityId: string;
  __v: number;

  clear(): void {
    this._id = undefined;
    this.cashType = undefined; // "viaCoin", "viaCash"
    this.customer = undefined; //"rider", "driver"
    this.transactionHead = []; // "referalReward", "discountAdjustment", "withdrawal", "refund", "cashback", "couponRedeem", "rideAdjustment", "subscriptionAdjustment", "loyaltyReward", "earlyAdoptorReward"
    this.valuation = 1;
    this.maxUpto = 0;
    this.usageLimit = 0;
    this.perDayWithdrawalLimit = 0;
    this.isPercentage = true;
    this.isSuspended = false;
    this.isDelete = false;
    this.modifiedById = '';
    this.createdById = '';
    this.zoneId = undefined;
    this.cityId = undefined;
    this.__v = 0;
  }
}
