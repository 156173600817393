// Angular
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
// Material
import { MatDialog } from '@angular/material/dialog';
// Toast
import { ToastrService } from 'ngx-toastr';
// Partials for CRUD
import {
	ConfirmationEntityComponent,
	UpdateStatusEntityComponent
} from '../../../../views/partials/crud/';
import { RideReport } from 'src/app/core/ride-report';
import { Driver } from 'src/app/core/driver/_models/driver.model';
import { DriverStatusVerifyComponent } from 'src/app/views/partials/crud/driver-status-verify/driver-status-verify.component';

export enum MessageType {
	success,
	danger,
	info,
	warning
}

@Injectable({
	providedIn: 'root'
})
export class LayoutUtilsService {

	onAlertChanged$: BehaviorSubject<any>;

	constructor(private dialog: MatDialog,
				private toastr: ToastrService) {
		this.onAlertChanged$ = new BehaviorSubject(null);
	}

	// Show alert box
	showAlert(
		_type: MessageType = MessageType.danger,
		_message: string = '',
		_duration: number = 5000,
		_showCloseButton: boolean = false
	) {
		const notice = {
			type: _type,
			message: _message,
			duration: _duration,
			showCloseButton: _showCloseButton,
		};
		this.onAlertChanged$.next(notice);
	}

	// Show notification box
	showActionNotification(
		_type: MessageType,
		_title: string,
		_message: string,
		_duration: number = 3000
	) {
		switch (_type) {
			case 0:
				this.toastr.success(_message, _title, {
					timeOut: _duration,
				});
				break;
			case 1:
				this.toastr.error(_message, _title, {
					timeOut: _duration,
				});
				break;
			case 2:
				this.toastr.info(_message, _title, {
					timeOut: _duration,
				});
				break;
			case 3:
				this.toastr.warning(_message, _title, {
					timeOut: _duration,
				});
				break;
			default:
				this.toastr.success(_message, _title, {
					timeOut: _duration,
				});
				break;
		}
	}

  confirmationPopup(type: string = 'info', title: string = '', description: string = '', confirmButton: string = 'Confirm', cancelButton: string = 'Cancel') {
    console.log(description);
		return this.dialog.open(ConfirmationEntityComponent, {
      data: { type, title, description, confirmButton, cancelButton },
			width: '440px'
		});
	}

  updateStatusForEntities(type: 'suspend' | 'reinstate' | 'delete' | 'vehicleType' | 'kycReject' | 'kycValid' | 'disable' | 'settlementStatus' = 'suspend', reason: string = undefined, title: string = '', description: string = '', confirmButton: string = 'Confirm', extra: any = undefined) {
		return this.dialog.open(UpdateStatusEntityComponent, {
			data: { type, reason, title, description, confirmButton, extra },
			width: '480px'
		});
	}

  driverStatusVerifyPopup(driver: Driver, rideDetails: RideReport, confirmButton: string = 'Confirm', cancelButton: string = 'Cancel') {
    return this.dialog.open(DriverStatusVerifyComponent, {
      data: { driver, rideDetails, confirmButton, cancelButton },
      width: '550px'
    });
  }
}
