export class DriverFare {
  _id: string;
  zoneId: string;
  cityId: string;
  vehicleType: string;
  cugLabel: string;
  rideNow: FareDetails;
  halfDay: FareDetails;
  fullDay: FareDetailsExtended;
  outStationOneWay: FareDetailsExtended;
  outStationRound: FareDetailsExtended;
  airport: FareDetails;
  freeWaitingTime: {
    fixedTime: number
  };
  waitingCharge: {
    fixedPrice: number
  };
  cancellationCharge: {
    fixedPrice: number
  };
  createdById: string;
  isDelete: boolean;

  clear(): void {
    this._id = undefined;
    this.zoneId = '';
    this.cityId = '';
    this.vehicleType = '';
    this.cugLabel = '';
    this.rideNow = new FareDetails();
    this.halfDay = new FareDetails();
    this.fullDay = new FareDetailsExtended();
    this.outStationOneWay = new FareDetailsExtended();
    this.outStationRound = new FareDetailsExtended();
    this.airport = new FareDetails();
    this.rideNow.clear()
    this.halfDay.clear()
    this.fullDay.clear()
    this.outStationOneWay.clear()
    this.outStationRound.clear()
    this.airport.clear()
    this.freeWaitingTime = {
      fixedTime: 0
    };
    this.waitingCharge = {
      fixedPrice: 0
    };
    this.cancellationCharge = {
      fixedPrice: 0
    };
    this.createdById = '';
    this.isDelete = false;
  }
}

export class FareDetails {
  isSurgeApplicable: boolean;
  isExceptionApplicable: boolean;
  baseFare: any[];
  extraDistancePrice: {
    fixedPrice: number
  };
  extraTimePrice: {
    fixedPrice: number
  };

  clear(): void {
    this.isSurgeApplicable = true;
    this.isExceptionApplicable = true;
    this.baseFare = [
      {
        fixedRange: 0,
        fixedPrice: 0,
        fixedTime: 0
      }
    ];
    this.extraDistancePrice = {
      fixedPrice: 0
    };
    this.extraTimePrice = {
      fixedPrice: 0
    };
  }
}

export class FareDetailsExtended {
  isSurgeApplicable: boolean;
  isExceptionApplicable: boolean;
  baseFare: any[];
  extraDistancePrice: {
    fixedPrice: number
  };
  extraTimePrice: {
    fixedPrice: number
  };
  driverBatta: {
    fixedPrice: number
  };
  permitCharge: {
    fixedPrice: number
  };

  clear(): void {
    this.isSurgeApplicable = true;
    this.isExceptionApplicable = true;
    this.baseFare = [
      {
        fixedRange: 0,
        fixedPrice: 0,
        fixedTime: 0
      }
    ];
    this.extraDistancePrice = {
      fixedPrice: 0
    };
    this.extraTimePrice = {
      fixedPrice: 0
    };
    this.driverBatta = {
      fixedPrice: 0
    };
    this.permitCharge = {
      fixedPrice: 0
    };
  }
}

