import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { RideReport } from '../_models/rideReport.model';
import { QueryParamsModel } from '../../_base/crud';

export enum PendingRideActionTypes {
    AllPendingRidesRequested = '[PendingRides Module] All PendingRides Requested',
    AllPendingRidesLoaded = '[PendingRides API] All PendingRides Loaded',
    PendingRidesPageRequested = '[PendingRides List Page] PendingRides Page Requested',
    PendingRidesPageLoaded = '[PendingRides API] PendingRides Page Loaded',
    PendingRidesPageCancelled = '[PendingRides API] PendingRides Page Cancelled',
    PendingRidesPageToggleLoading = '[PendingRides] PendingRides Page Toggle Loading',
    PendingRidesActionToggleLoading = '[PendingRides] PendingRides Action Toggle Loading'
}

export class PendingRidesPageRequested implements Action {
    readonly type = PendingRideActionTypes.PendingRidesPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class PendingRidesPageLoaded implements Action {
    readonly type = PendingRideActionTypes.PendingRidesPageLoaded;
    constructor(public payload: { pendingRides: RideReport[], totalCount: number, page: QueryParamsModel  }) { }
}

export class PendingRidesPageCancelled implements Action {
    readonly type = PendingRideActionTypes.PendingRidesPageCancelled;
}
export class PendingRidesPageToggleLoading implements Action {
    readonly type = PendingRideActionTypes.PendingRidesPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class PendingRidesActionToggleLoading implements Action {
    readonly type = PendingRideActionTypes.PendingRidesActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}


export type PendingRideActions = PendingRidesPageLoaded
| PendingRidesPageCancelled
| PendingRidesPageRequested
| PendingRidesPageToggleLoading
| PendingRidesActionToggleLoading;

