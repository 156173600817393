import { Attribute } from '..';

export class VehicleGroup {
  _id: string;
  vehicleTypes: string[];
  isDelete: boolean;
  name: string;
  zoneId: string;
  isEnabled: boolean;
  cityId: string;
  pricing: string; // 'both' for price range
  createdById: string;
  readOnly: boolean;
  __v: number;

  clear(): void {
    this._id = undefined;
    this.vehicleTypes = [];
    this.isDelete = false;
    this.name = '';
    this.zoneId = undefined;
    this.isEnabled = false;
    this.cityId = undefined;
    this.pricing = 'both'; // 'both' for price range
    this.createdById = '';
    this.readOnly = false;
    this.__v = 0;
  }
}
