
export class HardCopy {
  _id: string;
  driverImageId: boolean;
  drivingLicense: boolean;
  aadharCard: boolean;
  panCard: boolean;
  presentAddressProof: boolean;
  medicalLifeInsurance: boolean;
  rcBook: boolean;
  roadTaxReceipt: boolean;
  pollutionControlCertificate: boolean;
  vehicleLoan: boolean;
  vehicleInsurance: boolean;
  bankAccountDetails: boolean;
  votersId: boolean;
  deliveryType: string;
  trackId: string;
  courierServiceName: string;
  driverId: string;
  documentId: string;
  __v: number;

  clear(): void {
    this._id = undefined;
    this.driverImageId = false;
    this.drivingLicense = false;
    this.aadharCard = false,
    this.panCard = false;
    this.presentAddressProof = false;
    this.medicalLifeInsurance = false;
    this.rcBook = false;
    this.roadTaxReceipt = false;
    this.pollutionControlCertificate = false;
    this.vehicleLoan = false;
    this.vehicleInsurance = false;
    this.bankAccountDetails = false;
    this.votersId = false;
    this.deliveryType = '';
    this.trackId = undefined;
    this.courierServiceName = '';
    this.driverId = undefined;
    this.documentId = undefined;
    this.__v = 0;
  }
}
