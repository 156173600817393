// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { RidersState } from '../_reducers/rider.reducers';
import { each } from 'lodash-es';
import { Rider } from '../_models/rider.model';

export const selectRidersState = createFeatureSelector<RidersState>('riders');

export const selectRiderById = (_id: string) => createSelector(
    selectRidersState,
    ridersState => ridersState.entities[_id]
);

export const selectRidersPageLoading = createSelector(
    selectRidersState,
    ridersState => {
        return ridersState.listLoading;
    }
);

export const selectRidersActionLoading = createSelector(
    selectRidersState,
    ridersState => ridersState.actionsloading
);

export const selectLastCreatedRiderId = createSelector(
    selectRidersState,
    ridersState => ridersState.lastCreatedRiderId
);

export const selectRidersPageLastQuery = createSelector(
    selectRidersState,
    ridersState => ridersState.lastQuery
);

export const selectRidersInStore = createSelector(
    selectRidersState,
    ridersState => {
        const items: Rider[] = [];
        each(ridersState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: Rider[] = httpExtension.sortArray(items, ridersState.lastQuery.sortField, ridersState.lastQuery.sortOrder);
        return new QueryResultsModel(result, ridersState.totalCount, '');

    }
);

export const selectRidersShowInitWaitingMessage = createSelector(
    selectRidersState,
    ridersState => ridersState.showInitWaitingMessage
);

export const selectHasRidersInStore = createSelector(
    selectRidersState,
    queryResult => {
        if (!queryResult.totalCount) {
            return false;
        }

        return true;
    }
);

