// NGRX
import { Action } from '@ngrx/store';
// CRUD
import { Kyc } from '../_models/kyc.model';
// Models
import { QueryParamsModel } from '../../_base/crud';

export enum KycActionTypes {
    AllKycRequested = '[Kyc Module] All Kyc Requested',
    AllKycLoaded = '[Kyc API] All Kyc Loaded',
    KycPageRequested = '[Kyc List Page] Kyc Page Requested',
    KycPageLoaded = '[Kyc API] Kyc Page Loaded',
    KycPageCancelled = '[Kyc API] Kyc Page Cancelled',
    KycPageToggleLoading = '[Kyc] Kyc Page Toggle Loading',
    KycActionToggleLoading = '[Kyc] Kyc Action Toggle Loading'
}

export class KycPageRequested implements Action {
    readonly type = KycActionTypes.KycPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class KycPageLoaded implements Action {
    readonly type = KycActionTypes.KycPageLoaded;
    constructor(public payload: { kyc: Kyc[], totalCount: number, page: QueryParamsModel  }) { }
}


export class KycPageCancelled implements Action {
    readonly type = KycActionTypes.KycPageCancelled;
}

export class KycPageToggleLoading implements Action {
    readonly type = KycActionTypes.KycPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class KycActionToggleLoading implements Action {
    readonly type = KycActionTypes.KycActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type KycActions = KycPageLoaded
| KycPageCancelled
| KycPageToggleLoading
| KycPageRequested
| KycActionToggleLoading;
