import { Country } from './country.model';

export class Zone {
  _id: string;
  country: Country;
  zoneName: string;
  state: string;
  createdById: string;
  isDelete: boolean;
  readOnly: boolean;

  clear(): void {
    this._id = undefined;
    this.country = new Country();
    this.country.clear();
    this.zoneName = '';
    this.state = 'Karnataka';
    this.createdById = undefined;
    this.isDelete = false;
    this.readOnly = false;
  }
}
