import { Injectable, PipeTransform } from "@angular/core";

import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import { debounceTime, delay, switchMap, tap, mergeMap } from "rxjs/operators";

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { QueryParamsModel } from '../../_base/crud';

const API_URL = environment.apiUrl+'/settings';

@Injectable({
  providedIn: "root",
})

export class VehicleService {

  constructor(private http: HttpClient) {}

  // Get all vehicles
  getAllVehicles(queryParams: QueryParamsModel):Observable<any> {
    return this.http.get<any>(API_URL+'/allvehicle?'+
    'pageSize='+queryParams.pageSize +
    '&pageNumber='+queryParams.pageNumber+
    '&sortOrder='+queryParams.sortOrder+
    '&sortField='+queryParams.sortField+
    '&searchText='+queryParams.searchText+
    queryParams.filter).pipe(
      mergeMap(res => {
          console.log(res);
          if(res.response.code === 200) {
              const result = {
                items: res.data.list,
                totalCount: res.data.totalCount
              };
              return of(result);
          } else {
            const result = {
              items: [],
              totalCount: 0
            };
            return of(result);
          }
      })
    );
  }

  // Get a vehicle
  getVehicle(id: string): Observable<any> {
      return this.http.get<any>(API_URL+'/vehicle?_id='+id);
  }

  // Add a new vehicle
  addVehicle(vehicle: any): Observable<any> {
      return this.http.post<any>(API_URL+'/vehicle', vehicle);
  }

  // Update a vehicle
  updateVehicle(vehicle: any): Observable<any> {
    return this.http.put<any>(API_URL + '/vehicle', vehicle);
  }

  // Delete a vehicle
  deleteVehicle(vehicle: any): Observable<any> {
    return this.http.delete(API_URL + '/vehicle?_id=' + vehicle._id+'&isDelete='+vehicle.isDelete);
  }

  // Get all vehicle filter attribute
  getVehicleFilter(_brand: string, _model: string): Observable<any> {
    return this.http.get(API_URL + '/vehiclefilter?brand=' + _brand + '&vehicleModel='+_model);
  }

  // Get all vehicle attributes
  getAllAttributes(isDelete: boolean = true): Observable<any>{
    let url = '';
    if (!isDelete) {
      url = '?isDelete=false';
    }
    return this.http.get(API_URL+'/allattribute'+url);
  }

  // Get single vehicle attribute
  getAttributes(type: string): Observable<any>{
    return this.http.get(API_URL+'/attribute?attribute='+type);
  }

  // Add new vehicle attribute
  addAttribute(attribute: any): Observable<any>{
    return this.http.post(API_URL+'/attribute',attribute);
  }

  // Update vehicle attribute
  updateAttribute(attribute: any): Observable<any>{
    return this.http.put(API_URL+'/attribute',attribute);
  }

  // Delete vehicle attribute
  deleteAttribute(id: string, attribute: string, status: boolean): Observable<any>{
    return this.http.delete(API_URL+'/attribute?attribute='+attribute+'&_id='+id+'&isDelete='+status);
  }

  // Get all vehicle groups with filter
  getAllVehicleGroup(queryParams: QueryParamsModel): Observable<any> {
    return this.http.get<any>(API_URL + '/attribute/group?' +
      'pageSize=' + queryParams.pageSize +
      '&pageNumber=' + queryParams.pageNumber +
      '&sortOrder=' + queryParams.sortOrder +
      '&sortField=' + queryParams.sortField +
      '&searchText=' + queryParams.searchText + queryParams.filter).pipe(
        mergeMap(res => {
          console.log(res);
          if (res.response.code === 200) {
            const result = {
              items: res.data,
              totalCount: res.data.length
            };
            return of(result);
          } else {
            const result = {
              items: [],
              totalCount: 0
            };
            return of(result);
          }
        })
      );
  }

}
