import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, Input, Inject, Output, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// RxJS
import { Observable, BehaviorSubject, Subscription, of, Subject, take, map, mergeMap } from 'rxjs';

import { UploadService } from "../../../../core/upload/_services/upload.service";
import { UploadFile } from '../../../../core/upload/_models/uploadFile.model';

//Moment
import * as moment from 'moment';
// CRUD
import { LayoutUtilsService, MessageType } from "../../../../core/_base/crud";

import { ImageCroppedEvent } from 'ngx-image-cropper';

import { HttpClient, HttpHeaders } from '@angular/common/http';

export interface ImageTransform {
  scale?: number;
  rotate?: number;
  flipH?: boolean;
  flipV?: boolean;
}

export interface Dimensions {
  width: number;
  height: number;
}

export interface CropperPosition {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
}

@Component({
  selector: 'kt-photo-editor',
  templateUrl: './photo-editor.component.html',
  styleUrls: ['./photo-editor.component.scss']
})
export class PhotoEditorComponent implements OnInit, OnDestroy {

  loading = false;
  imageChange: boolean = false;
  imageChangedEvent: any = '';
  imageURL: string = '';
  imageBase64: string = '';
  croppedImage: any = '';
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  maintainRatio = false;
  aspectRatio = '1';
  transform: ImageTransform = {};

  contentType: string = '';

  private formData: FormData;
  private subscriptions: Subscription[] = [];

  constructor(public dialogRef: MatDialogRef<PhotoEditorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private layoutUtilsService: LayoutUtilsService,
    public cdr: ChangeDetectorRef,
    private uploadService: UploadService,
    private http: HttpClient) { }

  ngOnInit(): void {
    console.log(this.data);
    this.aspectRatio = this.data.aspectRatio;
    this.maintainRatio = this.data.maintainRatio;
    if (this.data?.file?.fileUploadId) {
      this.contentType = this.data?.file?.contentType;
      this.imageURL = this.data?.file?.imageUrl;
      this.dataURLtoBase64(this.data?.file?.imageUrl);
      this.imageChangedEvent = null;
      this.imageChange = true;
    }
  }

  fileChangeEvent(event: any): void {
    //if (event.target.files[0].size > 20971520) {
    if (event.target.files[0].size > 10485760) {
      this.layoutUtilsService.showActionNotification(MessageType.warning, 'File Too Large!', 'Maximum uploadable file size is 10MB. Please upload a smaller image and try again.');
      return;
    }
    if (event.target.files[0].type === 'image/jpeg' || event.target.files[0].type === 'image/jpg' || event.target.files[0].type === 'image/png') {
      this.imageChangedEvent = event;
      this.imageChange = true;
      console.log("file change");
    } else if (event.target.files[0].type === 'application/pdf') {
      //
    } else {
      this.layoutUtilsService.showActionNotification(MessageType.warning, 'Unsupported File Format!', 'Selected file format not supported. Please upload a jpg or png image.');
    }

  }

  imageCropped(event: ImageCroppedEvent) {
    console.log(event);
    this.croppedImage = event.base64;
    console.log("image cropped");
  }

  imageLoaded(image: HTMLImageElement) {
    // show cropper
    console.log("image loaded");
  }

  cropperReady() {
    // cropper ready
    console.log("cropper ready");
  }

  loadImageFailed() {
    // show message
    this.layoutUtilsService.showActionNotification(MessageType.danger, 'Image loading failed', 'Something went wrong, please try again later.');
    console.log("load failed");
  }

  ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());
  }

  dataURLtoFile(dataurl, filename) {
    console.log(dataurl);
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  dataURLtoBase64(dataurl) {
    this.uploadService.getBase64FromUrl(dataurl).subscribe(
      base64 => {
        console.log(base64);
        this.imageBase64 = 'data:' + this.contentType + ';base64,' + base64;
    });
  }

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }


  flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH
    };
  }

  flipVertical() {
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV
    };
  }

  resetImage() {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
  }

  zoomOut() {
    this.scale -= .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  zoomIn() {
    this.scale += .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  maintainAspectRatio() {
    this.maintainRatio = !this.maintainRatio;
  }

  toggleContainWithinAspectRatio() {
    this.containWithinAspectRatio = !this.containWithinAspectRatio;
  }

  updateRotation() {
    this.transform = {
      ...this.transform,
      rotate: this.rotation
    };
  }

  onSubmit(): void {
    if (!this.croppedImage) {
      this.layoutUtilsService.showActionNotification(MessageType.danger, 'File Required!', 'Need to upload an image');
      return;
    }
    this.loading = true;
    this.formData = new FormData();
    if (this.imageChange) {
      var file = this.dataURLtoFile(this.croppedImage, moment().valueOf() + '.png');
      this.formData.append('file', file);
      this.subscriptions.push(this.uploadService.uploadFile(this.formData).subscribe(
        (res) => {
          if (res.response.code === 201) {
            var _data = res.data[0];
            this.dialogRef.close({
              _data
            });
          }
        },
        error => {
          this.loading = false;
          this.layoutUtilsService.showActionNotification(MessageType.danger, 'Servr Error!', 'Something went wrong, please try again later.');
        }
      ));
    } else {
      setTimeout(() => {
        this.dialogRef.close();
      }, 500);
    }
  }

  onClose() {
    this.dialogRef.close();
  }
}
