import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { inject } from "@angular/core/testing";
import { BehaviorSubject, Subject } from "rxjs";
// RxJS
import { Observable, of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";

import { environment } from "src/environments/environment";

import { QueryParamsModel } from "../../_base/crud";

const API_URL = environment.apiUrl;

@Injectable({ providedIn: "root" })

export class PromotionService {

  constructor(private http: HttpClient) {}

  // Get all promotions with table functions
  getAllPromotions(queryParams: QueryParamsModel): Observable<any> {
    return this.http.get<any>(API_URL + '/settings/promotion?isDelete=false&pageSize=' + queryParams.pageSize +
    '&pageNumber=' + queryParams.pageNumber +
    '&sortField=' + queryParams.sortField +
    '&sortOrder=' + queryParams.sortOrder +
    '&searchText=' + queryParams.searchText + queryParams.filter).pipe(
      mergeMap(res => {
        console.log(res);
        if (res.response.code === 200) {
          const result = {
            items: res.data.list,
            totalCount: res.data.totalCount
          };
          return of(result);
        } else {
          const result = {
            items: [],
            totalCount: 0
          };
          return of(result);
        }
      })
    );
  }

  // Get single Promotion
  getSinglePromotion(id): Observable<any> {
    return this.http.get(API_URL + '/settings/promotion?_id='+id);
  }

  // Add new promotion
  createPromotion(promotion: any): Observable<any> {
    return this.http.post(API_URL + '/settings/promotion', promotion);
  }

  // Update promotion
  updatePromotion(promotion: any): Observable<any> {
    return this.http.put(API_URL + '/settings/promotion', promotion);
  }

  // Suspend promotion
  suspendPromotion(promotion: any): Observable<any> {
    return this.http.put(API_URL + '/settings/promotion/suspend', promotion);
  }

  // Get all promotion settlement with table functions
  getAllPromotionSettlements(queryParams: QueryParamsModel): Observable<any> {
    return this.http.get<any>(API_URL + '/drivers/driver/promotionsettlement?pageSize=' + queryParams.pageSize +
    '&pageNumber=' + queryParams.pageNumber +
    '&sortField=' + queryParams.sortField +
    '&sortOrder=' + queryParams.sortOrder +
    '&searchText=' + queryParams.searchText + queryParams.filter).pipe(
      mergeMap(res => {
        console.log(res);
        if (res.response.code === 200) {
          const result = {
            items: res.data.list,
            totalCount: res.data.totalCount
          };
          return of(result);
        } else {
          const result = {
            items: [],
            totalCount: 0
          };
          return of(result);
        }
      })
    );
  }

  // Get single promotion settlement
  getSinglePromotionSettlement(id): Observable<any> {
    return this.http.get(API_URL + '/drivers/driver/promotionsettlement?_id=' + id);
  }

  // Update / Reject multiple promotion settlement
  updatePromotionSettlement(data): Observable<any> {
    return this.http.put(API_URL + '/drivers/driver/promotionsettlement', data);
  }

  // Create order for payout
  createOrder(data): Observable<any> {
    return this.http.post(API_URL + '/drivers/driver/promotionsettlement/order', data);
  }

  // Get all orders with table functions
  getAllOrders(queryParams: QueryParamsModel): Observable<any> {
    return this.http.get<any>(API_URL + '/promotionSettlement/orders?isDelete=false&pageSize=' + queryParams.pageSize +
    '&pageNumber=' + queryParams.pageNumber +
    '&sortField=' + queryParams.sortField +
    '&sortOrder=' + queryParams.sortOrder +
    '&searchText=' + queryParams.searchText + queryParams.filter).pipe(
      mergeMap(res => {
        console.log(res);
        if (res.response.code === 200) {
          const result = {
            items: res.data.list,
            totalCount: res.data.totalCount
          };
          return of(result);
        } else {
          const result = {
            items: [],
            totalCount: 0
          };
          return of(result);
        }
      })
    );
  }

  // Get single order
  getSingleOrder(id): Observable<any> {
    return this.http.get(API_URL + '/promotionSettlement/orders?_id=' + id);
  }

  // Create order for payout
  verifyPayment(data): Observable<any> {
    return this.http.post(API_URL + '/drivers/driver/promotionsettlement/complete', data);
  }

}
