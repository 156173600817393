import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Rider } from '../_models/rider.model';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { QueryParamsModel, QueryResultsModel} from '../../_base/crud';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';

const API_URL = environment.apiUrl + '/auth';
const API_RIDER_URL = environment.apiUrl + '/riders';

@Injectable({
  providedIn: "root"
})
export class RiderService {

  constructor(private http: HttpClient) {}

  // Get all riders
  getAllRiders(queryParams: QueryParamsModel):Observable<any> {
    return this.http.get<any>(API_URL+'/management/allrider?'+
    'pageSize='+queryParams.pageSize +
    '&pageNumber='+queryParams.pageNumber+
    '&sortField='+queryParams.sortField+
    '&sortOrder='+queryParams.sortOrder+
    '&searchText='+queryParams.searchText+queryParams.filter).pipe(
      mergeMap(res => {
          console.log(res);
          if(res.response.code === 200) {
              const result = {
                items: res.data.list,
                totalCount: res.data.totalCount
              }
              return of(result);
          } else {
            const result = {
              items: [],
              totalCount: 0
            }
            return of(result);
          }
      })
    );
  }

  // Export all riders
  exportAllRiders(queryParams: QueryParamsModel): Observable<any> {
    return this.http.get<any>(API_URL + '/management/allrider/excel?' +
    'pageSize=' + queryParams.pageSize +
    '&pageNumber=' + queryParams.pageNumber +
    '&sortField=' + queryParams.sortField +
    '&sortOrder=' + queryParams.sortOrder +
    '&searchText=' + queryParams.searchText + queryParams.filter).pipe(
      mergeMap(res => {
        console.log(res);
        return of(res);
      })
    );
  }

  // Get a single rider by id
  getSingleRider(_id:string): Observable<any>{
    return this.http.get(API_URL+ '/management/rider?riderId=' +_id);
  }

  // Get rider action history
  getRiderActionHistory(queryParams: QueryParamsModel): Observable<any>{
    return this.http.get(API_URL+ '/suspendreport?'+
    'pageSize='+queryParams.pageSize +
    '&pageNumber='+queryParams.pageNumber+
    '&sortField='+queryParams.sortField+
    '&sortOrder='+queryParams.sortOrder+queryParams.filter);
  }

  // Invite Rider
  inviteRider(email: string): Observable<any> {
    return this.http.post<any>(API_URL+'/management/invite/email', email).pipe(
      mergeMap((res: any)=> {
           // console.log(res);
          const result = res.data;
          return of(result);
      })
    )
  }

  // Invite Rider with email
  inviteRiderEmail(data): Observable<any>{
    return this.http.post(API_URL+'/management/invite/email',data);
  }

  // Invite Rider with sms
  inviteRiderSms(data): Observable<any>{
    return this.http.post(API_URL+'/management/invite/sms/rider',data);
  }

  // Update Rider
  updateRider(value): Observable<any>{
    return this.http.put(API_URL + '/management/rider', value);
  }

  // Delete Rider
  deleteRider(_id: string, reason: string, isDelete: boolean = true): Observable<any>{
    return this.http.delete(API_URL + '/management/rider?riderId=' + _id + '&isDelete=' + isDelete + '&isDeleteReason=' + reason);
  }

  // Suspend or Reinstate Rider
  suspendRider(data: any): Observable<any>{
    return this.http.put(API_URL+'/management/rider/suspend', data);
  }

  // Verify Rider
  verifyRider(data: any): Observable<any> {
    return this.http.put(API_RIDER_URL + '/rider/profile/verify', data);
  }

  // Handler
  private handleError<T>(rider = 'rider', result?: any) {
    return (error: any): Observable<any> => {
        // TODO: send the error to remote logging infrastructure
        console.error(error); // log to console instead
        // Let the app keep running by returning an empty result.
        return of(result);
    };
  }

}
