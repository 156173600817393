<div class="modal-header border-bottom-0 mb-0 pb-0">
  <button id="closeDialogButton" type="button" class="close" aria-hidden="true" (click)="onCancel()">×</button>
</div>
<div class="modal-body p-4 pt-0 text-center">
  <div class="icon-box-c icon-{{data.type}}">
    <i class="fas fa-{{(data.type === 'suspend' || data.type === 'kycReject' || data.type === 'disable' || data.type === 'settlementStatus') ? 'times' : ((data.type === 'reinstate' || data.type === 'kycValid') ? 'check' : (data.type === 'vehicleType' ? 'car' : 'trash'))}}"></i>
  </div>
  <h5 class="modal-title font-size-16 mt-2 mb-1 text-dark text-center">{{data.title}}</h5>
  <p class="font-size-4 text-dark text-center">{{data.description}}</p>
  <div class="row">
    <div class="col-12">
      <div class="col-md-12">
        <form class="form-horizontal text-left" [formGroup]="statusForm">
          <div class="form-group mt-1 mb-3" *ngIf="isMaster && ((data.type === 'suspend') || (data.type === 'delete'))">
            <label>Select {{ (data.type === 'delete') ? 'Permanent' : 'Temporary'}} Master</label>
            <ng-select id="driverIDSelect" [ngClass]="{ 'is-invalid': isControlHasError('driverId')}" appearance="outline" [searchable]="false" class="form-control" [clearable]="false" formControlName="driverId" required (change)="reasonChange($event, 'driver')">
              <ng-option value="">Select a master</ng-option>
              <ng-option *ngFor="let item of driverList" [value]="item?._id">
                {{item?.name | titlecase}} ({{item?.driverID}})
              </ng-option>
            </ng-select>
            <div *ngIf="isControlHasError('driverId','required')" class="invalid-feedbacks">
              Master Driver is required
            </div>
          </div>
          <div class="form-group mt-1 mb-3" *ngIf="data.reason && (data.type !== 'vehicleType') && (data.type !== 'delete') && (data.type !== 'disable')">
            <label>Reason</label>
            <ng-select id="seasonSelect1" [ngClass]="{ 'is-invalid': isControlHasError('reason')}" appearance="outline" [searchable]="false" class="form-control" [clearable]="false" formControlName="reason" required (change)="reasonChange($event, 'reason')">
              <ng-option value="">Select a reason</ng-option>
              <ng-option *ngFor="let item of reasonList" [value]="item?.reason">
                {{item?.reason | titlecase}}
              </ng-option>
              <ng-option value="other">Other</ng-option>
            </ng-select>
            <div *ngIf="isControlHasError('reason','required')" class="invalid-feedbacks">
              Reason is required
            </div>
          </div>
          <div class="form-group mt-1 mb-3" *ngIf="(data.type === 'disable')">
            <label>Reason</label>
            <ng-select id="reasonSelect2" [ngClass]="{ 'is-invalid': isControlHasError('reason')}" appearance="outline" [searchable]="false" class="form-control" [clearable]="false" formControlName="reason" required (change)="reasonChange($event, 'reason')">
              <ng-option value="">Select a reason</ng-option>
              <ng-option *ngFor="let item of disableList" [value]="item">
                {{item | titlecase}}
              </ng-option>
            </ng-select>
            <div *ngIf="isControlHasError('reason','required')" class="invalid-feedbacks">
              Reason is required
            </div>
          </div>
          <div class="form-group mt-1 mb-3" *ngIf="(data.type === 'vehicleType')">
            <label>Vehicle Type</label>
            <ng-select id="vehicleTypeSelect" [ngClass]="{ 'is-invalid': isControlHasError('reason')}" appearance="outline" [searchable]="false" class="form-control" [clearable]="false" formControlName="reason" required (change)="reasonChange($event, 'reason')">
              <ng-option value="">Select a vehicle type</ng-option>
              <ng-option *ngFor="let item of vehicleTypeList" [value]="item?._id">
                {{item?.name | titlecase}}
              </ng-option>
            </ng-select>
            <div *ngIf="isControlHasError('reason','required')" class="invalid-feedbacks">
              Vehicle Type is required
            </div>
          </div>
          <div class="form-group mt-1 mb-3" *ngIf="(data.type === 'settlementStatus')">
            <label>Settlement Status</label>
            <ng-select id="settlementStatusSelect" [ngClass]="{ 'is-invalid': isControlHasError('reason')}" appearance="outline" [searchable]="false"
              class="form-control" [clearable]="false" formControlName="reason" required
              (change)="reasonChange($event, 'reason')">
              <ng-option value="">Select Status</ng-option>
              <ng-option value="settled">Settled</ng-option>
              <ng-option value="rejected">Rejected</ng-option>
            </ng-select>
            <div *ngIf="isControlHasError('reason','required')" class="invalid-feedbacks">
              Settlement Status is required
            </div>
          </div>
          <div class="form-group mt-1 mb-4" *ngIf="(type === 'other') && data.reason || (data.type === 'delete') || (data.type === 'kycReject') || (data.type === 'kycValid') || (data.type === 'settlementStatus')">
            <label>Comment</label>
            <textarea id="commentsTextArea" class="form-control" placeholder="Type your comments here..." rows="3" formControlName="comment" required></textarea>
            <div *ngIf="isControlHasError('comment','required')" class="invalid-feedbacks">
              Comment is required
            </div>
          </div>
        </form>
      </div>
      <div class="col-md-12 text-right">
        <button id="cancelButton" type="button" class="btn btn-secondary w-sm mr-3" (click)="onCancel()">
          Cancel
        </button>
        <button id="submitButton" type="button" class="btn btn-primary w-sm" (click)="onConfirm()" [disabled]="viewLoading">
          {{data.confirmButton}}
          <i *ngIf="viewLoading" class="bx bx-loader bx-spin"></i>
        </button>
      </div>
    </div>
  </div>
</div>
