export class Pincode {
    _id: string;
    pincode: number;
    officeName: string;
    taluk: string;
    districtName: string;
    stateName: string;

    clear(): void {
        this._id = undefined;
        this.pincode = undefined;
        this.officeName = '';
        this.taluk = '';
        this.districtName = '';
        this.stateName = '';
    }
}
