import { Zone } from './zone.model';
import { City } from './city.model';

export class PopularLocation {
  _id: string;
  zoneId: string;
  cityId: string;
  locationName: string;
  latitude: number;
  longitude: number;
  spendingTime: number;
  isSuspend: boolean;
  isDelete: boolean;
  createdById: string;
  readOnly: boolean;
  zone: Zone;
  city: City;
  __v: number;

  clear(): void {
    this._id = undefined;
    this.zoneId = undefined;
    this.cityId = undefined;
    this.locationName = '';
    this.latitude = undefined;
    this.longitude = undefined;
    this.spendingTime = undefined;
    this.isSuspend = false;
    this.isDelete = false;
    this.createdById = '';
    this.readOnly = false;
    this.zone = new Zone();
    this.city = new City();
    this.__v = 0
  }
}
