
export class ExotelCall {
  Sid: string;
  ParentCallSid: string;
  DateCreated: string;
  DateUpdated: string;
  AccountSid: string;
  To: string;
  From: string;
  PhoneNumber: string;
  PhoneNumberSid: string;
  Status: string; // failed,no-answer,busy,canceled
  StartTime: string;
  EndTime: string;
  Duration: number;
  Price: number;
  Direction: string; // outbound-dial,inbound
  AnsweredBy: string;
  ForwardedFrom: string;
  CallerName: string;
  Uri: string;
  CustomField: string;
  RecordingUrl: string;

  clear(): void {
    this.Sid = undefined;
    this.ParentCallSid = undefined;
    this.DateCreated = '';
    this.DateUpdated = '';
    this.AccountSid = '';
    this.To = '';
    this.From = '';
    this.PhoneNumber = '';
    this.PhoneNumberSid = '';
    this.Status = '';
    this.StartTime = '';
    this.EndTime = '';
    this.Duration = 0;
    this.Price = 0;
    this.Direction = '';
    this.AnsweredBy = '';
    this.ForwardedFrom = '';
    this.CallerName = '';
    this.Uri = '';
    this.CustomField = '';
    this.RecordingUrl = '';
  }
}
