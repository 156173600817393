<form class="form-horizontal" [formGroup]="forgotForm">

  <app-alert></app-alert>

  <div class="form-group mb-4">
    <label for="emailInput">Email</label>
    <input id="emailInput" type="email" formControlName="email" class="form-control" placeholder="Enter Your Email" [ngClass]="{ 'is-invalid': isControlHasError('email') }" />
    <div *ngIf="isControlHasError('email')" class="invalid-feedback">
      <div *ngIf="isControlHasError('email','required')">
        Email is required
      </div>
      <div *ngIf="isControlHasError('email','pattern')">
        Email must be a valid email address
      </div>
    </div>
  </div>
  <div class="mt-3">
    <button id="resetButton" class="btn btn-primary btn-block" (click)="onSubmit()" [disabled]="loading">
      Reset
      <i *ngIf="loading" class="bx bx-loader bx-spin"></i>
    </button>
  </div>
</form>
