// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
// Actions
import { KycActions, KycActionTypes } from '../_actions/kyc.actions';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { Kyc } from '../_models/kyc.model';
// RxJS
import { Observable, of } from 'rxjs';

// tslint:disable-next-line:no-empty-interface
export interface KycState extends EntityState<Kyc> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedKycId: string;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<Kyc> = createEntityAdapter({
  selectId: (kyc: Kyc) => kyc._id,
});

export const initialKycState: KycState = adapter.getInitialState({
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastQuery:  new QueryParamsModel(),
    lastCreatedKycId: undefined,
    showInitWaitingMessage: true
});

export function kycReducer(state = initialKycState, action: KycActions): KycState {
    switch  (action.type) {
        case KycActionTypes.KycPageToggleLoading: return {
            ...state, listLoading: action.payload.isLoading, lastCreatedKycId: undefined
        };
        case KycActionTypes.KycActionToggleLoading: return {
            ...state, actionsloading: action.payload.isLoading
        };
        case KycActionTypes.KycPageCancelled: return {
            ...state, listLoading: false, lastQuery: new QueryParamsModel()
        };
        case KycActionTypes.KycPageLoaded: {
            console.log(action.payload.kyc);
            return adapter.setAll(action.payload.kyc, {
                ...initialKycState,
                totalCount: action.payload.totalCount,
                lastQuery: action.payload.page,
                listLoading: false,
                showInitWaitingMessage: false
            });
        }
        default: return state;
    }
}

export const getDriverState = createFeatureSelector<KycState>('kyc');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
