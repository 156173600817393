// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { TripFareState } from '../_reducers/tripFare.reducers';
import { each } from 'lodash-es';
import { TripFare } from '../_models/tripFare.model';


export const selectTripFareState = createFeatureSelector<TripFareState>('tripFare');

export const selectTripFareById = (_id: string) => createSelector(
    selectTripFareState,
    tripFareState => tripFareState.entities[_id]
);

export const selectTripFarePageLoading = createSelector(
    selectTripFareState,
    tripFareState => {
        return tripFareState.listLoading;
    }
);

export const selectTripFareActionLoading = createSelector(
    selectTripFareState,
    tripFareState => tripFareState.actionsloading
);

export const selectLastCreatedTripFareId = createSelector(
    selectTripFareState,
    tripFareState => tripFareState.lastCreatedTripFareId
);

export const selectTripFarePageLastQuery = createSelector(
    selectTripFareState,
    tripFareState => tripFareState.lastQuery
);

export const selectTripFareInStore = createSelector(
    selectTripFareState,
    tripFareState => {
        const items: TripFare[] = [];
        each(tripFareState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: TripFare[] = httpExtension.sortArray(items, tripFareState.lastQuery.sortField, tripFareState.lastQuery.sortOrder);
        return new QueryResultsModel(result, tripFareState.totalCount, '');

    }
);

export const selectTripFareShowInitWaitingMessage = createSelector(
    selectTripFareState,
    tripFareState => tripFareState.showInitWaitingMessage
);

export const selectHasTripFareInStore = createSelector(
    selectTripFareState,
    queryResult => {
        if (!queryResult.totalCount) {
            return false;
        }

        return true;
    }
);
