<form class="form-horizontal" [formGroup]="lockForm">

  <div class="user-thumb text-center mb-4">
    <img src="{{user?.profileImage?.imageUrl ? user?.profileImage?.imageUrl : '/assets/images/icons/profile.png'}}" class="rounded-circle img-thumbnail avatar-md"
      alt="">
    <h5 class="font-size-15 mt-3">{{ user.name ? 'Hi '+(user.name | titlecase) : 'Hi Nobie'}}</h5>
    <p>{{user.email}}</p>
  </div>

  <app-alert></app-alert>

  <div class="form-group">
    <label for="password">
      Password
      <a href="javascript:void(0)" class="ml-1" (click)="togglePassword(0)"><i class="fa fa-{{passwordType[0] === 'text' ? 'eye-slash' : 'eye'}}"></i></a>
    </label>
    <input id="passwordInput" type="{{passwordType[0]}}" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': isControlHasError('password') }" placeholder="Enter Your Password" />
    <div *ngIf="isControlHasError('password')" class="invalid-feedback">
      <div *ngIf="isControlHasError('password','required')">
        Password is required
      </div>
    </div>
  </div>

  <div class="form-group row mb-0">
    <div class="col-12 text-right">
      <button id="unlockButton" class="btn btn-primary btn-block" (click)="onSubmit()" [disabled]="loading">
      Unlock
      <i *ngIf="loading" class="bx bx-loader bx-spin"></i>
    </button>
    </div>
  </div>

</form>
