// RxJS
import { of } from 'rxjs';
import { catchError, finalize, tap, debounceTime, delay, distinctUntilChanged } from 'rxjs/operators';
// NGRX
import { Store, select } from '@ngrx/store';
// CRUD
import { BaseDataSource, QueryResultsModel } from '../../_base/crud';
// State
import { AppState } from '../../../core/reducers';
import { selectRidersInStore, selectRidersPageLoading, selectRidersShowInitWaitingMessage } from '../_selectors/rider.selectors';


export class RidersDataSource extends BaseDataSource {
	constructor(private store: Store<AppState>) {
		super();

		this.loading$ = this.store.pipe(
			select(selectRidersPageLoading)
		);

		this.isPreloadTextViewed$ = this.store.pipe(
			select(selectRidersShowInitWaitingMessage)
		);

		this.store.pipe(
			select(selectRidersInStore)
		).subscribe((response: QueryResultsModel) => {
			console.log(response);
			this.paginatorTotalSubject.next(undefined);
			this.entitySubject.next(response.items);
			this.paginatorTotalSubject.next(response.totalCount);
		});
	}
}
