// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { DriversState } from '../_reducers/driver.reducers';
import { each } from 'lodash-es';
import { Driver } from '../_models/driver.model';


export const selectDriversState = createFeatureSelector<DriversState>('drivers');

export const selectDriverById = (driverId: string) => createSelector(
    selectDriversState,
    driversState => driversState.entities[driverId]
);

export const selectDriversPageLoading = createSelector(
    selectDriversState,
    driversState => {
        return driversState.listLoading;
    }
);

export const selectDriversActionLoading = createSelector(
    selectDriversState,
    driversState => driversState.actionsloading
);

export const selectLastCreatedDriverId = createSelector(
    selectDriversState,
    driversState => driversState.lastCreatedDriverId
);

export const selectDriversPageLastQuery = createSelector(
    selectDriversState,
    driversState => driversState.lastQuery
);

export const selectDriversInStore = createSelector(
    selectDriversState,
    driversState => {
        const items: Driver[] = [];
        each(driversState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: Driver[] = httpExtension.sortArray(items, driversState.lastQuery.sortField, driversState.lastQuery.sortOrder);
        return new QueryResultsModel(result, driversState.totalCount, '');

    }
);

export const selectDriversShowInitWaitingMessage = createSelector(
    selectDriversState,
    driversState => driversState.showInitWaitingMessage
);

export const selectHasDriversInStore = createSelector(
    selectDriversState,
    queryResult => {
        if (!queryResult.totalCount) {
            return false;
        }

        return true;
    }
);
