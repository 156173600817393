import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { RideReport } from '../_models/rideReport.model';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { QueryParamsModel, QueryResultsModel} from '../../_base/crud';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';

const API_URL = environment.apiUrl + '/drivers';
const RIDER_API_URL = environment.apiUrl + '/riders';
const AUTH_API_URL = environment.apiUrl + '/auth';

@Injectable({
  providedIn: "root"
})
export class RideReportService {

  constructor(private http: HttpClient) {}

  // Get all rider
  getAllRideReports(queryParams: QueryParamsModel):Observable<any> {
    return this.http.get<any>(API_URL+'/driver/ride/list?'+
    'pageSize='+queryParams.pageSize +
    '&pageNumber='+queryParams.pageNumber+
    '&sortField='+queryParams.sortField+
    '&sortOrder='+queryParams.sortOrder+
    '&searchText='+queryParams.searchText+
    queryParams.filter).pipe(
      mergeMap(res => {
          console.log(res);
          if(res.response.code === 200) {
              const result = {
                items: res.data.list,
                totalCount: res.data.totalCount,
                extra: {
                  totalAmount: res.data.totalAmount
                }
              };
              return of(result);
          } else {
            const result = {
              items: [],
              totalCount: 0,
              extra: {
                totalAmount: 0
              }
            };
            return of(result);
          }
      })
    );
  }

  // Get all pending rides
  getAllPendingRides(queryParams: QueryParamsModel): Observable<any> {
    return this.http.get<any>(API_URL + '/driver/ride/list/pending?' +
      'pageSize=' + queryParams.pageSize +
      '&pageNumber=' + queryParams.pageNumber +
      '&sortField=' + queryParams.sortField +
      '&sortOrder=' + queryParams.sortOrder +
      '&searchText=' + queryParams.searchText +
      queryParams.filter).pipe(
        mergeMap(res => {
          console.log(res);
          if (res.response.code === 200) {
            const result = {
              items: res.data.list,
              totalCount: res.data.totalCount
            };
            return of(result);
          } else {
            const result = {
              items: [],
              totalCount: 0
            };
            return of(result);
          }
        })
      );
  }

  // Get a single rider by id
  getSingleRideReport(_id:string): Observable<any>{
    return this.http.get(API_URL+ '/driver/ride/single?rideId=' +_id);
  }

  // Get a single ride track
  getRideTrack(_id: string): Observable<any> {
    return this.http.get(API_URL + '/driver/ride/location?rideId=' + _id);
  }

  // Get a single ride timeline
  getTimeline(_id: string): Observable<any> {
    return this.http.get(API_URL + '/driver/ride/timeline?rideId=' + _id);
  }

  // Get ride request notification Log
  getNotificationLog(_id: string): Observable<any> {
    return this.http.get(RIDER_API_URL + '/rider/ride/notify/list?rideId=' + _id);
  }

  // Get a single ride timeline
  getEstimate(_id: string): Observable<any> {
    return this.http.get(API_URL + '/driver/ride/estimate?rideId=' + _id);
  }

  // Download ride receipt after ride completion
  downloadInvoice(_id: string): Observable<any> {
    return this.http.get(RIDER_API_URL + '/rider/ride/receipt?rideId=' + _id);
  }

  // Cancel ride
  cancelRide(data): Observable<any> {
    return this.http.put(API_URL + '/driver/ride/cancel/admin', data);
  }

  // Assign driver to ride
  assignDriverManual(data): Observable<any> {
    return this.http.put(API_URL + '/driver/ride/assign', data);
  }

  // Remove driver from ride
  removeDriverManual(data): Observable<any> {
    return this.http.put(API_URL + '/driver/ride/assign/remove', data);
  }

  // Get ride verification
  getRideVerification(_id: string): Observable<any> {
    return this.http.get(API_URL + '/driver/ride/verify?rideId=' + _id);
  }

  // Verify ride
  verifyRide(data): Observable<any> {
    return this.http.put(API_URL + '/driver/ride/verify', data);
  }

  // Get chat id
  getChatId(data): Observable<any> {
    return this.http.post(AUTH_API_URL + '/comm/chat', data);
  }

  // Get rider device details
  getRiderDeviceDetails(riderId: string): Observable<any> {
    return this.http.get(RIDER_API_URL + '/rider/profile/version?riderId=' + riderId);
  }

  // Get driver device details
  getDriverDeviceDetails(driverId: string): Observable<any> {
    return this.http.get(API_URL + '/driver/profile/version?driverId=' + driverId);
  }


  // Handler
  private handleError<T>(rider = 'rider', result?: any) {
    return (error: any): Observable<any> => {
        // TODO: send the error to remote logging infrastructure
        console.error(error); // log to console instead
        // Let the app keep running by returning an empty result.
        return of(result);
    };
  }

}
