import { Activity } from '../../logs';

export class Rider {
  _id: string;
  emergencyContact: any;
  otpManagement: {
    otp: number,
    time: string
  };
  status: {
    admin: {
      isSuspend: boolean,
      reason: string
    },
    isActive: boolean
  };
  riderID: string;
  name: string;
  gender: string;
  email: string;
  address: string;
  isDelete: boolean;
  isDeleteReason: string;
  signUpVerification: boolean;
  riderType: string;
  rating: string;
  myDriverMobileNumber: any;
  dateOfJoining: string;
  mobileNumber: any;
  favoriteList: any[];
  __v: number;
  profileImage: {
    imageUrl: string
  };
  profileImageId: any;
  totalCount: number;
  totalRides: number;
  isTermsAgreed: boolean;
  version: Activity;
  zoneId: string;
  cityId: string;

  isVerified: boolean;

  clear(): void {
    this._id = undefined;
    this.emergencyContact = {
      number: "",
      countryCode: "91",
      primaryNumber: false
    };
    this.otpManagement = {
      otp: 0,
      time: ''
    };
    this.status = {
      admin: {
        isSuspend: false,
        reason: ''
      },
      isActive: false
    };
    this.riderID = undefined;
    this.name = '';
    this.gender = '';
    this.email = '';
    this.address = '';
    this.isDelete = false;
    this.isDeleteReason = '';
    this.signUpVerification = false;
    this.riderType = '';
    this.rating = '';
    this.favoriteList = [];
    this.myDriverMobileNumber = {
      countryCode: "91",
      number: ""
    };
    this.dateOfJoining = '';
    this.mobileNumber = {
      countryCode: "91",
      number: ""
    };
    this.__v = 0;
    this.profileImage = {
      imageUrl: ''
    };
    this.profileImageId = undefined;
    this.totalCount = 0;
    this.totalRides = 0;
    this.isTermsAgreed = false;
    this.version = new Activity();
    this.version.clear();
    this.zoneId = undefined;
    this.cityId = undefined;

    this.isVerified = false;
  }
}
