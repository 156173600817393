export class UploadFile {
  originalFileName: string;
  sizeInBytes: number;
  path: string;
  imageUrl: string;
  fileUploadId: string;
  isUploading: boolean;
  contentType: string;
  __v: number;

  clear(): void {
    this.originalFileName = '';
    this.sizeInBytes = 0;
    this.path = '';
    this.imageUrl = '';
    this.fileUploadId = undefined;
    this.isUploading = false;
    this.contentType = undefined;
    this.__v = 0;
  }
}
