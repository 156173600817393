// Angular
import { Component, OnDestroy, OnInit, ChangeDetectorRef, Input, Output, OnChanges, EventEmitter } from '@angular/core';
// RxJS
import { Subscription } from 'rxjs';
import {
  QueryParamsModel,
} from "src/app/core/_base/crud";

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit, OnDestroy, OnChanges {

  @Input() pageSize: number;
  @Input() page: number;
  @Input() collectionSize: number;
  @Output() pageChange: EventEmitter<any> = new EventEmitter();
  @Output("reload") reload: EventEmitter<any> = new EventEmitter();

  // Default values for next/prev only pagination
  @Input() boundaryLinks: boolean = true;
  @Input() maxSize: number = 5;
  @Input() ellipses: boolean = true;

	// Private properties
	private subscriptions: Subscription[] = [];

	constructor(private cdr: ChangeDetectorRef) {
	}

	/**
	 * On init
	 */
	ngOnInit() {
    //console.log(this.queryParms);
	}

  ngOnChanges(): void {
    //console.log(this.filterBy);
  }

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		this.subscriptions.forEach(sb => sb.unsubscribe());
	}

  onChange() {
    this.pageChange.emit(this.page);
    this.reload.emit();
  }

}
