
export class Message {
  _id: string;
  isRead: boolean;
  title: string;
  body: string;
  time: string;
  userId: string;
  priority: string;
  link: string;
  module: string;
  screen: string
  dataId: string;
  __v: number;

  clear(): void {
    this._id = undefined;
    this.isRead = false;
    this.title = '';
    this.body = '';
    this.time = '';
    this.userId = undefined;
    this.priority = 'normal';
    this.link = undefined;
    this.module = undefined;
    this.screen = undefined;
    this.dataId = undefined;
    this.__v = 0;
  }
}
