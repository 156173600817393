import { QueryParamsModel } from '../../_base/crud/models/query-models/query-params.model';

// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, tap, delay, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { Observable, defer, of, forkJoin } from 'rxjs';
// NGRX
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store, select, Action } from '@ngrx/store';
// import { map, switchMap, catchError } from "rxjs/operators";
// CRUD
import { QueryResultsModel } from '../../_base/crud';
import { AppState } from '../../reducers';

//Service
import { RideReportService } from '../_services/rideReport.services';
import {
    PendingRideActionTypes,
    PendingRidesPageRequested,
    PendingRidesPageLoaded,
    PendingRidesPageToggleLoading,
    PendingRidesActionToggleLoading
} from '../_actions/pendingRides.actions';

@Injectable()
export class PendingRideEffects {
    showPageLoadingDistpatcher = new PendingRidesPageToggleLoading({ isLoading: true });
    hidePageLoadingDistpatcher = new PendingRidesPageToggleLoading({ isLoading: false });

    showActionLoadingDistpatcher = new PendingRidesActionToggleLoading({ isLoading: true });
    hideActionLoadingDistpatcher = new PendingRidesActionToggleLoading({ isLoading: false });

    @Effect()
    loadPendingRidesPage$ = this.actions$.pipe(
            ofType<PendingRidesPageRequested>(PendingRideActionTypes.PendingRidesPageRequested),
            debounceTime(50),
            distinctUntilChanged(),
            switchMap(( { payload } ) => {
                this.store.dispatch(this.showPageLoadingDistpatcher);
                const requestToServer = this.service.getAllPendingRides(payload.page);
                // console.log(requestToServer);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                console.log(response);
                const result: QueryResultsModel = response[0];
                console.log(result);

                const lastQuery: QueryParamsModel = response[1];
                return new PendingRidesPageLoaded({
                    pendingRides: result.items,
                    totalCount: result.totalCount,
                    page: lastQuery
                });
            }),
        );

  constructor(private actions$: Actions, private service: RideReportService, private store: Store<AppState>) { }
}
