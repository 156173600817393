// Angular
import { Component, OnDestroy, OnInit, ChangeDetectorRef, Input, Output, OnChanges, EventEmitter } from '@angular/core';
// RxJS
import { Subscription } from 'rxjs';
import {
  QueryParamsModel,
} from "src/app/core/_base/crud";
// Layout
import { SubheaderService } from '../../../../../core/_base/layout';

@Component({
  selector: 'app-filterby-preview',
  templateUrl: './filterby-preview.component.html',
  styleUrls: ['./filterby-preview.component.scss']
})
export class FilterByPreviewComponent implements OnInit, OnDestroy, OnChanges {

  @Input() queryParms: QueryParamsModel;
  @Input() filterBy: any[];
  @Output("reload") reload: EventEmitter<any> = new EventEmitter();

  isAdvancedSearch: boolean = false;


	// Private properties
	private subscriptions: Subscription[] = [];

  constructor(private cdr: ChangeDetectorRef, private subheaderService: SubheaderService,) {
	}

	/**
	 * On init
	 */
	ngOnInit() {
    //console.log(this.queryParms);

    this.subscriptions.push(this.subheaderService.isAdvancedSearch$.subscribe(value => {
      // breadcrumbs title sometimes can be undefined
      //console.log(title);
      Promise.resolve(null).then(() => {
        this.isAdvancedSearch = value;
        this.cdr.detectChanges();
      });
    }));
	}

  ngOnChanges(): void {
    //console.log(this.filterBy);
  }

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		this.subscriptions.forEach(sb => sb.unsubscribe());
	}

  getActive(collection) {
    let selected = collection.value.filter(el => el.isChecked === true);
    if (selected && selected.length > 0 && ((collection.isAdvanced && this.isAdvancedSearch) || !collection.isAdvanced)) {
      return true;
    }
    return false;
  }
  clearFilter(item) {
    item.isChecked = false;
    this.cdr.detectChanges();
    this.reload.emit();
  }

}
