<form class="form-horizontal" [formGroup]="resetForm">

  <app-alert></app-alert>

  <div class="form-group mb-4">
    <label>
      New Password
      <a href="javascript:void(0)" class="ml-1" (click)="togglePassword(0)"><i class="fa fa-{{passwordType[0] === 'text' ? 'eye-slash' : 'eye'}}"></i></a>
    </label>
    <input id="passwordInput" type="{{passwordType[0]}}" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': isControlHasError('password') }" placeholder="Enter New Password" />
    <div *ngIf="isControlHasError('password')" class="invalid-feedback">
      <div *ngIf="isControlHasError('password','required')">
        Password is required
      </div>
      <div *ngIf="isControlHasError('password','pattern')">
        Password must be at least 8 characters in length with a combination of uppercase letters, lowercase letters, number and special characters.
      </div>
    </div>
  </div>

  <div class="form-group mb-4">
    <label>
      Confirm Password
      <a href="javascript:void(0)" class="ml-1" (click)="togglePassword(1)"><i class="fa fa-{{passwordType[1] === 'text' ? 'eye-slash' : 'eye'}}"></i></a>
    </label>
    <input id="confirmPasswordInput" type="{{passwordType[1]}}" formControlName="confirmPassword" class="form-control" [ngClass]="{ 'is-invalid': isControlHasError('confirmPassword') }" placeholder="Confirm Password" />
    <div *ngIf="isControlHasError('confirmPassword')" class="invalid-feedback">
      <div *ngIf="isControlHasError('confirmPassword','required')">
        Confirm password is required
      </div>
      <div *ngIf="resetForm.get('confirmPassword').errors && resetForm.get('confirmPassword').errors.ConfirmPassword">
        Confirm Password must match with password.
      </div>
    </div>
  </div>

  <div class="mt-3">
    <button id="confirmButton" class="btn btn-primary btn-block" (click)="onSubmit()" [disabled]="loading">
      Confirm
      <i *ngIf="loading" class="bx bx-loader bx-spin"></i>
    </button>
  </div>
</form>
