<div class="modal-header border-bottom-0">
  <button id="closeDialogButton" type="button" class="close" aria-hidden="true" (click)="closeDialog()">
    ×
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-12 pt-0 mt-0 pb-2">
      <!-- <div class="col-md-12">
        <app-alert></app-alert>
      </div> -->
      <div class="col-md-12">
        <div class="drop-area">
          <div class="upload-text" *ngIf="!tempUrl">
            <span>Drag and drop or click to upload</span>
          </div>
          <input type="file" title="" id="fileInputArea" accept="image/*" (change)="handleUploadEventDrop($event)" (click)="removeSelected($event)"/>
          <div class="image-box">
            <img src="{{tempUrl}}">
            <div class="remove-btn" (click)="removeImage()" *ngIf="tempUrl" ngbTooltip="Remove Image">
              <i class="fas fa-trash-alt text-danger font-size-13"></i>
            </div>
            <div class="update-btn" onclick="document.getElementById('fileInputArea').click()" *ngIf="tempUrl" ngbTooltip="Change Image">
              <span><i class="fas fa-pencil-alt text-success"></i></span>
            </div>
          </div>
        </div>
        <p>Maximum upload size is 10MB</p>
      </div>
      <div class="mt-3 col-md-12 text-right">
        <button id="cancelButton" type="button" class="btn btn-secondary w-sm mr-3" (click)="closeDialog()">
          Cancel
        </button>
        <button id="uploadButton" type="button" class="btn btn-primary w-sm" [disabled]="loading" (click)="onSubmit()">
          Upload
          <i *ngIf="loading" class="bx bx-loader bx-spin"></i>
        </button>
      </div>
    </div>
  </div>
</div>
