import { Zone } from '../../master-data/_models/zone.model';
import { City } from '../../master-data/_models/city.model';

export class Referral {
  _id: string;
  isPercentage: boolean;
  referralBonus: number;
  isDelete: boolean;
  zoneId: Zone;
  cityId: City;
  duration: string;
  settlementDuration: string;
  redemptionIsPercentage: boolean;
  redemption: number;
  redemptionBuffer: number;
  createdById: string;
  __v: number;

    clear(): void {
      this._id = undefined;
      this.isPercentage = true;
      this.referralBonus = 0;
      this.isDelete = false;
      this.zoneId = new Zone();
      this.zoneId.clear();
      this.cityId = new City();
      this.cityId.clear();
      this.duration = 'monthly';
      this.settlementDuration = 'last';
      this.redemptionIsPercentage = true;
      this.redemption = 0;
      this.redemptionBuffer = 5;
      this.createdById = '';
      this.__v = 0;
    }
}
