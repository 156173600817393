export class RideVerification {
  _id: string;
  rideId: string;
  isGenuine: boolean;
  isPromotionApplicable: boolean;
  isDriverPromotionApplicable: boolean;
  isWalletUsageApplicable: boolean;
  isSchCancelRewardApplicable: boolean;
  isCancellationChargeApplicable: boolean;
  isPickupChargeApplicable: boolean;
  isRiderGenuine: boolean;
  isDriverGenuine: boolean;
  misc: Misc;
  verifiedTime: string;
  verifiedBy: string;
  reason: string;
  comment: string;
  __v: number;


  clear(): void {
    this._id = undefined;
    this.rideId = undefined;
    this.isGenuine = false;
    this.isPromotionApplicable = false;
    this.isDriverPromotionApplicable = false;
    this.isWalletUsageApplicable = false;
    this.isSchCancelRewardApplicable = false;
    this.isCancellationChargeApplicable = false;
    this.isPickupChargeApplicable = false;
    this.isRiderGenuine = undefined;
    this.isDriverGenuine = undefined;
    this.misc = new Misc();
    this.misc.clear();
    this.verifiedTime = undefined;
    this.verifiedBy = undefined;
    this.reason = '';
    this.comment = '';
    this.__v = 0;
  }
}

export class Misc {
  genuinityPercentage: number;
  validations: any[];

  clear(): void {
    this.genuinityPercentage = 0;
    this.validations = [];
  }
}
