// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
// Actions
import { RiderActions, RiderActionTypes } from '../_actions/rider.actions';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { Rider } from '../_models/rider.model';
export interface RidersState extends EntityState<Rider> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedRiderId: string;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}
// export const adapter: EntityAdapter<Rider> = createEntityAdapter<Rider>();

export const adapter: EntityAdapter<Rider> = createEntityAdapter({
    selectId: (rider: Rider) => rider._id,
  });
export const initialRidersState: RidersState = adapter.getInitialState({
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastQuery:  new QueryParamsModel(),
    lastCreatedRiderId: undefined,
    showInitWaitingMessage: true
});
export function RidersReducer(state = initialRidersState, action: RiderActions): RidersState {
    switch  (action.type) {
        case RiderActionTypes.RidersPageToggleLoading: return {
            ...state, listLoading: action.payload.isLoading, lastCreatedRiderId: undefined
        };
        case RiderActionTypes.RidersActionToggleLoading: return {
            ...state, actionsloading: action.payload.isLoading
        };
        case RiderActionTypes.RidersPageCancelled: return {
            ...state, listLoading: false, lastQuery: new QueryParamsModel()
        };
        case RiderActionTypes.RidersPageLoaded: {
            return adapter.addMany(action.payload.riders, {
                ...initialRidersState,
                totalCount: action.payload.totalCount,
                lastQuery: action.payload.page,
                listLoading: false,
                showInitWaitingMessage: false
            });
        }
        default: return state;
    }
}


export const getRiderstate = createFeatureSelector<RidersState>('riders');
export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
