export class EstimatedFare {
  _id: string;
  rideDistance: number;
  rideDuration: number;
  fareAmount: number;
  driverBatta: number;
  surgeCharge: number;
  promotionDiscount: number;
  commission: number;
  pickUpCharge: number;
  reservationCharge: number;
  totalDiscount: number;
  rideFare: number;
  tax: number;
  due: number;
  __v: number;

  clear(): void {
    this._id = undefined;
    this.rideDistance = 0;
    this.rideDuration = 0;
    this.fareAmount = 0;
    this.driverBatta = 0;
    this.surgeCharge = 0;
    this.promotionDiscount = 0;
    this.commission = 0;
    this.pickUpCharge = 0;
    this.reservationCharge = 0;
    this.totalDiscount = 0;
    this.rideFare = 0;
    this.tax = 0;
    this.due = 0;
    this.__v = 0;
  }
}
