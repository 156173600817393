import { Zone } from './zone.model';
import { City } from './city.model';
import { PopularLocation } from './popular-location.model';

export class PopularPackage {
  _id: string;
  title: string;
  description: string;
  zoneId: string;
  cityId: string;
  spendingTime: number;
  locationArray: string[];
  locations: PopularLocation[];
  rideType: string; // ['rental', 'outStationOneWay', 'outStationRound'],
  imageId: string;
  sortOrder: number;
  isSuspend: boolean;
  isDelete: boolean;
  createdById: string;
  readOnly: boolean;
  zone: Zone;
  city: City;
  __v: number;

  clear(): void {
    this._id = undefined;
    this.zoneId = undefined;
    this.cityId = undefined;
    this.title = '';
    this.description = '';
    this.spendingTime = undefined;
    this.locationArray = [];
    this.locations = [];
    this.rideType = 'rental'; // ['rental', 'outStationOneWay', 'outStationRound'],
    this.imageId = '';
    this.sortOrder = 1;
    this.isSuspend = false;
    this.isDelete = false;
    this.createdById = '';
    this.readOnly = false;
    this.zone = new Zone();
    this.city = new City();
    this.__v = 0
  }
}
