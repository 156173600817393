// Angular
import { Component, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-driver-status-verify',
  templateUrl: './driver-status-verify.component.html',
  styleUrls: ['./driver-status-verify.component.scss']
})
export class DriverStatusVerifyComponent implements OnInit {
  // Public properties
  viewLoading: boolean = false;

  status: string = 'success';

  constructor(
    public dialogRef: MatDialogRef<DriverStatusVerifyComponent>,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    console.log(this.data);
    this.checkDriverMatch();
  }

  checkDriverMatch(){
    let matchPercentage = 0;
    if (this.data.driver?.status?.isActive === 'online') {
      matchPercentage += 10;
    } else if (this.data.driver?.status?.isActive === 'onTrip') {
      matchPercentage += 5;
    }else {
      matchPercentage += 0;
    }

    if (this.data.driver?.subscription?.status) {
      matchPercentage += 25;
    } else if (!this.data.driver?.subscription?.status && !this.data.driver?.subscription?.totalExpiryDate) {
      matchPercentage += 15;
    }else {
      matchPercentage += 0;
    }

    if (this.data.driver?.kycStatus === 'Partially Approved' || this.data.driver?.kycStatus === 'Approved') {
      matchPercentage += 40;
    } else if (this.data.driver?.kycStatus === 'Expired') {
      matchPercentage += 30;
    } else if (this.data.driver?.kycStatus === 'Rejected') {
      matchPercentage += 20;
    } else if (this.data.driver?.kycStatus === 'Pending') {
      matchPercentage += 10;
    } else {
      matchPercentage += 0;
    }

    if (this.data.driver?.vehicleType == this.data.rideDetails?.vehicleType || this.data.rideDetails?.vehicleGroupDetail?.vehicleTypes.includes(this.data.driver?.vehicleType)) {
      matchPercentage += 25;
    }else {
      matchPercentage += 0;
    }

    if (matchPercentage > 75) {
      this.status = 'success';
    } else if (matchPercentage > 50) {
      this.status = 'warning';
    } else {
      this.status = 'danger';
    }
    this.cdr.detectChanges();
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onConfirm(): void {
    this.viewLoading = true;
    setTimeout(() => {
      this.dialogRef.close(true); // Keep only this row
    }, 500);
  }

  getIcon(type) {
    switch (type) {
      case "danger":
        return 'fa-times';
        break;
      case "success":
        return 'fa-check';
        break;
      case "warning":
        return 'fa-exclamation';
        break;
      default:
        return 'fa-exclamation';
        break;
    }
  }
}
