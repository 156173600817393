// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { LogsState } from '../_reducers/logs.reducers';
import { each } from 'lodash-es';
import { Log } from '../_models/log.model';

export const selectLogsState = createFeatureSelector<LogsState>('logs');

export const selectLogById = (_id: string) => createSelector(
    selectLogsState,
    logsState => logsState.entities[_id]
);

export const selectLogsPageLoading = createSelector(
    selectLogsState,
    logsState => {
        return logsState.listLoading;
    }
);

export const selectLogsActionLoading = createSelector(
    selectLogsState,
    logsState => logsState.actionsloading
);

export const selectLastCreatedLogId = createSelector(
    selectLogsState,
    logsState => logsState.lastCreatedLogId
);

export const selectLogsPageLastQuery = createSelector(
    selectLogsState,
    logsState => logsState.lastQuery
);

export const selectLogsInStore = createSelector(
    selectLogsState,
    logsState => {
        const items: Log[] = [];
        each(logsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: Log[] = httpExtension.sortArray(items, logsState.lastQuery.sortField, logsState.lastQuery.sortOrder);
        return new QueryResultsModel(result, logsState.totalCount, '');

    }
);

export const selectLogsShowInitWaitingMessage = createSelector(
    selectLogsState,
    logsState => logsState.showInitWaitingMessage
);

export const selectHasLogsInStore = createSelector(
    selectLogsState,
    queryResult => {
        if (!queryResult.totalCount) {
            return false;
        }

        return true;
    }
);

