import { Injectable } from '@angular/core';
import { Observable, of} from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { debounceTime, delay, switchMap, tap, mergeMap, catchError, map } from "rxjs/operators";
import { environment } from '../../../../environments/environment';

import {
  LayoutUtilsService,
  MessageType
} from "src/app/core/_base/crud";

@Injectable({
  providedIn: "root"
})

export class OpenStreetMapService {

  constructor(private http: HttpClient,
              private layoutUtilService: LayoutUtilsService
  ) {}

  // Get placess suggetions from open street maps
  getPlacesList(query: string): Observable<any> {
    var url = 'https://nominatim.openstreetmap.org/search?q=' + query +'&format=json&accept-language=en&addressdetails=1&countrycodes=in';
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': 'http://localhost:4200/'
      })
    };
    return this.http.get(url, httpOptions).pipe(
      map((res: any) => {
        console.log(res);
        return res ?? [];
      }),
      catchError(err => {
        console.log(err);
        return [];
      })
    );
  }

}
