import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { Routes, RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { LocationStrategy } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
// Toast
import { ToastrModule } from 'ngx-toastr';
// Environment Variables
import { environment } from '../environments/environment';

import { NgbNavModule, NgbAccordionModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
// Material
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { from } from 'rxjs';
import { CoreModule } from "./core/core.module";
// Theme Module
import { ThemeModule } from './views/theme/theme.module';
// Partials
import { PartialsModule } from './views/partials/partials.module';
// Auth
import { AuthModule } from './views/pages/auth/auth.module';
import { AuthService } from './core/auth';
// NGX Permissions
import { NgxPermissionsModule } from 'ngx-permissions';
// NGRX /* important HashLocationStrategy,
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
// State
import { metaReducers, reducers } from './core/reducers';

// Firebase Cloud Messaging
import { MessagingService } from './core/fcm/_services/messaging.service';
import { AsyncPipe } from '@angular/common';

import { initializeApp } from 'firebase/app';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
const app = initializeApp(environment.firebaseConfig);
// const appCheck = initializeAppCheck(app, {
//   provider: new ReCaptchaV3Provider(environment.reCAPTCHASiteKey),
//   isTokenAutoRefreshEnabled: true
// });

import { HTTP_INTERCEPTORS } from '@angular/common/http';
// Services
import { InterceptService } from './core/_base/crud';

// Layout Services
import {
  LayoutConfigService,
  MenuConfigService,
  PageConfigService,
  SplashScreenService,
  SubheaderService
} from './core/_base/layout';

import {
  PwaService,
  HttpUtilsService
} from './core/_base/crud';

import { NgxDropzoneModule } from 'ngx-dropzone';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ConnectionServiceModule } from 'ng-connection-service';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CoreModule,
    AppRoutingModule,
    CarouselModule,
    NgbAccordionModule,
    NgbNavModule,
    NgbTooltipModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    CommonModule,
    RouterModule,
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgxPermissionsModule.forRoot(),
    PartialsModule,
    CoreModule,
    StoreModule.forRoot(reducers, {metaReducers}),
    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot({stateKey: 'router'}),
    StoreDevtoolsModule.instrument(),
    AuthModule.forRoot(),
    ScrollToModule.forRoot(),
    ToastrModule.forRoot({
      maxOpened: 3,
      newestOnTop: false,
      preventDuplicates: true,
      //countDuplicates: true,
      positionClass: 'toast-bottom-right',
      progressBar: true,
    }),
    ThemeModule,
    PartialsModule,
    NgxDropzoneModule,
    MatDialogModule,
    ConnectionServiceModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        hasBackdrop: true,
        panelClass: 'app-mat-dialog-container__wrapper',
        height: 'auto'
      }
    },
    InterceptService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true
    },
    // {
    //   provide: RECAPTCHA_V3_SITE_KEY,
    //   useValue: environment.reCAPTCHASiteKey,
    // },
    // ReCaptchaV3Service,
    PwaService,
    HttpUtilsService,
    AuthService,
    SplashScreenService,
    MenuConfigService,
    PageConfigService,
    SubheaderService,
    MessagingService,
    AsyncPipe
  ],
  bootstrap: [AppComponent],
  // schemas: [
  //   CUSTOM_ELEMENTS_SCHEMA
  // ],
})
export class AppModule { }
