import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class RideVerificationService {

  constructor(private http: HttpClient) { }

  weightage: any = {
    deviceSimilarity: 35,
    mobileNumberSimilarity: 30,
    customerSimilarity: 25,
    networkSimilarity: 10,
  }

  getGenuinity(item) {
    console.log(item);
    item.weightage = this.weightage[item.key] ?? 0;
    switch (item.key) {
      case 'deviceSimilarity':
        return this.getDeviceSimilarity(item);
      case 'mobileNumberSimilarity':
        return this.getMobileNumberSimilarity(item);
      case 'customerSimilarity':
        return this.getCustomerSimilarity(item);
      case 'networkSimilarity':
        return this.getNetworkSimilarity(item);
      default:
        return item;
    }
  }

  getDeviceSimilarity(item) {
    let deviceNameSimilarity = (this.levenshteinDistance(item.data[0]?.devicename, item.data[1]?.devicename) * 0.1);
    let deviceIdSimilarity = (item.data[0]?.deviceid == item.data[1]?.deviceid) ? 0.9 : 0;
    item.similarity = deviceNameSimilarity + deviceIdSimilarity;
    item.genuinityPercentage = (1 - item.similarity)*100;
    item.remark = (item.similarity > .9) ? 'Rider and driver application opening in same device to complete the ride.' : 'Rider and driver device similarity is normal.';
    return item;
  }

  getMobileNumberSimilarity(item) {
    item.similarity = this.levenshteinDistance(item.data[0]?.mobile, item.data[1]?.mobile);
    item.genuinityPercentage = (1 - item.similarity) * 100;
    item.remark = (item.similarity > .6) ? 'Rider and driver mobile number seems to be similar.' : 'Rider and driver mobile number similarity is normal.';
    return item;
  }

  getCustomerSimilarity(item) {
    item.similarity = this.levenshteinDistance(item.data[0]?.name, item.data[1]?.name);
    item.genuinityPercentage = (1 - item.similarity) * 100;
    item.remark = (item.similarity > .5) ? 'Rider and driver name seems to be similar.' : 'Rider and driver name similarity is normal.';
    return item;
  }

  getNetworkSimilarity(item) {
    item.similarity = (item.data[0]?.clientip4 == item.data[1]?.clientip4) ? 1 : 0 ;
    item.genuinityPercentage = (1 - item.similarity) * 100;
    item.remark = (item.similarity == 1) ? 'Rider and driver used same network (ip address) for completing the ride.' : 'Rider and driver network similarity is normal.';
    return item;
  }

  // Levenshtein Distance Function
  levenshteinDistance(a, b) {
    const an = a ? a.length : 0;
    const bn = b ? b.length : 0;
    if (an === 0) return bn;
    if (bn === 0) return an;

    const matrix = Array.from(Array(bn + 1), () => Array(an + 1).fill(0));

    for (let i = 0; i <= an; i++) matrix[0][i] = i;
    for (let j = 0; j <= bn; j++) matrix[j][0] = j;

    for (let j = 1; j <= bn; j++) {
      for (let i = 1; i <= an; i++) {
        const cost = a[i - 1] === b[j - 1] ? 0 : 1;
        matrix[j][i] = Math.min(
          matrix[j - 1][i] + 1,
          matrix[j][i - 1] + 1,
          matrix[j - 1][i - 1] + cost
        );
      }
    }
    const maxLen = Math.max(a.length, b.length);
    const normalizedLevDistance = (maxLen - matrix[bn][an]) / maxLen;
    return normalizedLevDistance;
  }

  // Jaro-Winkler Distance Function
  jaroWinkler(s1, s2) {
    let m = 0;

    if (s1?.length === 0 || s2?.length === 0) {
      return 0;
    }

    const range = Math.floor(Math.max(s1?.length, s2?.length) / 2) - 1;
    const s1Matches = new Array(s1?.length);
    const s2Matches = new Array(s2?.length);

    for (let i = 0; i < s1?.length; i++) {
      const low = i >= range ? i - range : 0;
      const high = i + range <= s2?.length ? i + range : s2?.length - 1;
      for (let j = low; j <= high; j++) {
        if (s1Matches[i] !== true && s2Matches[j] !== true && s1[i] === s2[j]) {
          ++m;
          s1Matches[i] = s2Matches[j] = true;
          break;
        }
      }
    }

    if (m === 0) {
      return 0;
    }

    let k = 0;
    let numTrans = 0;

    for (let i = 0; i < s1?.length; i++) {
      if (s1Matches[i] === true) {
        let j = k;
        while (s2Matches[j] === false) {
          ++j;
        }
        if (s1[i] !== s2[j]) {
          ++numTrans;
        }
        k = j + 1;
      }
    }

    const t = numTrans / 2;
    const jaro = (m / s1?.length + m / s2?.length + (m - t) / m) / 3;
    const p = 0.1;
    const l = Math.min(4, Math.max(0, [...Array(s1?.length)].filter((_, i) => s1[i] === s2[i])?.length));
    return jaro + l * p * (1 - jaro);
  }

  // Combine results into a comprehensive score
  similarityScore(name1, name2) {
    const levDistanceScore = this.levenshteinDistance(name1, name2);
    const jaroWinklerScore = this.jaroWinkler(name1, name2);
    // Combine the scores, giving 50% weight to each
    return (levDistanceScore + jaroWinklerScore) / 2;
  }

}
