export class RideTimeline {
  _id: string;
  rideId: string;
  eventType: string; // ['rideRequested', 'rideAccepted', 'rideRejected', 'rideCancelled', 'gotoPickup', 'startOTPSent', 'startRide', 'changeDestination', 'startWaiting', 'stopWaiting', 'dropOff ', 'paymentInitiated', 'paymentCompleted', 'driverRated', 'riderRated', 'setPrimaryDriver', 'requestDestination','locationStatusUpdate', 'driverReached']
  message: string;
  eventTime: string
  __v: number;

  clear(): void {
    this._id = undefined;
    this.rideId = undefined;
    this.eventType = ''; // ['rideRequested', 'rideAccepted', 'rideRejected', 'rideCancelled', 'gotoPickup', 'startOTPSent', 'startRide', 'changeDestination', 'startWaiting', 'stopWaiting', 'dropOff ', 'paymentInitiated', 'paymentCompleted', 'driverRated', 'riderRated', 'setPrimaryDriver', 'requestDestination','locationStatusUpdate', 'driverReached']
    this.message = '';
    this.eventTime = ''
    this.__v = 0;
  }
}
