import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// NGRX
import { select, Store } from '@ngrx/store';
// RxJS
import { Observable } from 'rxjs';
// State
import { AppState } from '../../../../core/reducers';
import { currentUser, Logout, User } from '../../../../core/auth';

import Swal from 'sweetalert2';

import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html'
})
export class ProfileComponent implements OnInit {

  user$: Observable<User>;
  user: User;

  constructor(private router: Router,
              private store: Store<AppState>) { }

  ngOnInit(): void {
    this.user$ = this.store.pipe(select(currentUser));
    this.user$.subscribe(data=>{
      this.user = data;
    })
  }

  /**
   * Logout the user
   */
  logout() {
    localStorage.removeItem(environment.userSession);
    this.store.dispatch(new Logout());
  }

  lockScreen() {
    localStorage.setItem(environment.userSession, JSON.stringify(this.user));
    this.store.dispatch(new Logout());
  }

}
