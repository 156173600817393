import { Zone, City } from "../../master-data";
import { UploadFile } from "../../upload/_models/uploadFile.model";

export class Promotion {
  _id: string;
  zoneId: string;
  zone: Zone;
  cityId: string;
  city: City;
  vehicleType: string[]; // Removed for now due to implementation complexity
  rideType: string; // rideNow, airport, rental, outStationOneWay, outStationRound, meterTaxi, halfDay, fullDay
  offerType: string; // ridePromotion, riderReferral, driverReferral, walletPromotion
  promotionName: string;
  promotionCode: string;
  promotionType: string; // percentage, flat
  promotionValue: number;
  maxValueUpto: number;
  minRideValue: number;
  promotionID: string;
  shortDesc: string;
  longDesc: string;
  promotionIconId: string;
  promotionImageId: string;
  promotionIcon: UploadFile;
  promotionImage: UploadFile;
  promotionStart: string;
  promotionEnd: string;
  totalCount: number;
  totalUsageCount: number;
  usageCount: number;
  customer: string;
  isActive: boolean;
  isDelete: boolean;
  createdById: string;
  isReadOnly: boolean;
  isDefault: boolean;
  isVisible: boolean;
  //
  isLocationBased: boolean;
  locationBoundary: {
    coordinates: any[],
    type: string
  };
  applicableLocation: string; // 'pickup', 'dropoff', 'both'
  isCustomerGroupPromotion: boolean;
  customerGroupList: string[];

  promotionExpiry: number;


  clear(): void {
    this._id = undefined;
    this.zoneId = '';
    this.zone = new Zone();
    this.zone.clear();
    this.cityId = undefined;
    this.city = new City();
    this.city.clear();
    this.vehicleType = [];
    this.rideType = '';
    this.offerType = '';
    this.promotionName = '';
    this.promotionCode = '';
    this.promotionType = '';
    this.promotionValue = 0;
    this.maxValueUpto = 0;
    this.minRideValue = 0;
    this.promotionID = undefined;
    this.shortDesc = '';
    this.longDesc = '';
    this.promotionIconId = undefined;
    this.promotionImageId = undefined;
    this.promotionIcon = new UploadFile();
    this.promotionIcon.clear();
    this.promotionImage = new UploadFile();
    this.promotionImage.clear();
    this.promotionStart = '';
    this.promotionEnd = '';
    this.totalCount = 0;
    this.totalUsageCount = 0;
    this.usageCount = 0;
    this.customer = '';
    this.isActive = false;
    this.isDelete = false;
    this.createdById = '';
    this.isReadOnly = false;
    this.isDefault = false;
    this.isVisible = false;
    //
    this.isLocationBased = false;
    this.locationBoundary = {
      coordinates: [],
      type: 'Polygon'
    };
    this.applicableLocation = 'both'; // 'pickup', 'dropoff', 'both'
    this.isCustomerGroupPromotion = false;
    this.customerGroupList = [];

    this.promotionExpiry = 0;
  }
}
