// Angular
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
// Material
import { MatDialog } from '@angular/material/dialog';
// Partials for CRUD
import { DropzoneUploadComponent } from '../../../../views/partials/crud/dropzone-upload/dropzone-upload.component';
import { BulkUploadComponent } from '../../../../views/partials/crud/bulk-upload/bulk-upload.component';

@Injectable({
	providedIn: 'root'
})
export class UploadPopupService {

	constructor(private dialog: MatDialog) {}

	// Show upload popup
	uploadPopup(imageUrl: string = '') {
		return this.dialog.open(DropzoneUploadComponent, {
			data: { imageUrl },
			width: '440px'
		});
	}

  // Show bulk upload popup
  bulkUpload(data) {
    return this.dialog.open(BulkUploadComponent, {
      data: data,
      width: '440px'
    });
  }
}
