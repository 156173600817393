import { Country } from './country.model';

export class Tax {
  _id: string;
  taxName: string;
  country: Country;
  taxPercentage: string[];
  createdById: string;
  isDelete: boolean;
  readOnly: boolean;

  clear(): void {
    this._id = undefined;
    this.taxName = '';
    this.country = new Country();
    this.country.clear();
    this.taxPercentage = [];
    this.createdById = undefined;
    this.isDelete = false;
    this.readOnly = false;
  }
}
