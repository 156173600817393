// Angular
import { Component, OnInit, Input, Output, OnDestroy, ChangeDetectorRef } from '@angular/core';// Angular
// RxJS
import { Subscription } from 'rxjs';
// Auth
import { LayoutUtilsService, MessageType } from '../../../../core/_base/crud/utils/layout-utils.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit, OnDestroy {

	// Public properties
	@Output() duration: any = {
		info: 0,
		other: 0,
	};

	@Output() message: any = {
		info: "",
		other: "",
	};

	@Output() type: string = 'danger';

	// Private properties
	private subscriptions: Subscription[] = [];

	constructor(private cdr: ChangeDetectorRef,
				private layoutService: LayoutUtilsService) {}

	ngOnInit(): void {
		this.subscriptions.push(this.layoutService.onAlertChanged$.subscribe(
			(alert: any) => {
				alert = Object.assign({}, {message: '', type: 'danger', duration: 0, showCloseButton: false}, alert);
				//console.log(alert);
				if (MessageType[alert.type] === 'info') {
					this.message.info = alert.message;
					this.duration.info = alert.duration;
				}else {
					this.message.other = alert.message;
					this.duration.other = alert.duration;
					this.type = MessageType[alert.type];
					// Set timer
					if (this.duration.other === 0) {
						return;
					}
					setTimeout(() => {
						this.closeAlert(MessageType[alert.type]);
					}, this.duration.other);
				}
        this.cdr.detectChanges();
			}
		));
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(sb => sb.unsubscribe());
	}

	closeAlert(type) {
		if (type === 'info') {
			this.message.info = '';
		} else {
			this.message.other = '';
		}
		this.cdr.markForCheck();
	}

}
