import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { RideReport } from '../_models/rideReport.model';
import { TripFareDetails } from '../_models/tripFareDetails.model';
import { TripFare } from "../../../core/trip-fare";

@Injectable({
  providedIn: "root",
})

export class RideDetailsMonitorService {
  rideDetails$ = new BehaviorSubject<RideReport>(new RideReport());
  tripFareDetails$ = new BehaviorSubject<TripFareDetails>(new TripFareDetails());
  commissionDetails$ = new BehaviorSubject<TripFare>(new TripFare());
  reloadRide$ = new BehaviorSubject<string>(null);
}
