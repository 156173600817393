import { QueryParamsModel } from './../../_base/crud/models/query-models/query-params.model';

// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, tap, delay, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { Observable, defer, of, forkJoin } from 'rxjs';
// NGRX
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store, select, Action } from '@ngrx/store';
// import { map, switchMap, catchError } from "rxjs/operators";
// CRUD
import { QueryResultsModel } from '../../_base/crud';
import { AppState } from '../../../core/reducers';

//Service
import { RiderService } from '../_services/rider.services';
import {
    RiderActionTypes,
    RidersPageRequested,
    RidersPageLoaded,
    RidersPageToggleLoading,
    RidersActionToggleLoading
} from '../_actions/rider.actions';

@Injectable()
export class RiderEffects {
    showPageLoadingDistpatcher = new RidersPageToggleLoading({ isLoading: true });
    hidePageLoadingDistpatcher = new RidersPageToggleLoading({ isLoading: false });

    showActionLoadingDistpatcher = new RidersActionToggleLoading({ isLoading: true });
    hideActionLoadingDistpatcher = new RidersActionToggleLoading({ isLoading: false });

    @Effect()
    loadRidersPage$ = this.actions$.pipe(
            ofType<RidersPageRequested>(RiderActionTypes.RidersPageRequested),
            debounceTime(50),
            distinctUntilChanged(),
            switchMap(( { payload } ) => {
                this.store.dispatch(this.showPageLoadingDistpatcher);
                const requestToServer = this.service.getAllRiders(payload.page);
                // console.log(requestToServer);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                console.log(response);
                const result: QueryResultsModel = response[0];
                console.log(result);

                const lastQuery: QueryParamsModel = response[1];
                return new RidersPageLoaded({
                    riders: result.items,
                    totalCount: result.totalCount,
                    page: lastQuery
                });
            }),
        );   

    constructor(private actions$: Actions, private service: RiderService, private store: Store<AppState>) { }
}