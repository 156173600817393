
export class Target {
  _id?: string;
  requested: number;
  accepted: number;
  rejected: number;
  completed: number;
  canceledByDriver: number;
  canceledByRider: number;
  completedRideTotalFare: number;
  meterTaxiInitiated: number;
  meterTaxiCompleted: number;
  meterTaxiTotalFare: number;
  month: string;
  year: string;
  zoneId: string;
  cityId: string;
  __v: number;

  clear(): void {
    this._id = undefined;
    this.requested = 0;
    this.accepted = 0;
    this.rejected = 0;
    this.completed = 0;
    this.canceledByDriver = 0;
    this.canceledByRider = 0;
    this.completedRideTotalFare = 0;
    this.meterTaxiInitiated = 0;
    this.meterTaxiCompleted = 0;
    this.meterTaxiTotalFare = 0;
    this.month = undefined;
    this.year = undefined;
    this.zoneId = undefined;
    this.cityId = undefined;
    this.__v = 0;
  }
}
