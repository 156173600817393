<div class="app-location-picker">
  <div class="appHeader bg-primary">
    <div class="left">
      <a id="closeDialogButton" href="javascript:void(0);" class="headerButton" (click)="onClose()">
        <!-- <mat-icon>arrow_back</mat-icon> -->
      </a>
    </div>
    <div class="pageTitle" style="text-transform: capitalize">
      Select Location
    </div>
  </div>

  <div class="map-container">
    <div class="search-box">
      <input
        id="searchInput"
        type="text"
        (keydown.enter)="$event.preventDefault()"
        placeholder="Search Location"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="off"
        type="text"
        #search
      />
      <div class="auto-complete-prediction" *ngIf="placesPrediction.length > 0">
        <div class="auto-complete-prediction-item" *ngFor="let item of placesPrediction" (click)="onPredictionSelect(item)">
          {{item?.display_name}}</div>
      </div>
      <!-- <mat-icon>search</mat-icon> -->
    </div>

    <agm-map
      [latitude]="location?.lat"
      [zoom]="zoom"
      [longitude]="location?.long"
      [styles]="styles"
      [disableDefaultUI]="true"
      language="en"
      [mapTypeId]="mapType"
    >
      <agm-marker
        [latitude]="location?.lat"
        [longitude]="location?.long"
        [markerDraggable]="true"
        (dragEnd)="markerDragEnd($event)"
        iconUrl="./assets/img/icon/map-marker.png"
        animation="BOUNCE"
      >
      </agm-marker>
    </agm-map>
  </div>

  <div class="float-button">
    <button id="proceedButton" class="btn btn-primary" (click)="onSave()">Proceed</button>
  </div>
</div>
