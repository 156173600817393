import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { inject } from "@angular/core/testing";
import { BehaviorSubject } from "rxjs";

// RxJS
import { Observable, of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
// CRUD
import { QueryParamsModel, QueryResultsModel } from "../../_base/crud";

import { environment } from "src/environments/environment";

import { Driver } from "../_models/driver.model";

const API_URL = environment.apiUrl + '/auth';

const API_URL_DRIVERS = environment.apiUrl + '/drivers';

@Injectable({
  providedIn: "root"
})

export class DriversService {
  constructor(private http: HttpClient) { }

  // Get all drivers with filter
  getAllDriver(queryParams: QueryParamsModel): Observable<any> {
    return this.http
      .get<any>(
        API_URL +
        '/management/alldriver?' +
        'pageSize=' +
        queryParams.pageSize +
        '&pageNumber=' +
        queryParams.pageNumber +
        '&sortField=' +
        queryParams.sortField +
        '&sortOrder=' +
        queryParams.sortOrder +
        '&searchText=' +
        queryParams.searchText +
        queryParams.filter
      )
      .pipe(
        mergeMap(res => {
          console.log(res);
          if (res.response.code === 200) {
            const result = {
              items: res.data.list,
              totalCount: res.data.totalCount
            };
            return of(result);
          } else {
            const result = {
              items: [],
              totalCount: 0
            };
            return of(result);
          }
        })
      );
  }

  // Export all drivers with filter
  exportAllDriver(queryParams: QueryParamsModel): Observable<any> {
    return this.http
      .get<any>(
        API_URL +
        '/management/alldriver/excel?' +
        'pageSize=' +
        queryParams.pageSize +
        '&pageNumber=' +
        queryParams.pageNumber +
        '&sortField=' +
        queryParams.sortField +
        '&sortOrder=' +
        queryParams.sortOrder +
        '&searchText=' +
        queryParams.searchText +
        queryParams.filter
      )
      .pipe(
        mergeMap(res => {
          console.log(res);
          return of(res);
        })
      );
  }

  // Get all drivers
  getAllDrivers() {
    return this.http.get(API_URL + '/management/alldriver');
  }

  // Get single driver
  getSingleDriver(_id) {
    return this.http.get(API_URL + '/management/driver?driverId=' + _id);
  }

  // Get single driver location track
  getSingleDriverLocation(query) {
    return this.http.get(API_URL_DRIVERS + '/driver/profile/location?' + query);
  }

  // Get driver action history
  getDriverActionHistory(queryParams: QueryParamsModel): Observable<any> {
    return this.http.get(API_URL + '/suspendreport?' +
      'pageSize=' + queryParams.pageSize +
      '&pageNumber=' + queryParams.pageNumber +
      '&sortField=' + queryParams.sortField +
      '&sortOrder=' + queryParams.sortOrder + queryParams.filter);
  }

  // Get accounts list
  getAccounts(queryParams: QueryParamsModel): Observable<any> {
    return this.http.get(API_URL_DRIVERS + '/driver/commission/earning/driver?' +
      'pageSize=' + queryParams.pageSize +
      '&pageNumber=' + queryParams.pageNumber +
      '&sortField=' + queryParams.sortField +
      '&sortOrder=' + queryParams.sortOrder + queryParams.filter);
  }

  // Invite driver email
  inviteDriver(data) {
    return this.http.post(API_URL + '/management/invite/email', data);
  }

  // Invite driver mobile
  inviteDriverSms(data) {
    return this.http.post(API_URL + '/management/invite/sms', data);
  }

  // Delete a driver
  deleteDriver(_id: string, reason: string, isDelete: boolean = true, tempMasterId: string = '') {
    return this.http.delete(API_URL + '/management/driver?driverId=' + _id + '&isDelete=' + isDelete + '&isDeleteReason=' + reason + '&tempMasterId=' + tempMasterId);
  }

  // Suspend a driver
  suspendDriver(driver) {
    return this.http.put(API_URL + '/management/driver/suspend', driver);
  }

  // Verify Driver
  verifyDriver(data: any): Observable<any> {
    return this.http.put(API_URL_DRIVERS + '/driver/profile/verify', data);
  }

  // Update driver vehicle
  addVehicle(vehicle) {
    return this.http.post(API_URL + '/management/driver/vehicle', vehicle);
  }

  // Update driver vehicle
  updateVehicle(vehicle) {
    return this.http.put(API_URL + '/management/driver/vehicle', vehicle);
  }

  // Get vehicle type list
  getVehicleTypeList(id) {
    return this.http.get(API_URL_DRIVERS + '/driver/vehicletypelist?driverId=' + id);
  }

  // Update driver profile
  updateDriverProfile(driver) {
    return this.http.put(API_URL + '/management/driver', driver);
  }

  // Update driver alternative numbers
  updateDriverNumber(number: any) {
    return this.http.put(API_URL + '/management/driver/number', number);
  }

  // Update driver type (make member => master)
  updateDriverType(data) {
    return this.http.put(API_URL + '/management/driver/driverType', data);
  }

  // Get CUG list
  getAllUpStreamDriver(queryParams: QueryParamsModel): Observable<any> {
    return this.http
      .get<any>(
        API_URL +
        "/management/driver/cug?" +
        "pageSize=" +
        queryParams.pageSize +
        "&pageNumber=" +
        queryParams.pageNumber +
        '&sortField=' +
        queryParams.sortField +
        '&sortOrder=' +
        queryParams.sortOrder +
        "&searchText=" +
        queryParams.searchText +
        queryParams.filter
      )
      .pipe(
        mergeMap(res => {
          console.log(res);
          if (res.response.code === 200) {
            const result = {
              items: res.data.list,
              extra: res.data.masterDriver,
              totalCount: res.data.totalCount
            };
            return of(result);
          } else {
            const result = {
              items: [],
              masterDriver: new Driver(),
              totalCount: 0
            };
            return of(result);
          }
        })
      );
  }

  // Get CUG list
  getAllCugMembers(query: string): Observable<any> {
    return this.http.get<any>(API_URL + "/management/driver/cug?" + query);
  }

  // Get drivet fare charge details
  getDriverFareCharge(cugLabel: string, vehicleType: string): Observable<any> {
    return this.http.get(API_URL + '/management/driver/driverfare?cugLabel=' + cugLabel + '&vehicleType=' + vehicleType);
  }

  // Get fare charge details of admin
  getAdminFareCharge(zoneId: string, cityId: string, vehicleType: string): Observable<any> {
    return this.http.get<any>(API_URL + '/management/driver/adminfare?zoneId=' + zoneId + '&cityId=' + cityId + '&vehicleType=' + vehicleType);
  }

  // Create driver fare
  createDriverfareCharge(fare) {
    return this.http.post(API_URL + '/management/driver/driverfare', fare);
  }

  // Update driver fare
  updateDriverfareCharge(fare) {
    return this.http.put(API_URL + '/management/driver/driverfare', fare);
  }

}
