<!-- breadcrumb item -->
<div class="row">
  <div class="col-12">
    <div class="page-title-box d-flex align-items-center justify-content-between">
      <h4 class="mb-0 font-size-18">{{ title }}</h4>
      <!-- <div class="page-title-right">
        <ol class="breadcrumb m-0">
          <ng-container *ngFor="let item of breadcrumbs">
            <li class="breadcrumb-item" *ngIf="item.page">
              <a href="javascript: void(0);" [routerLink]="item.page">{{ item.title }}</a>
            </li>
            <li class="breadcrumb-item active" *ngIf="!item.page">{{ item.title }}
            </li>
          </ng-container>
        </ol>
      </div> -->
      <div class="page-title-right m-0" *ngIf="showAdvancedSearch">
        <div class="toggle-group">
          <i class="bx bx-info-circle information-icon" [ngbTooltip]="'Toggle the button to activate advanced search options.'"></i>
          <label class="toggle">
            <input type="checkbox" [(ngModel)]="isAdvancedSearch" (change)="onAdvancedChange()" />
            <span class="slider"></span>
            <!-- label element 👇 -->
            <span class="labels" data-on="ADVANCED" data-off="NORMAL"></span>
          </label>
        </div>
      </div>
    </div>
  </div>
</div>
