import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Charge } from '../_models/charge.model';

@Injectable({
  providedIn: "root",
})

export class TripFareMonitorService {
  chargeDetails$ = new BehaviorSubject<Charge>(new Charge());
  savedDetails$ = new BehaviorSubject<Charge>(new Charge());
  updatedFareDetails$ = new BehaviorSubject<any>(undefined);
  reloadFareDetails$ = new BehaviorSubject<boolean>(false);
}
