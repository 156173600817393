// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { PromotionSettlementState } from '../_reducers/promotionSettlement.reducers';
import { each } from 'lodash-es';
import { PromotionSettlement } from '../_models/promotionSettlement.model';


export const selectPromotionSettlementState = createFeatureSelector<PromotionSettlementState>('promotionSettlements');

export const selectPromotionSettlementById = (promotionSettlementId: string) => createSelector(
    selectPromotionSettlementState,
    promotionSettlementState => promotionSettlementState.entities[promotionSettlementId]
);

export const selectPromotionSettlementPageLoading = createSelector(
    selectPromotionSettlementState,
    promotionSettlementState => {
        return promotionSettlementState.listLoading;
    }
);

export const selectPromotionSettlementActionLoading = createSelector(
    selectPromotionSettlementState,
    promotionSettlementState => promotionSettlementState.actionsloading
);

export const selectLastCreatedPromotionSettlementId = createSelector(
    selectPromotionSettlementState,
    promotionSettlementState => promotionSettlementState.lastCreatedPromotionSettlementId
);

export const selectPromotionSettlementPageLastQuery = createSelector(
    selectPromotionSettlementState,
    promotionSettlementState => promotionSettlementState.lastQuery
);

export const selectPromotionSettlementInStore = createSelector(
    selectPromotionSettlementState,
    promotionSettlementState => {
        const items: PromotionSettlement[] = [];
        each(promotionSettlementState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: PromotionSettlement[] = httpExtension.sortArray(items, promotionSettlementState.lastQuery.sortField, promotionSettlementState.lastQuery.sortOrder);
        return new QueryResultsModel(result, promotionSettlementState.totalCount, '');
    }
);

export const selectPromotionSettlementShowInitWaitingMessage = createSelector(
    selectPromotionSettlementState,
    promotionSettlementState => promotionSettlementState.showInitWaitingMessage
);

export const selectHasPromotionSettlementInStore = createSelector(
    selectPromotionSettlementState,
    queryResult => {
        if (!queryResult.totalCount) {
            return false;
        }
        return true;
    }
);
