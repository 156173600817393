<div class="global-search-bar">
  <mat-progress-bar class="progress-bar-top" *ngIf="checkGlobalLoading()" mode="indeterminate"></mat-progress-bar>
  <perfect-scrollbar class="h-100">
    <div class="rightbar-title px-3 py-4">
      <a id="hideActionLink" href="javascript:void(0);" class="global-search-bar-toggle float-right" (click)="hide();">
        <i class="mdi mdi-close noti-icon"></i>
      </a>
      <h5 class="m-0">Advanced Global Search</h5>
    </div>
    <hr class="mt-0">
    <div class="col-lg-12">
      <div class="row">
        <div class="col-lg-4">
          <div class="search-box-area">
            <form>
              <div class="row">
                <div class="col-lg-12">
                  <app-alert></app-alert>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-9">
                  <div class="search-box d-inline-block" style="width: 100%;">
                    <div class="position-relative">
                      <i class="bx bx-search-alt search-icon font-size-16"></i>
                      <input #globalSearchInput [(ngModel)]="searchText" name="globalSearchTextInput" autofocus
                        placeholder="Global Search..." type="text" class="form-control font-size-12"
                        autocomplete="off" />
                      <i class="bx bx-info-circle information-icon"
                        [ngbTooltip]="'Search for drivers, riders, rides, kyc document etc globally'"></i>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3">
                  <!-- [disabled]="!(searchText.length > 3)" -->
                  <button id="globalSearchButton" type="submit" class="btn btn-primary"
                    style="width: 100% !important;" (click)="search()">
                    Search
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12 mt-3">
                  <h6>Expand Search in</h6>
                  <hr />
                  <div class="custom-control custom-checkbox mt-2">
                    <input id="searchInAll" [(ngModel)]="searchInAll" name="searchInAll"
                      (change)="toggleAll($event.target.checked)" type="checkbox" class="custom-control-input" />
                    <label class="custom-control-label" for="searchInAll">All Records</label>
                  </div>
                  <div *ngFor="let item of expandedSearch" class="custom-control custom-checkbox mt-2">
                    <input [(ngModel)]="item.value" name="{{ 'searchIn-'+item.key }}" id="{{ 'searchIn-'+item.key }}"
                      type="checkbox" class="custom-control-input" />
                    <label class="custom-control-label" for="{{ 'searchIn-'+item.key }}">{{ item.label }}</label>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="col-lg-8">
          <h5>Search Result</h5>
          <div class="search-result-area">
            <div class="row" *ngIf="!checkDataAvailable()">
              <div class="col-12">
                <ng-container *ngFor="let searchItem of expandedSearch">
                  <div class="custom-card mb-4" *ngIf="searchItem.totalCount > 0">
                    <div class="card-body">
                      <h4 class="card-title font-size-15 mb-4">
                        {{ searchItem.title }}
                      </h4>

                      <div class="row">
                        <div class="col-lg-12">
                          <div class="table-responsive mt-3">
                            <table class="table table-centered table-nowrap">
                              <thead>

                                <!-- Tabel header for driver document list -->
                                <tr *ngIf="searchItem.key == 'document'">
                                  <th>Document Number</th>
                                  <th>Driver Details</th>
                                  <th>KYC Status</th>
                                  <th>Upload Date</th>
                                  <th>Expiry Date</th>
                                  <th>Status</th>
                                </tr>

                                <!-- Tabel header for driver list -->
                                <tr *ngIf="searchItem.key == 'driver'">
                                  <th>Driver ID</th>
                                  <th>Details</th>
                                  <th>KYC Status</th>
                                  <th>Subscription Status</th>
                                  <th>Rating</th>
                                  <th>Joining Date</th>
                                  <th>Status</th>
                                </tr>

                                <!-- Table header for rider list -->
                                <tr *ngIf="searchItem.key == 'rider'">
                                  <th>Rider ID</th>
                                  <th>Details</th>
                                  <th>Rating</th>
                                  <th>Joining Date</th>
                                  <th>Status</th>
                                </tr>

                                <!-- Table header for ride list -->
                                <tr *ngIf="searchItem.key == 'ride'">
                                  <th>Date</th>
                                  <th>Ride ID</th>
                                  <th>Driver</th>
                                  <th>Rider</th>
                                  <th>Ride Fare</th>
                                  <th>Status</th>
                                </tr>

                              </thead>
                              <tbody *ngFor="let customers of searchItem.items; let i = index">
                                <tr *ngIf="searchItem.key == 'document'">
                                  <td>
                                    <a href="javascript:void(0)" (click)="hide()" [routerLink]="'/kyc-management/details/view/'+customers?.driver?._id">{{customers?.documentNumber | uppercase}}</a>
                                    <p class="mb-0 font-size-12">{{ customers.documentName }}</p>
                                  </td>
                                  <td>
                                    <h6 class="mb-1 font-size-13">{{ (customers?.driver?.name | titlecase) || "-" }}</h6>
                                    <h6 class="mb-1 font-size-12">
                                      {{
                                      customers?.driver?.mobileNumber?.length > 0
                                      ? getPrimaryNumber(customers?.driver?.mobileNumber)
                                      : "-"
                                      }}
                                      <app-initiate-call [id]="customers?.driver?._id" [userType]="'driver'">
                                      </app-initiate-call>
                                    </h6>
                                    <p class="mb-0 font-size-12">
                                      {{ customers?.driver?.email || "-" }}
                                    </p>
                                  </td>
                                  <td>
                                      <span class="badge badge-pill badge-soft-success font-size-13" [ngClass]="{
                                                                                          'badge-soft-danger':
                                                                                            customers?.driver?.kycStatus === 'Rejected' ||
                                                                                            customers?.driver?.kycStatus === 'Expired',
                                                                                          'badge-soft-warning': customers?.driver?.kycStatus === 'Pending',
                                                                                          'badge-soft-info':
                                                                                            customers?.driver?.kycStatus === 'Not Uploaded',
                                                                                          'badge-warning':
                                                                                            customers?.driver?.kycStatus === 'Partially Approved'
                                                                                        }">
                                        <!-- Online -->
                                        {{ customers?.driver?.kycStatus }}
                                      </span>
                                  </td>
                                  <td>{{ formatTime(customers?.uploadDate, true) }}</td>
                                  <td>{{ formatTime(customers?.expiryDate, true) }}</td>
                                  <td>
                                    <span class="badge badge-pill badge-soft-success font-size-13" [ngClass]="{
                                        'badge-soft-danger':
                                          customers?.admin?.softCopy?.status === 'Rejected' ||
                                          customers?.admin?.softCopy?.status === 'Expired',
                                        'badge-soft-warning': customers?.admin?.softCopy?.status === 'Pending',
                                        'badge-soft-info':
                                          customers?.admin?.softCopy?.status === 'Not Uploaded',
                                        'badge-warning':
                                          customers?.admin?.softCopy?.status === 'Approved'
                                      }">
                                      <!-- Online -->
                                      {{ customers?.admin?.softCopy?.status }}
                                    </span>
                                    <span *ngIf="customers?.isInvalid" class="badge badge-pill badge-warning font-size-13">Invalid</span>
                                  </td>
                                </tr>

                                <tr *ngIf="searchItem.key == 'driver'">
                                  <td>
                                    <a href="javascript:void(0)" (click)="hide()" [routerLink]="'/driver-management/details/view/'+customers?._id">{{ customers?.driverID
                                      }}</a>
                                  </td>
                                  <td>
                                    <h6 class="mb-1 font-size-13">{{ (customers?.name | titlecase) || "-" }}</h6>
                                    <h6 class="mb-1 font-size-12">
                                      {{
                                      customers?.mobileNumber.length > 0
                                      ? getPrimaryNumber(customers?.mobileNumber)
                                      : "-"
                                      }}
                                      <app-initiate-call [id]="customers?._id" [userType]="'driver'">
                                      </app-initiate-call>
                                    </h6>
                                    <p class="mb-0 font-size-12">
                                      {{ customers?.email || "-" }}
                                    </p>
                                  </td>
                                  <td>
                                    <ng-template ngxPermissionsOnly="kycManagement">
                                      <span (click)="viewKycDetails(customers?._id)" class="badge badge-pill badge-soft-success font-size-13"
                                        style="cursor: pointer;" [ngClass]="{
                                                          'badge-soft-danger':
                                                            customers?.kycStatus === 'Rejected' ||
                                                            customers?.kycStatus === 'Expired',
                                                          'badge-soft-warning': customers?.kycStatus === 'Pending',
                                                          'badge-soft-info':
                                                            customers?.kycStatus === 'Not Uploaded',
                                                          'badge-warning':
                                                            customers?.kycStatus === 'Partially Approved'
                                                        }">
                                        <!-- Online -->
                                        {{ customers.kycStatus }}
                                      </span>
                                    </ng-template>
                                    <ng-template [ngxPermissionsExcept]="['kycManagement']">
                                      <span class="badge badge-pill badge-soft-success font-size-13" [ngClass]="{
                                                          'badge-soft-danger':
                                                            customers?.kycStatus === 'Rejected' ||
                                                            customers?.kycStatus === 'Expired',
                                                          'badge-soft-warning': customers?.kycStatus === 'Pending',
                                                          'badge-soft-info':
                                                            customers?.kycStatus === 'Not Uploaded',
                                                          'badge-warning':
                                                            customers?.kycStatus === 'Partially Approved'
                                                        }">
                                        <!-- Online -->
                                        {{ customers.kycStatus }}
                                      </span>
                                    </ng-template>
                                  </td>
                                  <td>
                                    <span class="badge badge-pill badge-soft-success font-size-11" [ngClass]="{
                                                        'badge-soft-danger': !customers?.subscription?.status && customers?.subscription?.totalExpiryDate,
                                                        'badge-soft-warning': !customers?.subscription?.status && !customers?.subscription?.totalExpiryDate,
                                                        'badge-soft-success': customers?.subscription?.status
                                                      }">
                                      <!-- Online -->
                                      {{ customers?.subscription?.status ? 'Active' : (customers?.subscription?.totalExpiryDate ? 'Expired' : 'Inactive')
                                      }}
                                    </span>
                                    <p class="mb-0 font-size-11 mt-1" *ngIf="customers?.subscription?.totalExpiryDate">
                                      Expiry Date: {{
                                      formatTime(customers?.subscription?.totalExpiryDate, true) || "-"
                                      }}
                                    </p>
                                  </td>
                                  <td>
                                    <span class="badge badge-primary font-size-13">
                                      <i class="mdi mdi-star mr-1 yellow"></i>
                                      {{ customers?.rating | number: "1.1-1" }}
                                      <!-- 4 -->
                                    </span>
                                  </td>
                                  <td>{{ formatTime(customers?.dateOfJoining, true) }}</td>
                                  <td>
                                    <span class="font-size-13" [ngClass]="{
                                                        suspended: customers?.status?.suspend?.isSuspend || customers?.isDefaulter
                                                      }">
                                      <span [ngClass]="{
                                                          online: customers?.status?.isActive === 'online',
                                                          offline: customers?.status?.isActive === 'offline',
                                                          ontrip: customers?.status?.isActive === 'onTrip'
                                                        }" *ngIf="!customers?.status?.suspend?.isSuspend &&  !customers?.isDefaulter">
                                      </span>
                                      <span *ngIf="!customers?.status?.suspend?.isSuspend && !customers?.isDefaulter">
                                        {{ customers?.status?.isActive | titlecase }}
                                      </span>
                                      <span *ngIf="customers?.status?.suspend?.isSuspend || customers?.isDefaulter">
                                        {{ customers?.isDefaulter ? "Defaulter" : "Suspended" }}
                                      </span>
                                    </span>
                                  </td>
                                </tr>

                                <tr *ngIf="searchItem.key == 'rider'">
                                  <td><a href="javascript:void(0)"  (click)="hide()" [routerLink]="'/rider-management/details/view/'+customers?._id">{{ customers?.riderID
                                      }}</a></td>
                                  <td>
                                    <h6 class="mb-1 font-size-13">{{ (customers?.name | titlecase) || "-" }}</h6>
                                    <h6 class="mb-1 font-size-12">
                                      {{ formatMobileNumber(customers?.mobileNumber) }}
                                      <app-initiate-call [id]="customers?._id" [userType]="'rider'">
                                      </app-initiate-call>
                                    </h6>
                                    <p class="mb-0 font-size-12">
                                      {{ customers?.email || "-" }}
                                    </p>
                                  </td>
                                  <td>
                                    <span class="badge badge-primary font-size-14">
                                      <i class="mdi mdi-star mr-1 yellow"></i>
                                      {{ customers?.rating | number : '1.1-1' }}
                                    </span>
                                  </td>
                                  <td>{{ formatTime(customers?.dateOfJoining, true) }}</td>
                                  <td>
                                    <span class="badge badge-pill badge-success font-size-13" [ngClass]="{
                                                        'badge-danger': customers.status?.admin.isSuspend === true
                                                      }">
                                      <span [ngClass]="{
                                                          Active: customers.status?.admin.isSuspend === false,
                                                          Suspended: customers.status?.admin.isSuspend === true
                                                        }">
                                      </span>
                                      {{ (customers.status?.admin.isSuspend === true) ? 'Suspended':'Active' }} </span>
                                  </td>
                                </tr>

                                <tr *ngIf="searchItem.key == 'ride'">
                                  <td>{{ formatTime(customers.rideTime) }}</td>
                                  <td>
                                    <ng-template ngxPermissionsOnly="rideReportsManagementView">
                                      <a href="javascript:void(0);"  (click)="hide()" [routerLink]="'/ride-reports/details/'+customers._id">{{ customers.tripId |
                                        uppercase }}</a>
                                    </ng-template>
                                    <ng-template [ngxPermissionsExcept]="['rideReportsManagementView']">
                                      {{ customers.tripId | uppercase }}
                                    </ng-template>
                                  </td>
                                  <td>
                                    <h6 class="mb-1 font-size-13">
                                      {{ (customers.driverId?.name | titlecase) || "-" }}
                                    </h6>
                                    <p class="mb-0 font-size-10">
                                      {{
                                      formatMobileNumber(customers.driverId?.mobileNumber[0]) ||
                                      "-"
                                      }}
                                      <app-initiate-call *ngIf="customers.driverId?._id" [id]="customers.driverId?._id" [userType]="'driver'">
                                      </app-initiate-call>
                                    </p>
                                  </td>
                                  <td>
                                    <h6 class="mb-1 font-size-13">
                                      {{ (customers.riderId?.name | titlecase) || "-" }}
                                    </h6>
                                    <p class="mb-0 font-size-10">
                                      {{
                                      formatMobileNumber(customers.riderId?.mobileNumber) || "-"
                                      }}
                                      <app-initiate-call [id]="customers.riderId?._id" [userType]="'rider'">
                                      </app-initiate-call>
                                    </p>
                                  </td>
                                  <td>
                                    <i class="bx bx-rupee"></i>
                                    {{ (customers.rideFare || 0) | number : '1.2-2' }}
                                  </td>
                                  <td>
                                    <span class="badge badge-pill badge-success font-size-12 mr-1 mb-1" [ngClass]="{
                                                      'badge-danger': customers.rideStatus === 'cancelled' || customers.rideStatus === 'scheduleCancel',
                                                      'badge-warning': (customers.rideStatus === 'upComing' || customers.rideStatus === 'scheduled'),
                                                      'badge-info': customers.rideStatus === 'onGoing',
                                                      'badge-success': customers.rideStatus === 'completed'
                                                    }">{{ getStatusLabel(customers) | titlecase }}</span>

                                    <span class="badge badge-pill badge-success font-size-10 mr-1 mb-1" [ngClass]="{
                                                      'badge-danger': getVerificationStatus(customers) == 'Fake',
                                                      'badge-warning': getVerificationStatus(customers) == 'Not Verified',
                                                      'badge-success': getVerificationStatus(customers) == 'Genuine'
                                                    }">{{ getVerificationStatus(customers) | titlecase }}</span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <!-- pagination -->
                        <div class="col-lg-6">
                          <!-- pagination counter -->
                          <app-page-counter [direction]="'asc'" [collectionSize]="searchItem.totalCount"
                            [pageSize]="searchItem.pageSize" [page]="searchItem.pageNumber">
                          </app-page-counter>
                        </div>
                        <div class="col-lg-6">
                          <!-- paginator -->
                          <app-paginator [collectionSize]="searchItem.totalCount" [pageSize]="searchItem.pageSize"
                            [(page)]="searchItem.pageNumber" (reload)="search(searchItem.key)">
                          </app-paginator>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="no-record" *ngIf="!searchInitiated && checkDataAvailable()">
              <img src="assets/images/no-result.png" alt="" />
              <h4>Add Keyword to Search</h4>
              <p>Please add a keyword in global search input to intiate a search</p>
            </div>
            <div class="no-record" *ngIf="searchInitiated && checkDataAvailable()">
              <img src="assets/images/no-result.png" alt="" />
              <h4>No Result Found</h4>
              <p>No content: There may not be any record about the topic being searched for</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </perfect-scrollbar>
</div>
<!-- /Right-bar -->

<!-- Right bar overlay-->
<!-- <div class="rightbar-overlay"></div> -->
