// Angular
import { Injectable } from '@angular/core';
import { QueryParamsModel } from "src/app/core/_base/crud";

@Injectable({
  providedIn: 'root'
})

export class FilterConfigService {

  constructor() { }

  updateFilterByFromQuery(filterBy: any, query: QueryParamsModel) {
    let filterByList = filterBy;
    filterByList.forEach(collection => {
      collection?.value?.forEach(element => {
        element.isChecked = false;
      });
    })
    filterByList.forEach(collection => {
      this.getQueryVariable(query.filter, collection.key).forEach(value => {
        console.log(value);
        collection.value?.forEach(item => {
          if (item.value === value) {
            item.isChecked = true;
          }
        })
      })
    })
    return filterByList;
  }

  updateQueryStringFromFilterBy(filterBy: any) {
    let queryString = '';
    filterBy.forEach(collection => {
      let arrayItems = [];
      collection?.value?.forEach(element => {
        if (element.isChecked) {
          arrayItems.push(element.value);
        }
      });
      if (arrayItems.length > 0) {
        queryString = queryString + '&' + collection.key + '=' + arrayItems.join();
      }
    })
    return queryString;
  }

  getQueryVariable(query, variable) {
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      if (decodeURIComponent(pair[0]) == variable) {
        return decodeURIComponent(pair[1]).split(",");
      }
    }
    return [];
  }

  checkQueryString(query) {
    let queryString = '';
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
      if (vars[i].length > 0) {
        var pair = vars[i].split('=');
        if (decodeURIComponent(pair[1]).length > 0) {
          queryString = queryString + '&' + decodeURIComponent(pair[0]) + '=' + decodeURIComponent(pair[1]);
        }
      }
    }
    return queryString;
  }
}
