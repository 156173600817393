import { Injectable, PipeTransform } from "@angular/core";

import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import { debounceTime, delay, switchMap, tap, mergeMap } from "rxjs/operators";

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { QueryParamsModel } from '../../_base/crud';

const API_URL = environment.apiUrl+'/settings';

@Injectable({
  providedIn: "root",
})

export class ReasonsService {

  constructor(private http: HttpClient) {}

  // Get all reasons
  getAllReasons(): Observable<any>{
    return this.http.get(API_URL+'/reason');
  }

  // Get single reason
  getReason(type: string): Observable<any>{
    return this.http.get(API_URL+'/reason?reasonType='+type);
  }

  // Add new reason
  addReason(reason: any): Observable<any>{
    return this.http.post(API_URL+'/reason', reason);
  }

  // Update reason
  updateReason(reason: any): Observable<any>{
    return this.http.put(API_URL+'/reason', reason);
  }

  // Delete reason
  deleteReason(reason: any): Observable<any>{
    return this.http.delete(API_URL+'/reason?_id=' + reason._id+'&isDelete='+reason.isDelete);
  }

}
