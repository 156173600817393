import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Rider } from '../_models/rider.model';
import { QueryParamsModel } from '../../_base/crud';

export enum RiderActionTypes {
    AllRidersRequested = '[Riders Module] All Riders Requested',
    AllRidersLoaded = '[Riders API] All Riders Loaded',
    RidersPageRequested = '[Riders List Page] Riders Page Requested',
    RidersPageLoaded = '[Riders API] Riders Page Loaded',
    RidersPageCancelled = '[Riders API] Riders Page Cancelled',
    RidersPageToggleLoading = '[Riders] Riders Page Toggle Loading',
    RidersActionToggleLoading = '[Riders] Riders Action Toggle Loading'
}

export class RidersPageRequested implements Action {
    readonly type = RiderActionTypes.RidersPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class RidersPageLoaded implements Action {
    readonly type = RiderActionTypes.RidersPageLoaded;
    constructor(public payload: { riders: Rider[], totalCount: number, page: QueryParamsModel  }) { }
}

export class RidersPageCancelled implements Action {
    readonly type = RiderActionTypes.RidersPageCancelled;
}
export class RidersPageToggleLoading implements Action {
    readonly type = RiderActionTypes.RidersPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class RidersActionToggleLoading implements Action {
    readonly type = RiderActionTypes.RidersActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}


export type RiderActions = RidersPageLoaded
| RidersPageCancelled
| RidersPageRequested
| RidersPageToggleLoading
| RidersActionToggleLoading;

