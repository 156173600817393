// NGRX
import { Action } from '@ngrx/store';
// CRUD
import { TripFare } from '../_models/tripFare.model';
// Models
import { QueryParamsModel } from '../../_base/crud';

export enum TripFareActionTypes {
    AllTripFareRequested = '[TripFare Module] All TripFare Requested',
    AllTripFareLoaded = '[TripFare API] All TripFare Loaded',
    TripFarePageRequested = '[TripFare List Page] TripFare Page Requested',
    TripFarePageLoaded = '[TripFare API] TripFare Page Loaded',
    TripFarePageCancelled = '[TripFare API] TripFare Page Cancelled',
    TripFarePageToggleLoading = '[TripFare] TripFare Page Toggle Loading',
    TripFareActionToggleLoading = '[TripFare] TripFare Action Toggle Loading'
}

export class TripFarePageRequested implements Action {
    readonly type = TripFareActionTypes.TripFarePageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class TripFarePageLoaded implements Action {
    readonly type = TripFareActionTypes.TripFarePageLoaded;
    constructor(public payload: { tripFare: TripFare[], totalCount: number, page: QueryParamsModel  }) { }
}


export class TripFarePageCancelled implements Action {
    readonly type = TripFareActionTypes.TripFarePageCancelled;
}

export class TripFarePageToggleLoading implements Action {
    readonly type = TripFareActionTypes.TripFarePageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class TripFareActionToggleLoading implements Action {
    readonly type = TripFareActionTypes.TripFareActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type TripFareActions = TripFarePageLoaded
| TripFarePageCancelled
| TripFarePageToggleLoading
| TripFarePageRequested
| TripFareActionToggleLoading;
