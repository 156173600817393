// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

//var pjson = require('../../package.json');

export const environment = {
  production: false,
  envName: 'dev',
  versionName: '4.8.7' + '-DEV',
  versionCode: 49,
  authTokenKey: 'autht0987654321asdfghjkl',
  userSession: 'users0987654321asdfghjkl',
  encryptKey: 1203199320052021,
  encryptIV: 1203199320052021,
  clientIpv4: 'clientIpv40987654321asdfghjkl',
  clientMac: 'clientMac0987654321asdfghjkl',
  razorPayKey: 'rzp_test_ROP5kg4BrRfTUq',
  reCAPTCHASiteKey: '6LeOIecfAAAAAP_ccAWcjZJuPEy0TqHbDatnM2FI',
  googlePlacesRestriction: ['IN', 'CA'],
  googleApiKey: 'AIzaSyAciCkBw7q2YzA6C10ykDwcISjT8W7PZ5c',
  vapidKey: 'BJGZ9x2Qm_XibkVfjULE7CnRqbvgcW9IgtSDYLxg4ZryTkm9RJ_vTXgv2ebI3d-DoNCw12GdemYxerVbcyws9bg',
  invoiceUrl: 'https://dev.receipt.dotstaxi.com',
  apiUrl: 'https://development.backend.viadots.com',

  // Exotel
  exotelSid: 'dotstaxi1',
  exotelSubdomain: 'ccm-api.exotel.com',
  exotelSubdomainV1: 'api.exotel.com',
  exotelApiKey: '98fc0e8fca44f31478471dee16029320988d51a303dbe673',
  exotelToken: 'a21ff48149545326e7df2d95a91a3800b89e0e6bd19d9974',
  exotelRiderCallerId: '+918047363647',
  exotelDriverCallerId: '+918047363534',

  firebaseConfig: {
    apiKey: "AIzaSyAp0eAYAoY_D0ag157z8dCQfwP2PEo9cRA",
    authDomain: "dots-taxi-dev.firebaseapp.com",
    databaseURL: "https://dots-taxi-dev.firebaseio.com",
    projectId: "dots-taxi-dev",
    storageBucket: "dots-taxi-dev.appspot.com",
    messagingSenderId: "1026074205887",
    appId: "1:1026074205887:web:16df26d9fda3e17560700a",
    measurementId: "G-1LESWNVV56"
  }

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
