// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { Promotion } from '../_models/promotion.model';
// Models
import { QueryParamsModel } from '../../_base/crud';

export enum PromotionActionTypes {
    PromotionPageRequested = '[Promotion List Page] Promotion Page Requested',
    PromotionPageLoaded = '[Promotion API] Promotion Page Loaded',
    PromotionPageCancelled = '[Promotion API] Promotion Page Cancelled',
    PromotionPageToggleLoading = '[Promotion] Promotion Page Toggle Loading',
    PromotionActionToggleLoading = '[Promotion] Promotion Action Toggle Loading'
}

export class PromotionPageRequested implements Action {
    readonly type = PromotionActionTypes.PromotionPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class PromotionPageLoaded implements Action {
    readonly type = PromotionActionTypes.PromotionPageLoaded;
    constructor(public payload: { promotions: Promotion[], totalCount: number, page: QueryParamsModel  }) { }
}


export class PromotionPageCancelled implements Action {
    readonly type = PromotionActionTypes.PromotionPageCancelled;
}

export class PromotionPageToggleLoading implements Action {
    readonly type = PromotionActionTypes.PromotionPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class PromotionActionToggleLoading implements Action {
    readonly type = PromotionActionTypes.PromotionActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type PromotionActions = PromotionPageLoaded
| PromotionPageCancelled
| PromotionPageToggleLoading
| PromotionPageRequested
| PromotionActionToggleLoading;
