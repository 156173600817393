// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { PendingRidesState } from '../_reducers/pendingRides.reducers';
import { each } from 'lodash-es';
import { RideReport } from '../_models/rideReport.model';

export const selectPendingRidesState = createFeatureSelector<PendingRidesState>('pendingRides');

export const selectPendingRideById = (_id: string) => createSelector(
    selectPendingRidesState,
    pendingRidesState => pendingRidesState.entities[_id]
);

export const selectPendingRidesPageLoading = createSelector(
    selectPendingRidesState,
    pendingRidesState => {
        return pendingRidesState.listLoading;
    }
);

export const selectPendingRidesActionLoading = createSelector(
    selectPendingRidesState,
    pendingRidesState => pendingRidesState.actionsloading
);

export const selectLastCreatedPendingRideId = createSelector(
    selectPendingRidesState,
    pendingRidesState => pendingRidesState.lastCreatedPendingRideId
);

export const selectPendingRidesPageLastQuery = createSelector(
    selectPendingRidesState,
    pendingRidesState => pendingRidesState.lastQuery
);

export const selectPendingRidesInStore = createSelector(
    selectPendingRidesState,
    pendingRidesState => {
        const items: RideReport[] = [];
        each(pendingRidesState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: RideReport[] = httpExtension.sortArray(items, pendingRidesState.lastQuery.sortField, pendingRidesState.lastQuery.sortOrder);
        return new QueryResultsModel(result, pendingRidesState.totalCount, '');

    }
);

export const selectPendingRidesShowInitWaitingMessage = createSelector(
    selectPendingRidesState,
    pendingRidesState => pendingRidesState.showInitWaitingMessage
);

export const selectHasPendingRidesInStore = createSelector(
    selectPendingRidesState,
    queryResult => {
        if (!queryResult.totalCount) {
            return false;
        }

        return true;
    }
);

