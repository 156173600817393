import { City } from './city.model';

export class Airport {
  _id: string;
  isDelete: boolean;
  airportName: string;
  city: City;
  lat: number;
  long: number;
  locationBoundary: {
    coordinates: any[],
    type: string
  }
  airportCode: string;
  createdById: string;
  readOnly: boolean;
  __v: 0

  clear(): void {
    this._id = undefined;
    this.isDelete = false;
    this.airportName = '';
    this.city = new City();
    this.city.clear();
    this.lat = 13.1990053;
    this.long = 77.7041131;
    this.locationBoundary = {
      coordinates: [],
      type: 'Polygon'
    }
    this.airportCode = '';
    this.createdById = '';
    this.readOnly = false;
    this.__v = 0
  }
}
