import { User } from '../../auth';

export class KycLog {
  _id: string;
  driverId: string;
  documentId: string;
  who: User;
  typeOfEvent: string; // 'created', 'updated', 'deleted', 'ticketCreated', 'ticketClosed'
  whoIs: string; // 'driver', 'admin', 'system'
  what: string;
  at: string;
  meta: any;
  oldMeta: any;
  isExpanded: boolean;

  clear(): void {
    this._id = undefined;
    this.driverId = undefined;
    this.documentId = undefined;
    this.who = new User();
    this.who.clear();
    this.typeOfEvent = 'created';
    this.whoIs = 'admin';
    this.what = '';
    this.at = '';
    this.meta = undefined;
    this.oldMeta = undefined;
    this.isExpanded = false;
  }
}
