// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
// Actions
import { PromotionActions, PromotionActionTypes } from '../_actions/promotion.actions';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { Promotion } from '../_models/promotion.model';
// RxJS
import { Observable, of } from 'rxjs';

// tslint:disable-next-line:no-empty-interface
export interface PromotionState extends EntityState<Promotion> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedPromotionId: string;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<Promotion> = createEntityAdapter({
  selectId: (referral: Promotion) => referral._id,
});

export const initialPromotionState: PromotionState = adapter.getInitialState({
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastQuery:  new QueryParamsModel(),
    lastCreatedPromotionId: undefined,
    showInitWaitingMessage: true
});

export function PromotionReducer(state = initialPromotionState, action: PromotionActions): PromotionState {
    switch  (action.type) {
        case PromotionActionTypes.PromotionPageToggleLoading: return {
            ...state, listLoading: action.payload.isLoading, lastCreatedPromotionId: undefined
        };
        case PromotionActionTypes.PromotionActionToggleLoading: return {
            ...state, actionsloading: action.payload.isLoading
        };
        case PromotionActionTypes.PromotionPageCancelled: return {
            ...state, listLoading: false, lastQuery: new QueryParamsModel()
        };
        case PromotionActionTypes.PromotionPageLoaded: {
            console.log(action.payload , "reducers");
            return adapter.setAll(action.payload.promotions, {
                ...initialPromotionState,
                totalCount: action.payload.totalCount,
                lastQuery: action.payload.page,
                listLoading: false,
                showInitWaitingMessage: false
            });
        }
        default: return state;
    }
}

export const getPromotionState = createFeatureSelector<PromotionState>('promotions');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
