import { Injectable, PipeTransform } from "@angular/core";

import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import { debounceTime, delay, switchMap, tap, mergeMap } from "rxjs/operators";

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { QueryParamsModel } from '../../_base/crud';

const API_URL = environment.apiUrl+'/settings';

@Injectable({
  providedIn: "root",
})

export class AttributesService {

  constructor(private http: HttpClient) {}

  // Get all attributes
  getAllAttributes(): Observable<any>{
    return this.http.get(API_URL +'/general');
  }

  // Get single attribute
  getAttribute(type: string): Observable<any>{
    return this.http.get(API_URL +'/general?generalType='+type);
  }

  // Add new attribute
  addAttribute(attribute: any): Observable<any>{
    console.log(attribute);
    return this.http.post(API_URL+'/general', attribute);
  }

  // Update attribute
  updateAttribute(attribute: any): Observable<any>{
    console.log(attribute);
    return this.http.put(API_URL+'/general', attribute);
  }

  // Delete attribute
  deleteAttribute(attribute: any): Observable<any>{
    console.log(attribute);
    return this.http.delete(API_URL+'/general?_id=' + attribute._id+'&isDelete='+attribute.isDelete);
  }

}
