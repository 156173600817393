// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { PromotionSettlement } from '../_models/promotionSettlement.model';
// Models
import { QueryParamsModel } from '../../_base/crud';

export enum PromotionSettlementActionTypes {
    PromotionSettlementPageRequested = '[PromotionSettlement List Page] PromotionSettlement Page Requested',
    PromotionSettlementPageLoaded = '[PromotionSettlement API] PromotionSettlement Page Loaded',
    PromotionSettlementPageCancelled = '[PromotionSettlement API] PromotionSettlement Page Cancelled',
    PromotionSettlementPageToggleLoading = '[PromotionSettlement] PromotionSettlement Page Toggle Loading',
    PromotionSettlementActionToggleLoading = '[PromotionSettlement] PromotionSettlement Action Toggle Loading'
}

export class PromotionSettlementPageRequested implements Action {
  readonly type = PromotionSettlementActionTypes.PromotionSettlementPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class PromotionSettlementPageLoaded implements Action {
    readonly type = PromotionSettlementActionTypes.PromotionSettlementPageLoaded;
    constructor(public payload: { promotionSettlements: PromotionSettlement[], totalCount: number, page: QueryParamsModel  }) { }
}


export class PromotionSettlementPageCancelled implements Action {
    readonly type = PromotionSettlementActionTypes.PromotionSettlementPageCancelled;
}

export class PromotionSettlementPageToggleLoading implements Action {
    readonly type = PromotionSettlementActionTypes.PromotionSettlementPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class PromotionSettlementActionToggleLoading implements Action {
    readonly type = PromotionSettlementActionTypes.PromotionSettlementActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type PromotionSettlementActions = PromotionSettlementPageLoaded
| PromotionSettlementPageCancelled
| PromotionSettlementPageToggleLoading
| PromotionSettlementPageRequested
| PromotionSettlementActionToggleLoading;
