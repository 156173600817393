import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-loading',
  templateUrl: './card-loading.component.html',
})

export class CardLoadingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
