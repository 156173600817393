import { Driver } from '../../driver/_models/driver.model';
import { RideReport, TripFareDetails } from '../../ride-report';
import { Settlement } from '../_models/settlement.model';


export class TripFare {
  _id: string;
  from: Driver;
  ride: RideReport;
  settlement: Settlement[];
  tripFare: TripFareDetails;
  __v: number;

  clear(): void {
    this._id = undefined;
    this.from = new Driver();
    this.from.clear();
    this.ride = new RideReport();
    this.ride.clear();
    this.settlement = [];
    this.tripFare = new TripFareDetails();
    this.tripFare.clear();
    this.__v = 0;
  }
}


